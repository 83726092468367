import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineNumber, AiOutlineSearch } from 'react-icons/ai';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import StyledInput from '../../../../custom-ui/styledInput';

const FlexRow = styled.div`
  flex: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 50px
`;

const LeftCol = styled.div`
  flex: 8;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SButton = styled(Button)`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TripSearchInput = props => {
  const { userData } = props;
  const [tripNumber, setTripNumber] = useState('');
  const { t } = useTranslation();
  const { dataStores: { createTripStore } } = useStores();

  const handleSearch = async () => {
    await createTripStore.fillFromTrip(tripNumber.trim());
    setTripNumber('');
  };

  if (!userData || !userData.canSearchPortalTripById) {
    return null;
  }

  return (
      <FlexRow>
        <LeftCol xs={10}>
          <StyledInput
            label={t(langKeys.createTrip.tripNumber)}
            value={tripNumber}
            onChange={v => setTripNumber(v)}
            isClearable={true}
            prependIcon={<AiOutlineNumber />}
            resetValue={''}
          />
        </LeftCol>
        <RightCol>
          <SButton
            variant={'outline-dark'}
            disabled={!tripNumber}
            onClick={handleSearch}
          >
            <AiOutlineSearch />
          </SButton>
        </RightCol>
      </FlexRow>
  );
};

TripSearchInput.propTypes = {
  userData: PropTypes.shape({
    canSearchPortalTripById: PropTypes.bool,
  }).isRequired,
};

export default TripSearchInput;
