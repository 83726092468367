import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { useStores } from '../../stores/helpers/use-stores';

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  top: 0;
  left: 0;
`;

const FullContentSpinner = () => {
  const {
    uiStore: {
      globalView
    }
  } = useStores();

  const { isGlobalLoading } = globalView;

  if (!isGlobalLoading) return null;

  return (
    <Wrapper>
      <Spinner animation={'border'} variant={'primary'} />
    </Wrapper>
  );
};

FullContentSpinner.propTypes = {
  loading: PropTypes.bool,
}

export default observer(FullContentSpinner);
