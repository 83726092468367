import { callAPI } from './apiService';

export const getRestaurantMenu = async (token) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/dishes`,
  });
};

export const findRestaurantDishes = async (token, query) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/dishes/search?query=${query}`,
  });
};

export const updateDishStock = async (token, dishId) => {
  return callAPI({
    method: 'PATCH',
    authentication: token,
    endpoint: `api/corporate/food/dishes/${dishId}`,
  });
};

export const updateDishData = async (token, dishData) => {
  return callAPI({
    method: 'PUT',
    authentication: token,
    body: dishData,
    endpoint: `api/corporate/food/dishes/${dishData._id}`,
  });
};
