import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import commonStyle from '../../../../custom-ui/commonStyle';
import ToolTip from '../../../../custom-ui/toolTip';
import * as S from './styles';

const OrderInfoRow = props => {
  const { icon, value, tooltip } = props;
  return (
    <S.OrderDataRow>
      <ToolTip text={tooltip} placement={'top'}>
        <S.OrderDataIconContainer>
          {cloneElement(icon, { fill: commonStyle.colors.greyMedium })}
        </S.OrderDataIconContainer>
      </ToolTip>
      <S.OrderDataValueContainer>
        <p>{value}</p>
      </S.OrderDataValueContainer>
    </S.OrderDataRow>
  );
};

OrderInfoRow.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.string,
  tooltip: PropTypes.string,
};

export default OrderInfoRow;
