import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from 'react-bootstrap';
import styled from 'styled-components';
import commonStyle from './commonStyle';

const AreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0 16px;
`;

const Label = styled(FormLabel)`
  color: ${commonStyle.colors.greyMedium};
  font-weight: 600;
`;

const Input = styled.textarea`
  width: 100%;
  border: 1px solid ${commonStyle.colors.greyMedium2};
  border-radius: 5px;
  padding: 8px 16px;
`;

const StyledTextArea = props => {
  const { numberOfLines, label, value } = props;
  return (
    <AreaContainer>
      <Label>{label}</Label>
      <Input
        rows={numberOfLines}
        value={value}
        onChange={e => props.onChange(e.target.value)}
      />
    </AreaContainer>
  );
};

StyledTextArea.propTypes = {
  numberOfLines: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default StyledTextArea;
