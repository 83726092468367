import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import commonStyle from './commonStyle';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    height: 40,
    margin: '0 0 16px',
    border: `1px solid ${state.isFocused ? commonStyle.colors.brandPrimary : commonStyle.colors.greyMedium2}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
};

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.selected ? commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.8) : commonStyle.colors.white};
  color: ${props => props.selected ? commonStyle.colors.white : commonStyle.colors.brandPrimary};

  &:hover {
    background-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.6)};
    color: ${commonStyle.colors.white};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;

const LabelContainer = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const IconizedOption = props => {
  const { data, innerProps, isDisabled, isSelected } = props;
  if (isDisabled) return null;
  return <OptionRow selected={isSelected} {...innerProps}>
    <IconContainer>{data.icon}</IconContainer>
    <LabelContainer>{data.label}</LabelContainer>
  </OptionRow>
};

const CustomizedControl = props => {
  const values = props.getValue();
  return <components.Control
    {...props}
  >
    {!!values[0]?.icon && <IconContainer style={{ color: commonStyle.colors.brandPrimary }}>{values[0].icon}</IconContainer>}
    {props.children}
  </components.Control>;
}

const StyledDropdownInput = props => {
  const { options, value, label, additionalProps } = props;
  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <Select
        components={{
          Option: IconizedOption,
          Control: CustomizedControl
        }}
        onChange={props.onSelect}
        options={options}
        styles={selectStyles}
        value={value}
        placeholder={label}
        {...additionalProps}
      />
    </FormGroup>
  );
};

StyledDropdownInput.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  label: PropTypes.string,
  additionalProps: PropTypes.object,
};

StyledDropdownInput.defaultProps = {
  additionalProps: {},
};

export default StyledDropdownInput;
