import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const AlertPanel = props => {
  const { isSuccess, message } = props;

  if (!message) return null;

  return (
    <Alert variant={isSuccess ? 'success' : 'danger'} style={{ width: '100%' }}>
      {message}
    </Alert>
  );
};

AlertPanel.propTypes = {
  isSuccess: PropTypes.bool,
  message: PropTypes.string,
};

export default AlertPanel;
