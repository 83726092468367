const tripStatuses = {
  request: 'request',
  booking: 'booking',
  booked: 'booked',
  arriving: 'arriving',
  arrived: 'arrived',
  enRoute: 'enRoute',
  payment: 'payment',
  feedback: 'feedback',
  endTrip: 'endTrip',
  cancelled: 'cancelled',
  deleted: 'deleted',
  missed: 'missed',
  noNearByDriver: 'noNearByDriver',
  bookingLimitReached: 'bookingLimitReached',
  missedPreBooking: 'missedPreBooking'
};

export const bookingStatuses = [
  tripStatuses.booking,
  tripStatuses.booked
];

export const onGoingTripStatuses = [
  tripStatuses.arriving,
  tripStatuses.arrived,
  tripStatuses.enRoute
];

export default tripStatuses;
