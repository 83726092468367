import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebouncedEffect } from '../../../../hooks/useDebouncedEffect';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import commonStyle from '../../../custom-ui/commonStyle';
import CreateTripForm from './form';
import MapData from './map';

const CreateTripMainContainer = styled(Row)`
  margin: 0;
  padding: 8px;
`;

const FormContainer = styled(Col).attrs(() => ({
  sm: 12,
  lg: 4
}))`
  background-color: ${commonStyle.colors.white};
  min-height: 100%;
  min-width: 300px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 4px 4px 4px ${commonStyle.colors.greyMedium2};
  @media (max-width: 990px) {
    border-radius: 8px 8px 0 0;
    margin-bottom: 0;
  }
`;

const MapContainer = styled(Col).attrs(() => ({
  sm: 12,
  lg: 8
}))`
  min-height: 100%;
  border-radius: 0 8px 8px 0;
  padding: 0;
  box-shadow: 4px 4px 4px ${commonStyle.colors.greyMedium2};
  overflow: hidden;
  @media (max-width: 990px) {
    min-height: 50vh;
    max-height: 50vh;
    margin-top: 0;
    border-radius: 0 0 8px 8px;
  }
`;

const CreateTrip = props => {
  const { t } = useTranslation();
  useTitle(t(langKeys.navLinks.createTrip));
  const { dataStores: { createTripStore }, uiStore: { globalView } } = useStores();
  const [mapContainerDimensions, setMapContainerDimensions] = useState({ height: 0, width: 0 });
  const mapContainerRef = useRef(null);

  useDebouncedEffect(() => {
    if (mapContainerRef.current) {
      setMapContainerDimensions({
        width: mapContainerRef.current.offsetWidth,
        height: mapContainerRef.current.offsetHeight
      });
    }
  }, 400, [globalView.windowDimensions, globalView.sidebarWidth]);

  useEffect(() => {
    createTripStore.getCities();
    return () => {
      globalView.setLoaded();
    };
  }, [createTripStore, globalView]);
  return (
    <CreateTripMainContainer>
      <FormContainer>
        <CreateTripForm />
      </FormContainer>
      <MapContainer ref={mapContainerRef}>
        <MapData containerDimensions={mapContainerDimensions} />
      </MapContainer>
    </CreateTripMainContainer>
  );
};

export default observer(CreateTrip);
