import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    margin: '0 0 16px',
    border: `1px solid ${state.isFocused ? commonStyle.colors.brandPrimary : commonStyle.colors.greyMedium2}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px'
  })
};

const RestaurantTagsInput = props => {
  const { t } = useTranslation();
  const { dataStores: { accountSettings } } = useStores();
  const { availableRestaurantTags } = accountSettings;
  const { restaurant } = props;

  useEffect(() => {
    accountSettings.getAvailableRestaurantTags();
    // eslint-disable-next-line
  }, []);

  return (
    <FormGroup>
      <FormLabel>{t(langKeys.settings.tags)}</FormLabel>
      <CreatableSelect
        onChange={props.onSelect}
        options={availableRestaurantTags.map(t => ({ label: t, value: t }))}
        value={restaurant?.restaurantData?.tags.map(t => ({ label: t, value: t })) || []}
        placeholder={t(langKeys.settings.tags)}
        styles={selectStyles}
        isClearable
        isMulti
      />
    </FormGroup>
  );
};

RestaurantTagsInput.propTypes = {
  restaurant: PropTypes.object,
  onSelect: PropTypes.func,
};

export default observer(RestaurantTagsInput);
