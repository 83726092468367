import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { COMPLETED } from '../../../../../constants/delivery-order-statuses';
import {
  formatAddressString,
  formatPrice,
  getDriverAndTripDataFromOrder,
} from '../../../../../helpers/utils';
import EuroIcon from '../../../../../assets/icons/euro-icon.svg';
import DestinationIcon from '../../../../../assets/icons/dest-icon.svg';
import ClientIcon from '../../../../../assets/icons/profile.svg';
import PhoneIcon from '../../../../../assets/icons/phone-icon.svg';
import CarIcon from '../../../../../assets/icons/car-thin.svg';
import DriverIcon from '../../../../../assets/icons/driverIcon.svg';
import LocationIcon from '../../../../../assets/icons/location.svg';
import CommentIcon from '../../../../../assets/icons/comment-icon.svg';
import langKeys from '../../../../../i18n/lang/keys';
import commonStyle from '../../../../custom-ui/commonStyle';
import ToolTip from '../../../../custom-ui/toolTip';
import { DeliveryDate, DeliveryType, OrderDataIconContainer, OrderDataValueContainer } from '../content/styles';
import MapSection from './mapSection';
import OrderItem from './orderItem';
import * as S from './styles';

const OrderDetailsDrawerBody = props => {
  const { orderDetails: order, showMenuNumber } = props;
  const { t } = useTranslation();
  const tripData = useMemo(() => {
    return getDriverAndTripDataFromOrder(order);
  }, [order]);

  return (
    <S.BodyContainer>
      <S.BodyGeneralRow>
        <DeliveryType style={{ margin: 0 }} isDelivery={!order.isSelfPickup}>
          {t(langKeys.foodOrders[order.isSelfPickup ? 'pickup' : 'delivery'])}
        </DeliveryType>
        <DeliveryDate>
          <strong>{moment(order.deliveryDate).locale('de').format('DD MMM [um] HH:mm')}</strong>
        </DeliveryDate>
        <S.PriceBlock>
          <EuroIcon fill={commonStyle.colors.brandPrimary} style={{ marginRight: 6 }}/>
          {`${formatPrice(order.priceData?.orderItemsFare, { addEuroSign: false })} (${t(langKeys.paymentMethod[order?.paymentMethod])})`}
        </S.PriceBlock>
      </S.BodyGeneralRow>
      {!order.isSelfPickup &&
      <S.AddressRow
        href={`https://www.google.com/maps/search/?api=1&query=${order.deliveryPlace.location.latitude},${order.deliveryPlace.location.longitude}`}
        target={'_blank'}
      >
        <ToolTip text={t(langKeys.foodOrders.deliveryAddress)} placement={'bottom'}>
          <DestinationIcon style={{ marginRight: 12 }}/>
        </ToolTip>
        {formatAddressString(order.deliveryPlace.address)}
      </S.AddressRow>
      }
      {order.orderStatus !== COMPLETED && <S.OrderClientRow>
        <S.ClientInfoBox>
          <S.ClientTopRow>
            {t(langKeys.foodOrders.client)}
          </S.ClientTopRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <ClientIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{order.isSelfPickup ? order.clientOrderName : order.clientOrderLName}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <PhoneIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{order.clientPhoneNo}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <CommentIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{order.orderComment || '------'}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
        </S.ClientInfoBox>
        {tripData?.showTrip && <S.ClientInfoBox>
          <S.ClientTopRow>
            {t(langKeys.foodOrders.driver)}
            <ToolTip text={t(langKeys.foodOrders.showOnMap)} placement={'bottom'}>
              <S.DriverLocBtn
                href={`https://www.google.com/maps/search/?api=1&query=${tripData.location.join(',')}`}
                target={'_blank'}
              >
                <LocationIcon />
              </S.DriverLocBtn>
            </ToolTip>
          </S.ClientTopRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <DriverIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{tripData.driverName}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <PhoneIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{tripData.driverPhoneNo}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
          <S.ClientRow>
            <OrderDataIconContainer>
              <CarIcon fill={commonStyle.colors.greyMedium}/>
            </OrderDataIconContainer>
            <OrderDataValueContainer>
              <p>{tripData.car}</p>
            </OrderDataValueContainer>
          </S.ClientRow>
        </S.ClientInfoBox>}
      </S.OrderClientRow>}
      <MapSection mapData={order.mapData} order={order} />
      <S.DishesBlock>
        <S.DishesTitle>{t(langKeys.foodOrders.dishes)}:</S.DishesTitle>
        <S.DishesBody>
          {order.orderItems.map((item, index) => <OrderItem key={index} item={item} showMenuNumber={showMenuNumber}/>)}
        </S.DishesBody>
      </S.DishesBlock>
      {order.orderStatus === COMPLETED && order.foodRatingByClient > 0 && <S.DishesBlock>
        <S.DishesTitle>{t(langKeys.foodOrders.orderRating)}:</S.DishesTitle>
        <S.StarBlock>
          {[...new Array(5)].map((v, i) => <S.StarItem key={`star-${i}`}>
            {i < order.foodRatingByClient ? <AiFillStar/> : <AiOutlineStar/>}
          </S.StarItem>)}
        </S.StarBlock>
        {!!(order.clientFeedbackComment?.trim()) && <S.CommentBlock>{order.clientFeedbackComment}</S.CommentBlock>}
      </S.DishesBlock>}
    </S.BodyContainer>
  );
};

OrderDetailsDrawerBody.propTypes = {
  orderDetails: PropTypes.object,
  showMenuNumber: PropTypes.bool,
};

export default OrderDetailsDrawerBody;

