import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStores } from '../../../../../stores/helpers/use-stores';
import RestaurantCategory from './restaurantCategoryBlock';

const RestaurantMenu = props => {
  const { dataStores: { foodMenuStore } } = useStores();
  const { restaurantMenu } = foodMenuStore;

  useEffect(() => {
    foodMenuStore.getRestaurantMenu();
    // eslint-disable-next-line
  }, []);

  const handleStockChange = (dishId) => {
    foodMenuStore.updateDishStock(dishId);
  }

  return (
    <>
      {restaurantMenu.map((cat, idx) =>
        <RestaurantCategory
          key={`${idx}-${cat.catId}`}
          data={cat}
          onStockChange={handleStockChange}
          onEdit={props.onEdit}
        />)}
    </>
  );
};

RestaurantCategory.propTypes = {
  onEdit: PropTypes.func,
}

export default observer(RestaurantMenu);
