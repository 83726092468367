import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import Select from 'react-select';
import FoodOrderTabs from '../../../../../constants/foodOrderTabs';
import langKeys from '../../../../../i18n/lang/keys';
import SortIcon from '../../../../../assets/icons/sortIcon.svg';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import * as S from './styles';

const selectStyles = (bgColor = null) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: bgColor || commonStyle.colors.white,
    width: 140,
    margin: '0 16px',
    border: 'none',
    boxShadow: `1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    fill: commonStyle.colors.brandPrimary
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 30,
    padding: 0,
    textAlign: 'center',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '400 !important'
  })
});

const SortControl = props => {
  const { t } = useTranslation();
  const { justify, selectorBg } = props;
  const { dataStores: { foodOrdersStore } } = useStores();
  const { currentTab, displayMeta } = foodOrdersStore;

  const sortByOptions = useMemo(() => [
    { label: t(langKeys.foodOrders.deliveryDate), value: 'deliveryDate' },
    { label: t(langKeys.foodOrders.orderDate), value: 'orderDate' },
    { label: t(langKeys.foodOrders.price), value: 'orderPrice' },
    { label: t(langKeys.foodOrders.orderStatus), value: 'orderStatus' },
    { label: t(langKeys.foodOrders.rating), value: 'foodRatingByClient' },
  ], [t]);

  const showExportModal = () => {
    foodOrdersStore.showFoodModal({
      title: t(langKeys.foodOrders.exportOrders),
      type: 'export',
      onConfirm: (rangeData) => {
        foodOrdersStore.hideFoodModal();
        foodOrdersStore.exportCompletedOrders(rangeData);
      }
    });
  };

  const showDatePickModal = () => {
    foodOrdersStore.showFoodModal({
      title: t(langKeys.foodOrders.pickRange),
      type: 'rangeSearch',
      onConfirm: (dateRange) => {
        foodOrdersStore.hideFoodModal();
        foodOrdersStore.updateDisplayMeta({ dateRange });
      }
    });
  };

  return (
    <S.SortControlsContainer justify={justify}>
      <S.SortAscBtn
        onClick={() => foodOrdersStore.updateDisplayMeta({ isDescending: !displayMeta.isDescending })}
      >
        <SortIcon fill={commonStyle.colors.brandPrimary} className={displayMeta.isDescending ? 'not-rotated' : 'rotated'}/>
      </S.SortAscBtn>
      <Select
        onChange={(v) => foodOrdersStore.updateDisplayMeta({ sortBy: v.value })}
        options={sortByOptions}
        styles={selectStyles(selectorBg)}
        value={sortByOptions.find(v => v.value === displayMeta.sortBy)}
        />
      {currentTab === FoodOrderTabs.done && !foodOrdersStore.displayMeta.dateRange && <S.ExportButton
        onClick={showDatePickModal}
      >
        {t(langKeys.foodOrders.pickRange)}...
      </S.ExportButton>}
      {currentTab === FoodOrderTabs.done && !!foodOrdersStore.displayMeta.dateRange && <ButtonGroup>
        <S.ExportButton
          onClick={showDatePickModal}
        >
          {`${moment(foodOrdersStore.displayMeta.dateRange.startRange).format('DD.MM')} - ${moment(foodOrdersStore.displayMeta.dateRange.endRange).format('DD.MM')}`}
        </S.ExportButton>
        <S.CloseButton
          onClick={() => foodOrdersStore.updateDisplayMeta({ dateRange: null })}
        >
          <AiOutlineClose />
        </S.CloseButton>
      </ButtonGroup>}
      {/* remove false to show ExportButton (hidden for now) */}
      {currentTab === FoodOrderTabs.done && false && <S.ExportButton
        onClick={showExportModal}
      >
        Export
      </S.ExportButton>}
    </S.SortControlsContainer>
  );
};

SortControl.propTypes = {
  justify: PropTypes.string,
  selectorBg: PropTypes.string,
}

export default observer(SortControl);
