import { action, computed, makeAutoObservable, observable, runInAction, toJS } from 'mobx';
import { clearPersist, isSynchronized, startPersist, StorageAdapter } from 'mobx-persist-store';
import carCategoryConsts from '../../../constants/carCategories';
import { isStringEqual } from '../../../helpers/utils';
import notify, { NotificationTypes } from '../../../services/notifier';
import { readStore, writeStore } from '../auth';
import * as CreateTripService from '../../../services/createTripService';

export const createTripPersistOptions = {
  name: 'BookTrip',
  properties: [
    'cities',
    'carCategories',
    'nearByDrivers',
    'selectedCarCategory',
    'isPreBookingsEnabled',
    'isPB',
    'fareData',
    'isPriceFixed',
    'preBookedTime',
    'selectedCity',
    'pickUpAddress',
    'destAddress',
    'srcLoc',
    'destLoc',
    'waypoints',
    'riderFirstName',
    'riderLastName',
    'taxiType',
    'tripComment',
    'distance',
    'travelTime',
    'phoneNo',
    'withInvoice',
    'officeCarrier',
    'fareDetails',
    'polyline',
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 0,
  }
};

export default class TripCreate {
  @observable isTripRequest = false;
  @observable requestedTrip = null;
  @observable requestCounter = null;

  @observable isError = false;
  @observable fetchMessage = null;

  @observable cities = [];
  @observable carCategories = [];
  @observable nearByDrivers = [];
  @observable selectedCarCategory = null;
  @observable isPreBookingsEnabled = true;
  @observable isPB = false;
  @observable fareData = null;

  @observable isPriceFixed = false;
  @observable preBookedTime = null;
  @observable selectedCity = null;
  @observable pickUpAddress = '';
  @observable destAddress = '';
  @observable srcLoc = null;
  @observable destLoc = null;
  @observable waypoints = [];
  @observable riderFirstName = '';
  @observable riderLastName = '';
  @observable taxiType = '';
  @observable tripComment = '';
  @observable distance = null;
  @observable travelTime = null;
  @observable phoneNo = '';
  @observable withInvoice = false;
  @observable officeCarrier = null;
  @observable fareDetails = null;
  @observable polyline = null;

  timeout = null;
  requestUpdateInterval = null;
  requestUpdateClearTimeout = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.startPersist();
  }

  @action updateFareObject = (data) => {
    this.fareData = data;
  }

  @action updateAdditionalFareFields = data => {
    if (!data) {
      this.distance = null;
      this.polyline = null;
      this.travelTime = null;
    } else {
      this.distance = {
        value: data.distance * 1000,
        text: `${data.distance.toFixed(1)} km`,
        sections: data.sections
      };
      this.travelTime = data.duration;
      this.polyline = data.polyline;
    }
  }

  @action setSelectedCity = (cityId) => {
    this.officeCarrier = cityId;
    if (this.cities.length) {
      this.selectedCity = this.cities.find(c => isStringEqual(c._id)(cityId));
    }
  }

  @action setSelectedCarCategory = (catId) => {
    if (this.carCategories.length) {
      this.selectedCarCategory = this.carCategories.find(cat => isStringEqual(cat._id)(catId));
      this.taxiType = this.selectedCarCategory?.name;
    }
  }

  @action toggleIsBP = (value) => {
    this.isPB = value;
  }

  @action setPBTime = (date) => {
    this.preBookedTime = date;
  }

  @action setPickup = (address, loc) => {
    this.pickUpAddress = address;
    this.srcLoc = loc;
  }

  @action setDestination = (address, loc) => {
    this.destAddress = address;
    this.destLoc = loc;
  }

  @action setFName = name => {
    this.riderFirstName = name;
  }

  @action setLName = name => {
    this.riderLastName = name;
  }

  @action setTripComment = comment => {
    this.tripComment = comment;
  }

  @action setPhoneNo = phoneNo => {
    this.phoneNo = phoneNo;
  }

  @action updateWaypoints = (wps) => {
    this.waypoints = wps;
  }

  @action toggleWithInvoice = value => {
    if (typeof value !== 'boolean') {
      this.withInvoice = !this.withInvoice;
    } else {
      this.withInvoice = value;
    }
  }

  @action setDistance = (dist) => {
    this.distance = dist;
  }

  @action setPreBookingTime = (date) => {
    this.preBookedTime = date;
  }

  @action setFareDetails = (data) => {
    this.fareDetails = { ...data };
  }

  @action setPolyline = (polyline) => {
    this.polyline = polyline;
  }

  @action toggleFixedPrice = (value) => {
    if (typeof value !== 'boolean') {
      this.isPriceFixed = !this.isPriceFixed;
    } else {
      this.isPriceFixed = value;
    }
  }

  @action loading = () => {
    this.rootStore.uiStore.globalView.setLoading();
    this.isError = false;
    this.fetchMessage = null;
  }

  @action loaded = (isError = false, message = null) => {
    this.rootStore.uiStore.globalView.setLoaded();
    this.isError = isError;
    this.fetchMessage = message;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (!!message) {
      this.timeout = setTimeout(() => {
        this.loaded();
      }, 15 * 1000);
    }
  }

  @action getCities = async () => {
    const resp = await CreateTripService.getCitiesList(this.rootStore.dataStores.authStore.token);
    if (resp.success) {
      runInAction(() => {
        this.cities = resp.data;
      });
    }
  }

  @action getCategories = async () => {
    const resp = await CreateTripService.getCarCategories(this.rootStore.dataStores.authStore.token, this.officeCarrier);
    if (resp.success) {
      let categories = resp.data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
      const cabdoGreen = categories.find(cat => cat.name === carCategoryConsts.CabdoGreen);
      if (cabdoGreen) {
        categories = [cabdoGreen, ...categories.filter(cat => ![carCategoryConsts.CabdoGreen].includes(cat.name))]
      }
      const defaultCat = categories.find(c => c.default);
      runInAction(() => {
        this.carCategories = categories;
        this.selectedCarCategory = defaultCat;
        this.taxiType = defaultCat?.name;
      });
      if (resp.meta) {
        runInAction(() => {
          this.isPreBookingsEnabled = resp.meta.preBookingsEnabled;
        });
      }
    }
  }

  getCategoriesByCityId = async (cityId) => {
    const resp = await CreateTripService.getCarCategories(this.rootStore.dataStores.authStore.token, cityId);
    return resp.success ? resp.data : [];
  }

  @action calculateFare = async () => {
    this.updateFareObject(null);
    this.updateAdditionalFareFields(null);
    if (!this.srcLoc || !this.destLoc || (this.waypoints.length && this.waypoints.some(wp => !wp.location))) {
      return;
    }
    const requestBody = {
      origin: this.srcLoc.join(','),
      destination: this.destLoc.join(','),
      bookingTime: this.preBookedTime,
      waypoints: toJS(this.waypoints),
      cityId: this.officeCarrier
    };
    const resp = await CreateTripService.estimateFare(this.rootStore.dataStores.authStore.token, requestBody);
    if (resp.success && resp.data) {
      this.updateFareObject({ ...resp.data, meta: resp.meta });
      this.updateAdditionalFareFields(resp.data);
    } else {
      notify(resp.message, NotificationTypes.error);
    }
  }

  @action fillFromTrip = async (tripNumber) => {
    this.loading();
    const resp = await CreateTripService.getTripFromNumber(this.rootStore.dataStores.authStore.token, tripNumber);
    if (resp.success) {
      const prevTrip = resp.data;
      this.setPickup(prevTrip.pickUpAddress, prevTrip.srcLoc);
      this.setDestination(prevTrip.destAddress, prevTrip.destLoc);
      this.updateWaypoints(prevTrip.waypoints);
      this.setDistance(prevTrip.distance);
      this.toggleFixedPrice(prevTrip.isPriceFixed);
      this.setFareDetails(prevTrip.fareDetails);
      this.setLName(prevTrip.rider?.lname ?? '');
      this.setFName(prevTrip.rider?.fname ?? '');
      this.setTripComment(prevTrip.tripComment);
      this.setPhoneNo(prevTrip.rider?.phoneNo ?? '');
      this.toggleWithInvoice(prevTrip.withInvoice);
      this.setPreBookingTime(null);
      this.setPolyline(prevTrip.polyline);
    } else {
      this.loaded(true, resp.message);
    }
  }

  @action resetTripData = () => {
    const { userData } = this.rootStore.dataStores.authStore;
    this.isPriceFixed = false;
    this.preBookedTime = null;
    this.pickUpAddress = userData.notPresetPickupAddress ? '' : userData.address;
    this.srcLoc = userData.notPresetPickupAddress ? null : userData.gpsLoc;
    this.destAddress = '';
    this.destLoc = null;
    this.waypoints = [];
    this.riderFirstName = userData.fname;
    this.riderLastName = userData.lname;
    this.tripComment = '';
    this.distance = null;
    this.phoneNo = userData.phoneNo;
    this.withInvoice = userData.invoiceByDefault;
    this.fareDetails = null;
    this.polyline = null;
    this.fareData = null;
    this.isPB = false;
    this.setSelectedCity(userData.defaultCity);
    this.requestCounter = 0;
    this.requestedTrip = null;
  };

  @action getNearByDrivers = async () => {
    if (!this.officeCarrier || !this.srcLoc) {
      runInAction(() => {
        this.nearByDrivers = [];
      });
      return;
    }
    const resp = await CreateTripService.getNearByDrivers(this.rootStore.dataStores.authStore.token, this.officeCarrier, this.srcLoc);
    if (resp.success) {
      runInAction(() => {
        this.nearByDrivers = resp.data;
      });
    }
  }

  @action resetRequestedTrip = () => {
    this.requestedTrip = null;
  }

  @action requestTrip = async (fixedFare) => {
    runInAction(() => {
      this.isTripRequest = true;
      this.requestedTrip = null;
    });
    let tripAmt = null;
    if (!!this.destLoc) {
      tripAmt = fixedFare ? fixedFare : this.fareDetails.totalFare;
    }

    const trip = {
      tripAmt,
      isPriceFixed: this.isPriceFixed,
      preBookedTime: this.preBookedTime,
      pickUpAddress: this.pickUpAddress,
      destAddress: this.destAddress,
      srcLoc: this.srcLoc,
      destLoc: this.destLoc,
      waypoints: this.waypoints,
      riderFirstName: this.riderFirstName,
      riderLastName: this.riderLastName,
      taxiType: this.taxiType,
      tripComment: this.tripComment,
      distance: this.distance,
      travelTime: this.travelTime,
      phoneNo: this.phoneNo,
      withInvoice: this.withInvoice,
      officeCarrier: this.officeCarrier,
      fareDetails: this.fareDetails,
      polyline: this.polyline,
      rideNow: !this.isPB
    };

    const resp = await CreateTripService.sendTripRequest(this.rootStore.dataStores.authStore.token, trip, toJS(this.selectedCarCategory));

    if (resp.success) {
      runInAction(() => {
        this.isTripRequest = false;
        this.requestedTrip = resp.data;
      });
      this.initiateRequestInterval();
    } else {
      this.loaded(true, resp.message);
    }
  }

  @action cancelLastTrip = async () => {
    if (!this.requestedTrip) return;
    const resp = await CreateTripService.cancelTripByPortal(this.rootStore.dataStores.authStore.token, this.requestedTrip.tripId);
    if (resp.success) {
      notify('Letzte Reiserücktrittsaktion erfolgreich!', NotificationTypes.success);
      runInAction(() => {
        this.requestCounter = 0;
        this.requestedTrip = null;
      });
    } else {
      notify(`Fehler beim Abbrechen der letzten Reise: ${resp.error}`, NotificationTypes.error);
    }
  }

  @action cancelLastTripById = async (tripId) => {
    const resp = await CreateTripService.cancelTripByPortal(this.rootStore.dataStores.authStore.token, tripId);
    if (resp.success) {
      notify('Letzte Reiserücktrittsaktion erfolgreich!', NotificationTypes.success);
    } else {
      notify(`Fehler beim Abbrechen der letzten Reise: ${resp.error}`, NotificationTypes.error);
    }
  }

  @action getRequestedTripUpdates = async () => {
    if (!this.requestedTrip) return;
    const trip = await CreateTripService.getRequestUpdates(this.rootStore.dataStores.authStore.token, this.requestedTrip.tripId);
    runInAction(() => {
      this.requestedTrip = { ...toJS(this.requestedTrip), ...trip };
    });
  }

  @action initiateRequestInterval = () => {
    this.requestCounter = 0;
    this.requestUpdateInterval = setInterval(() => {
      this.getRequestedTripUpdates();
      runInAction(() => {
        this.requestCounter += 3500;
      });
    }, 3500);
    this.requestUpdateClearTimeout = setTimeout(() => {
      runInAction(() => {
        this.requestCounter = 0;
        this.requestedTrip = null;
      });
    }, 36 * 1000);
  }

  @action resetRequestInterval = () => {
    runInAction(() => {
      clearTimeout(this.requestUpdateClearTimeout);
      clearInterval(this.requestUpdateInterval);
    })
  }

  startPersist = async () => {
    startPersist(this)
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
};
