import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyle from '../custom-ui/commonStyle';
import RightDrawer from '../custom-ui/sideDrawer';
import Header from '../elements/header';
import Sidebar from '../elements/sidebar';
import bgImage from '../../assets/images/mainBackgroundImage.png';

const GeneralView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${commonStyle.colors.brandPrimary};
  background-image: url(${bgImage});
  background-size: cover;
  overflow-x: hidden;
`;

const ViewContainer = styled.section`
  background-color: ${commonStyle.colors.background};
  position: fixed;
  top: 70px;
  bottom: 0;
  right: 0;
  height: ${props => props.windowHeight - 70}px;
  z-index: 0;
  left: ${props => props.sbWidth}px;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  transition: all 0.2s ease-in-out;
  overflow: auto;
  //@media (max-width: 1200px) {
  //  left: 50px;
  //}
  @media (max-width: 768px) {
    left: 0;
  }
`;

const Layout = props => {
  const { uiStore: { globalView } } = useStores();
  const { sidebarWidth, windowDimensions } = globalView;
  return (
    <>
      <GeneralView>
        <Header />
        <Sidebar />
        <RightDrawer />
        <ViewContainer sbWidth={sidebarWidth} windowWidth={windowDimensions.width} windowHeight={windowDimensions.height}>
          {props.children}
        </ViewContainer>
      </GeneralView>
    </>
  );
};

export default observer(Layout);
