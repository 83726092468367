import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import StyledModal from '../../../../custom-ui/styledModal';
import { useExportData } from '../../../portal/myTrips/modals/exportModal';

const FoodOrdersModal = () => {
  const { t }= useTranslation();
  const { dataStores: { foodOrdersStore } } = useStores();
  const modalData = foodOrdersStore.modalData;
  const { isVisible, title, type, onConfirm } = modalData;

  const exportData = useExportData(onConfirm, () => foodOrdersStore.hideFoodModal());
  const rangeSearchData = useExportData(onConfirm, () => foodOrdersStore.hideFoodModal(), null, t(langKeys.createTrip.submit));

  const modalContent = useMemo(() => {
    const data = {};
    switch (type) {
      case 'rangeSearch':
        data.title = title;
        data.bodyContent = rangeSearchData.bodyContent;
        data.footerContent = rangeSearchData.footerContent;
        break;
      case 'export':
      default:
        data.title = title;
        data.bodyContent = exportData.bodyContent;
        data.footerContent = exportData.footerContent;
        break;
    }
    return data;
  }, [type, exportData, title, rangeSearchData]);

  return (
    <StyledModal
      isVisible={isVisible}
      onHide={() => foodOrdersStore.hideFoodModal()}
      {...modalContent}
    />
  );
};

FoodOrdersModal.propTypes = {
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default observer(FoodOrdersModal);
