import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CONFIRMED, EN_ROUTE } from '../../../../../constants/delivery-order-statuses';
import langKeys from '../../../../../i18n/lang/keys';
import { SubmitButton } from '../../restaurant-settings/styles';
import * as S from './styles';

const OrderDetailsDrawerFooter = props => {
  const { t } = useTranslation();
  const { orderDetails, hasOwnDelivery } = props;

  const showConfirmPickupButton = useMemo(() => {
    return [CONFIRMED, EN_ROUTE].includes(orderDetails.orderStatus) && (orderDetails.isSelfPickup || hasOwnDelivery);
  }, [orderDetails, hasOwnDelivery]);

  return (
    <S.FooterContainer>
      <S.CloseButton
        onClick={props.onHidePress}
      >
        {t(langKeys.hide)}
      </S.CloseButton>
      {showConfirmPickupButton && <SubmitButton style={{ margin: 0 }} onClick={props.onOrderCompleted}>
        {orderDetails.isSelfPickup ? t(langKeys.foodOrders.confirmPickup) : t(langKeys.foodOrders.confirmDelivery)}
      </SubmitButton>}
    </S.FooterContainer>
  );
};

OrderDetailsDrawerFooter.propTypes = {
  orderDetails: PropTypes.object,
  hasOwnDelivery: PropTypes.bool,
  onHidePress: PropTypes.func,
  onOrderCompleted: PropTypes.func,
};

export default OrderDetailsDrawerFooter;
