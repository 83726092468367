import axios from 'axios';
import apiConfig from './apiConfig';

export const callAPI = async ({ method, authentication, endpoint, body, headers, credentials, responseType }) => {
  try {
    const url = `${apiConfig.serverUrl}/${endpoint}`;
    const requestOptions = {
      url,
      method: method || 'GET',
      headers: headers || {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (!!body) {
      requestOptions.data = JSON.stringify(body);
    }

    if (authentication) {
      requestOptions.headers.Authorization = authentication;
    }
    if (credentials) {
      requestOptions.credentials = credentials
    }
    if (responseType) {
      requestOptions.responseType = responseType;
    }
    const response = await axios(requestOptions);
    return await response.data;
  } catch (e) {
    console.error(`Error in callAPI: ${e.message}, ${e.stack}`);
    return {};
  }
}
