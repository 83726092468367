import { action, makeAutoObservable, observable } from 'mobx';

export default class DrawerStore {
  @observable isOpened = false;
  @observable title = '';
  @observable body = null;
  @observable footer = null;
  @observable closeOnBackdrop = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action showDrawer = ({
    title = '',
    body = null,
    footer = null,
    closeOnBackdrop = true
  }) => {
    this.title = title;
    this.body = body;
    this.footer = footer;
    this.closeOnBackdrop = closeOnBackdrop;
    this.isOpened = true;
  }

  @action updateBodyPart = (partName, value) => {
    this[partName] = value;
  }

  @action closeDrawer = () => {
    this.title = '';
    this.body = null;
    this.footer = null;
    this.closeOnBackdrop = true;
    this.isOpened = false;
  }
}
