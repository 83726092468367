export const dishTypes = {
  simpleDish: 'simpleDish',
  addition: 'addition',
  multiSize: 'multiSize',
  looseSize: 'looseSize',
  constr: 'constructor',
  foodBox: 'foodBox',
  constructorAddition: 'constructorAddition',
};

export const defineDishType = (dish) => {
  if (!dish) return dishTypes.simpleDish;
  if (!!dish.subCategory) return dishTypes.constructorAddition;
  if (dish.isAddition) return dishTypes.addition;
  if (dish.isConstructor) return dishTypes.constr;
  if (dish.isFoodBox) return dishTypes.foodBox;
  if (dish.sizingAvailable && dish.flexibleSizingEnabled) return dishTypes.looseSize;
  if (dish.sizingAvailable && !dish.flexibleSizingEnabled) return dishTypes.multiSize;
  return dishTypes.simpleDish;
};
