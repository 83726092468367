import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DestinationIcon from '../../../assets/icons/destination.svg';
import RideNowIcon from '../../../assets/icons/start-trip.svg';
import ForkIcon from '../../../assets/icons/fork_knife.svg';
import DishesIcon from '../../../assets/icons/menu_icon.svg';
import UserIcon from '../../../assets/icons/profile.svg';
import RestaurantSettingsIcon from '../../../assets/icons/restaurant_settings.svg';
import langKeys from '../../../i18n/lang/keys';
import { useStores } from '../../../stores/helpers/use-stores';
import LinkItem from './linkItem';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 80px;
  overflow: auto;
`;

const LinkList = props => {
  const { isCollapsed, isRestaurant } = props;
  const { uiStore: { globalView } } = useStores();
  const { t } = useTranslation();

  return (
    <ListContainer>
      {isRestaurant && <>
        <LinkItem
          icon={<ForkIcon height={18} width={18}/>}
          linkPath={'/food/orders'}
          linkText={t(langKeys.navLinks.foodOrders)}
          showText={!isCollapsed}
          canBlink={true}
          currentTitle={globalView.pageTitle}
          blinkTitle={'Neue Bestellung!'}
        />
        <LinkItem
          icon={<DishesIcon height={18} width={18}/>}
          linkPath={'/food/menu'}
          linkText={t(langKeys.navLinks.foodMenu)}
          showText={!isCollapsed}
        />
      </>}
      <LinkItem
        icon={<RideNowIcon height={18} width={18}/>}
        linkPath={'/portal/create-trip'}
        linkText={t(langKeys.navLinks.createTrip)}
        showText={!isCollapsed}
      />
      <LinkItem
        icon={<DestinationIcon height={18} width={18}/>}
        linkPath={'/portal/trips'}
        linkText={t(langKeys.navLinks.myTrips)}
        showText={!isCollapsed}
      />
      {isRestaurant && <>
        <LinkItem
          icon={<RestaurantSettingsIcon height={18} width={18}/>}
          linkPath={'/food/restaurant-settings'}
          linkText={t(langKeys.navLinks.openHours)}
          showText={!isCollapsed}
        />
      </>}
      <LinkItem
        icon={<UserIcon height={18} width={18}/>}
        linkPath={'/portal/settings'}
        linkText={t(langKeys.navLinks.accountSettings)}
        showText={!isCollapsed}
      />
    </ListContainer>
  );
};

LinkList.propTypes = {
  isCollapsed: PropTypes.bool,
  isRestaurant: PropTypes.bool,
}

export default observer(LinkList);
