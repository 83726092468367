import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import styled from 'styled-components';
import commonStyle from './commonStyle';

const SModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.white, 0.25)};
  }
`;

const StyledModal = props => {
  const { isVisible, title, bodyContent, footerContent, headerContent } = props;
  return (
    <SModal show={isVisible} onHide={props.onHide}>
      {(!!title || !!headerContent) && <Modal.Header closeButton>
        {!!title && <h4>{title}</h4>}
        {!!headerContent && headerContent}
      </Modal.Header>}
      <Modal.Body>{bodyContent}</Modal.Body>
      {!!footerContent && footerContent}
    </SModal>
  );
};

StyledModal.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  headerContent: PropTypes.element,
  bodyContent: PropTypes.element,
  footerContent: PropTypes.element,
  onHide: PropTypes.func,
};

export default StyledModal;
