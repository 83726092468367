import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const SortControlsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justify ?? 'center'};
  position: relative;
  width: 100%;
  z-index: 200;
`;

export const SortAscBtn = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 30px;
  width: 30px;
  & > .rotated {
    transition: all 0.2s ease-in;
    transform: rotate(180deg);
  }
  & > .not-rotated {
    transition: all 0.2s ease-in;
    transform: rotate(0deg);
  }
`;

export const ExportButton = styled(Button).attrs(() => ({
  variant: 'outline-dark'
}))``;

export const CloseButton = styled(Button).attrs(() => ({
  variant: 'outline-dark'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 700;
`;
