import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FoodOrderTabs from '../../../../../constants/foodOrderTabs';
import langKeys from '../../../../../i18n/lang/keys';
import commonStyle from '../../../../custom-ui/commonStyle';
import UrgrntOrdersIcon from '../../../../../assets/icons/urgent-icon.svg';
import FutureOrdersIcon from '../../../../../assets/icons/back-in-time.svg';
import DoneOrdersIcon from '../../../../../assets/icons/completed-time.svg';

const getTabIcon = (type, isGreen = false) => {
  switch (type) {
    case FoodOrderTabs.urgent:
      return <UrgrntOrdersIcon fill={isGreen ? commonStyle.colors.brandSuccess : commonStyle.colors.brandPrimary}/>;
    case FoodOrderTabs.future:
      return <FutureOrdersIcon fill={isGreen ? commonStyle.colors.brandSuccess : commonStyle.colors.brandPrimary}/>;
    case FoodOrderTabs.done:
    default:
      return <DoneOrdersIcon fill={isGreen ? commonStyle.colors.brandSuccess : commonStyle.colors.brandPrimary}/>;
  }
};

const TabContainer = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${commonStyle.colors.white};
  color: ${commonStyle.colors.greyMedium};
  border-top-left-radius: ${props => props.isFirst ? 15 : 0}px;
  border-top-right-radius: ${props => props.isLast ? 15 : 0}px;
  &.active {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: ${commonStyle.colors.greyLight};
    cursor: default;
    color: ${commonStyle.colors.brandPrimary};
    box-shadow: inset 0 0 6px 0 ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.1)};
  }
`;

const TabName = styled.h4`
  font-size: 24px;
  font-weight: 700;
  color: ${props => commonStyle.colors.alphaString(props.green ? commonStyle.colors.brandSuccess : commonStyle.colors.brandPrimary, 0.6)};
  margin: 0 8px;
  text-overflow: ellipsis;
  &.active {
    color: ${props => props.green ? commonStyle.colors.brandSuccess : commonStyle.colors.brandPrimary};
  }
  @media (max-width: 668px) {
    display: none;
  }
`;

const TabParent = styled.div`
  width: ${props => 100 / (props.numOfTabs || 3)}%;
  max-width: 315px;
  height: 100%;
  position: relative;
`;

const TabBlocker = styled.div`
  position: absolute;
  bottom: -5px;
  height: 10px;
  width: 100%;
  left: 0;
  background-color: ${commonStyle.colors.greyLight};
`;

const NewBadge = styled.div`
  background-color: ${commonStyle.colors.brandDanger};
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${commonStyle.colors.white};
  font-size: 12px;
  margin-right: 8px;
`;

const FoodTab = props => {
  const { type, isActive, numOfTabs, isFirst, isLast, tabInfo } = props;
  const { t } = useTranslation();
  const icon = useMemo(() => {
    return getTabIcon(type, type === FoodOrderTabs.urgent)
  }, [type]);

  return (
    <TabParent numOfTabs={numOfTabs}>
      <TabContainer
        isFirst={isFirst}
        isLast={isLast}
        className={`tab ${isActive ? 'active' : ''}`}
        onClick={props.onPress}
      >

        {!!tabInfo?.new && <NewBadge>{tabInfo.new}</NewBadge>}
        {icon}
        <TabName className={isActive ? 'active' : ''} green={type === FoodOrderTabs.urgent}>{t(langKeys.foodOrders[type])}{tabInfo?.total ? ` (${tabInfo.total})` : ''}</TabName>
      </TabContainer>
      {isActive && <TabBlocker />}
    </TabParent>
  );
};

FoodTab.propTypes = {
  type: PropTypes.string,
  numOfTabs: PropTypes.number,
  isActive: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  tabInfo: PropTypes.object,
};

export default FoodTab;
