import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CollapsibleContainer = styled.div`
  display: block;
  transition: opasity 0.4s;
  opacity: 1;
  &.hidden {
    display: none;
    opacity: 0;
  }
`;

const Collapsible = props => {
  const { isCollapsed } = props;
  const [parentClass, setParentClass] = useState('');

  useEffect(() => {
    setParentClass(isCollapsed ? 'hidden' : '');
  }, [isCollapsed]);

  return (
    <CollapsibleContainer className={parentClass}>
      {props.children}
    </CollapsibleContainer>
  );
};

Collapsible.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default Collapsible;
