import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCloseCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { useDebouncedEffect } from '../../../../../hooks/useDebouncedEffect';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import SearchIcon from '../../../../../assets/icons/search.svg';

const SearchBarContainer = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`;

const SearchInput = styled.input`
  border: 1px solid ${commonStyle.colors.brandPrimary};
  border-radius: 5px;
  width: 100%;
  background-color: transparent;
  height: 30px;
  padding-left: 45px;
  padding-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid ${commonStyle.colors.brandPrimary};
  }
`;

const SearchIco = styled(SearchIcon).attrs(() => ({
  fill: commonStyle.colors.brandPrimary
}))`
  position: absolute;
  top: ${6 + 16}px;
  left: ${20 + 16}px;
`;

const CloseIcon = styled(AiFillCloseCircle)`
  position: absolute;
  top: ${16 + 7}px;
  right: ${16 + 7}px;
  font-size: 16px;
  color: ${commonStyle.colors.brandDanger};
  &:hover {
    color: ${commonStyle.colors.greyMedium};
  }`;

const SearchBar = props => {
  const { t } = useTranslation();
  const { dataStores: { foodMenuStore } } = useStores();
  const { searchQuery } = foodMenuStore;
  const inputRef = useRef(null);

  useDebouncedEffect(() => {
    if (searchQuery.length > 2) {
      foodMenuStore.searchDishes();
    }
  }, 200, [searchQuery]);

  return (
    <SearchBarContainer>
      <SearchIco onClick={() => inputRef.current?.focus()}/>
      <SearchInput
        ref={inputRef}
        value={searchQuery}
        placeholder={t(langKeys.foodMenu.searchPlaceholder)}
        onChange={e => foodMenuStore.changeSearchQuery(e.target.value)}
      />
      {!!searchQuery.length && <CloseIcon onClick={() => foodMenuStore.changeSearchQuery('')} />}
    </SearchBarContainer>
  );
};

export default observer(SearchBar);
