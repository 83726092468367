import { observer } from 'mobx-react-lite';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CommentIcon from '../../../../../assets/icons/comment-icon.svg';
import DestinationIcon from '../../../../../assets/icons/destination.svg';
import EuroIcon from '../../../../../assets/icons/euro-icon.svg';
import PhoneIcon from '../../../../../assets/icons/phone-icon.svg';
import PickUpIcon from '../../../../../assets/icons/pickup.svg';
import UserIcon from '../../../../../assets/icons/profile.svg';
import ServiceIcon from '../../../../../assets/icons/service.svg';
import WayPointIcon from '../../../../../assets/icons/wp-icon.svg';
import { isNewYearEve, isPBDateValid } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import notify, { NotificationTypes } from '../../../../../services/notifier';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import StyledCheckbox from '../../../../custom-ui/styledCheckbox';
import StyledDropdownInput from '../../../../custom-ui/styledDropdownInput';
import StyledInput from '../../../../custom-ui/styledInput';
import StyledPlacesInput from '../../../../custom-ui/styledPlacesInput';
import DateTimeInput from '../../createTrip/form/dateTimeInput';

const initialState = {
  isPriceFixed: false,
  tripAmt: 0,
  preBookedTime: null,
  pickUpAddress: '',
  destAddress: '',
  srcLoc: null,
  destLoc: null,
  waypoints: [],
  riderFirstName: '',
  riderLastName: '',
  taxiType: '',
  tripComment: '',
  phoneNo: '',
  withInvoice: false,
  officeCarrier: null
};

const formatDate = date => moment(date).format('DD MMM YYYY [um] HH:mm');

const FormContainer = styled.div``;

const BookedOnContainer = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 20px 0;
`;

const SButton = styled(Button)`
`;

const Separator = styled.div`
  text-align: center;
  margin: 0 0 20px 0;
  color: ${commonStyle.colors.greyMedium};
  font-size: 12px;
  border-color: ${commonStyle.colors.greyMedium};
  border-style: dotted;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
`;

const EditPBForm = props => {
  const { initialTripData } = props;
  const { t } = useTranslation();
  const [pbData, setPBData] = useState(initialState);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const buttonPressed = useRef(false);
  const { dataStores: { authStore: { userData }, createTripStore } } = useStores();

  useEffect(() => {
    if (!initialTripData) {
      setPBData(initialState);
      setInitialData(null);
    } else {
      const data = {
        isPriceFixed: initialTripData.isPriceFixed,
        preBookedTime: initialTripData.preBookedTime,
        pickUpAddress: initialTripData.pickUpAddress,
        officeCarrier: initialTripData.officeCarrier,
        destAddress: initialTripData.destAddress,
        tripAmt: initialTripData.tripAmt,
        srcLoc: initialTripData.srcLoc,
        destLoc: initialTripData.destLoc,
        waypoints: initialTripData.waypoints,
        riderFirstName: initialTripData.riderId?.fname,
        riderLastName: initialTripData.riderId?.lname,
        taxiType: initialTripData.taxiType,
        tripComment: initialTripData.tripComment,
        phoneNo: initialTripData.riderId?.phoneNo,
        withInvoice: initialTripData.withInvoice,
      };
      setPBData(data);
      setInitialData(data);
    }
    buttonPressed.current = false;
  }, [initialTripData]);

  useEffect(() => {
    if (!pbData.officeCarrier) return;
    (async () => {
      const cats = await createTripStore.getCategoriesByCityId(pbData.officeCarrier);
      setCategoryOptions(cats.map(cat => ({
        key: cat._id,
        value: cat.name,
        label: cat.name,
        icon: <ServiceIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>
      })));
    })();
  }, [pbData.officeCarrier, createTripStore]);


  const formErrors = useMemo(() => {
    return {
      areEqual: !_.isEqual(pbData, initialData),
      pickupValid: !!pbData.srcLoc,
      waypointsValid: pbData.waypoints.map(wp => !!wp.location),
      destinationValid: !!pbData.destLoc || !!(pbData.waypoints.length && pbData.destLoc) || (!pbData.waypoints.length && !pbData.destLoc),
      pbTimeValid: !!pbData.preBookedTime && isPBDateValid(pbData.preBookedTime),
    };
  }, [pbData, initialData]);

  const handlePlacesError = mes => notify(mes, NotificationTypes.error);

  const removeWP = (index) => {
    const wps = pbData.waypoints.filter((wp, i) => i !== index);
    setPBData({ ...pbData, waypoints: wps });
  };

  const updateWaypoint = ({ address, latLng }, index) => {
    const wps = [...pbData.waypoints];
    wps[index] = {
      address,
      location: latLng
    };
    setPBData({ ...pbData, waypoints: wps });
  };

  const handlePriceFixed = () => {
    const isPriceFixed = !pbData.isPriceFixed;
    const defaultTripAmt = initialTripData.tripAmt;
    setPBData({ ...pbData, isPriceFixed, tripAmt: defaultTripAmt });
  };

  const handleSubmit = () => {
    if (buttonPressed.current) return;
    buttonPressed.current = true;
    props.onConfirm(pbData);
  }

  return (
    <FormContainer>
      <BookedOnContainer>
        {t(langKeys.myRides.bookedAt)} {formatDate(initialTripData?.bookedAt)}
      </BookedOnContainer>
      <Separator>{t(langKeys.myRides.tripDetails)}</Separator>
      <DateTimeInput
        value={pbData.preBookedTime ? moment(pbData.preBookedTime) : null}
        label={t(langKeys.createTrip.pbTime)}
        locale={'de'}
        isValid={formErrors.pbTimeValid}
        meta={null}
        errorMessage={isNewYearEve(pbData.preBookedTime) ? t(langKeys.createTrip.errorMessages.cannotMakePrebookingNY) : t(langKeys.createTrip.errorMessages.preBooking)}
        onChange={dt => setPBData({ ...pbData, preBookedTime: dt })}
      />
      <StyledPlacesInput
        label={t(langKeys.createTrip.pickupAddress)}
        address={pbData.pickUpAddress}
        location={pbData.srcLoc}
        isValid={formErrors.pickupValid}
        isClearable={true}
        prependIcon={<PickUpIcon height={21} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onError={handlePlacesError}
        onSelect={dt => setPBData({ ...pbData, pickUpAddress: dt.address, srcLoc: dt.latLng })}
        onChange={addr => setPBData({ ...pbData, pickUpAddress: addr, srcLoc: null })}
      />
      {pbData.waypoints.map((wp, i) => <StyledPlacesInput
        key={`wp-${i}`}
        label={t(langKeys.createTrip.through)}
        address={wp.address}
        location={wp.location}
        isClearable={true}
        isValid={formErrors.waypointsValid[i]}
        prependIcon={<WayPointIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onError={handlePlacesError}
        onClear={() => removeWP(i)}
        onSelect={dt => updateWaypoint(dt, i)}
        onChange={addr => updateWaypoint({ address: addr, latLng: null }, i)}
      />)}
      <StyledPlacesInput
        label={t(langKeys.createTrip.destinationAddress)}
        address={pbData.destAddress}
        location={pbData.destLoc}
        isClearable={true}
        isValid={formErrors.destinationValid}
        prependIcon={<DestinationIcon height={18} width={18} fill={commonStyle.colors.brandPrimary}/>}
        onError={handlePlacesError}
        onSelect={dt => setPBData({ ...pbData, destAddress: dt.address, destLoc: dt.latLng })}
        onChange={addr => setPBData({ ...pbData, destAddress: addr, destLoc: null })}
      />
      {userData.fixedFareByDefault && <StyledInput
        label={t(langKeys.myRides.totalFare)}
        value={pbData.tripAmt}
        isClearable={true}
        prependIcon={<EuroIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onChange={(v) => setPBData({ ...pbData, tripAmt: +v })}
        disabled={!pbData.destLoc || !pbData.isPriceFixed}
        inputProps={{
          type: 'number',
          min: 0,
          step: 0.05,
        }}
        resetValue={initialTripData?.tripAmt || 0}
      />
      }
      <CheckboxContainer>
        <StyledCheckbox
          value={pbData.withInvoice}
          label={t(langKeys.createTrip.withInvoice)}
          onChange={() => setPBData({ ...pbData, withInvoice: !pbData.withInvoice })}
        />
        {userData.fixedFareByDefault && <StyledCheckbox
          value={pbData.isPriceFixed}
          label={t(langKeys.createTrip.fixedFare)}
          onChange={handlePriceFixed}
        />}
      </CheckboxContainer>
      <StyledDropdownInput
        options={categoryOptions}
        additionalProps={{
          isSearchable: false
        }}
        onSelect={({ value }) => setPBData({ ...pbData, taxiType: value })}
        value={categoryOptions.find(c => c.value === pbData.taxiType)}
        label={t(langKeys.myRides.category)}
      />
      <Separator>{t(langKeys.myRides.riderDetails)}</Separator>
      <StyledInput
        label={t(langKeys.createTrip.fname)}
        value={pbData.riderFirstName}
        isClearable={true}
        prependIcon={<UserIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onChange={(v) => setPBData({ ...pbData, riderFirstName: v })}
        resetValue={''}
      />
      <StyledInput
        label={t(langKeys.createTrip.lname)}
        value={pbData.riderLastName}
        isClearable={true}
        prependIcon={<UserIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onChange={(v) => setPBData({ ...pbData, riderLastName: v })}
        resetValue={''}
      />
      <StyledInput
        label={t(langKeys.createTrip.phoneNo)}
        value={pbData.phoneNo}
        isClearable={true}
        prependIcon={<PhoneIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onChange={(v) => setPBData({ ...pbData, phoneNo: v })}
        resetValue={''}
      />
      <StyledInput
        label={t(langKeys.createTrip.tripComment)}
        value={pbData.tripComment}
        isClearable={true}
        prependIcon={<CommentIcon height={20} width={20} fill={commonStyle.colors.brandPrimary}/>}
        onChange={(v) => setPBData({ ...pbData, tripComment: v })}
        resetValue={''}
      />
      <ButtonContainer>
        <SButton
          variant={'outline-dark'}
          onClick={props.onHide}
        >
          {t(langKeys.hide)}
        </SButton>
        <SButton
          variant={'success'}
          onClick={handleSubmit}
          disabled={Object.values(formErrors).some(v => !v)}
        >
          {t(langKeys.confirm)}
        </SButton>
      </ButtonContainer>
    </FormContainer>
  );
};

EditPBForm.propTypes = {
  initialTripData: PropTypes.object,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default observer(EditPBForm);
