import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import { SubmitButton } from '../../food/restaurant-settings/styles';
import FormInputs from './formInputs';
import * as S from './styles';

const AccountSettings = props => {
  const { t } = useTranslation();
  useTitle(t(langKeys.navLinks.accountSettings));
  const { dataStores: { authStore, accountSettings } } = useStores();
  const [submitActive, setSubmitActive] = useState(false);
  const { isDataChanged, currentUserData, isDataValid } = accountSettings;

  useEffect(() => {
    accountSettings.setUserData(authStore.userData);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSubmitActive(isDataChanged() && isDataValid());
  }, [isDataChanged, currentUserData, isDataValid]);

  const handleSubmit = () => {
    accountSettings.updateAccountData();
  }

  if (!accountSettings.currentUserData) return null;

  return (
    <S.Container>
      <S.FormContainer>
        <FormInputs/>
      </S.FormContainer>
      <S.ButtonsContainer>
        <Button
          variant={'outline-dark'}
          onClick={() => accountSettings.changeCurrentData(accountSettings.initialUserData)}
        >
          {t(langKeys.revert)}
        </Button>
        <SubmitButton
          disabled={!submitActive}
          onClick={handleSubmit}
          style={{ margin: '0 0 0 30px' }}
        >
          {t(langKeys.confirm)}
        </SubmitButton>
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default observer(AccountSettings);
