import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { formatDayTimeValueToDecimal } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import Toggler from '../../../../custom-ui/toggler';
import * as S from '../styles';
import { DownIcon, openHoursSelectorStyles } from './dayRow';

const LunchControlRow = props => {
  const { orderingData, timeOptions } = props;
  const { t } = useTranslation();

  const isLunchEnabled = useMemo(() => {
    if (!orderingData?.length) return false;
    const nearestWorkingDay = orderingData.find(d => d.isWorking);
    if (!nearestWorkingDay) return false;
    const dinnerFinishTime = formatDayTimeValueToDecimal(nearestWorkingDay.dinnerTo);
    const dinnerStartTime = formatDayTimeValueToDecimal(nearestWorkingDay.dinnerFrom);
    const openTime = formatDayTimeValueToDecimal(nearestWorkingDay.from);
    const closeTime = formatDayTimeValueToDecimal(nearestWorkingDay.to);
    return dinnerFinishTime >= openTime && dinnerStartTime <= closeTime;
  }, [orderingData]);

  const handleLunchEnableToggle = () => {
    if (isLunchEnabled) {
      props.onChange(orderingData.map(day => ({
        ...day,
        dinnerFrom: '01:00',
        dinnerTo: '01:30',
      })));
    } else {
      props.onChange(orderingData.map(day => ({
        ...day,
        dinnerFrom: '13:00',
        dinnerTo: '14:00',
      })));
    }
  };

  const handleTimeChange = (value, key) => {
    props.onChange(orderingData.map(day => ({
      ...day,
      [key]: value,
    })));
  };

  return (
    <S.DayRow>
      <S.OffsetCol/>
      <S.DayContainer>
        <S.TogglerContainer>
          <Toggler
            isEnabled={isLunchEnabled}
            height={20}
            width={40}
            onChange={handleLunchEnableToggle}
          />
        </S.TogglerContainer>
        <S.DayStr>{t(langKeys.restaurantWorkingHours.lunch)}</S.DayStr>
      </S.DayContainer>
      <S.TimeCol active={true}>
        {!isLunchEnabled && 'X'}
        {!!isLunchEnabled && <Select
          components={{
            IndicatorsContainer: DownIcon,
          }}
          onChange={v => handleTimeChange(v.value, 'dinnerFrom')}
          options={timeOptions}
          styles={openHoursSelectorStyles}
          isSearchable={false}
          value={timeOptions.find(v => v.value === orderingData[0].dinnerFrom)}
        />}
      </S.TimeCol>
      <S.TimeCol active={true}>
        {!isLunchEnabled && 'X'}
        {!!isLunchEnabled && <Select
          components={{
            IndicatorsContainer: DownIcon,
          }}
          onChange={v => handleTimeChange(v.value, 'dinnerTo')}
          options={timeOptions}
          styles={openHoursSelectorStyles}
          isSearchable={false}
          value={timeOptions.find(v => v.value === orderingData[0].dinnerTo)}
        />}
      </S.TimeCol>
      <S.OffsetCol/>
    </S.DayRow>
  );
};

LunchControlRow.propTypes = {
  orderingData: PropTypes.array,
  timeOptions: PropTypes.array,
  onChange: PropTypes.func,
};

export default LunchControlRow;
