import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import * as FoodOrderService from '../../../services/foodOrdersService';
import notify, { NotificationTypes } from '../../../services/notifier';

export class FoodOrderDetails {
  @observable orderDetails = null;
  @observable isFetching = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action getFoodOrderDetails = async (orderId) => {
    runInAction(() => {
      this.isFetching = true;
    });
    const resp = await FoodOrderService.getOrderDetails(this.rootStore.dataStores.authStore.token, orderId);
    if (resp.success) {
      runInAction(() => {
        this.orderDetails = resp.data;
      })
    } else {
      notify(resp.message, NotificationTypes.error);
    }
    runInAction(() => {
      this.isFetching = false;
    });
  }

  @action clearOrderDetails = () => {
    this.orderDetails = null;
    this.isFetching = false;
  }

  @action setOrderAsCompleted = async (orderId) => {
    runInAction(() => {
      this.isFetching = true;
    });
    const resp = await FoodOrderService.setOrderAsCompleted(this.rootStore.dataStores.authStore.token, orderId);
    if (resp.success) {
      runInAction(() => {
        this.orderDetails = resp.data;
      })
    } else {
      notify(resp.message, NotificationTypes.error);
    }
    runInAction(() => {
      this.isFetching = false;
    });
  }
}
