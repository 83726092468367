import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../../../assets/icons/profile.svg';
import PickUpIcon from '../../../../../assets/icons/pickup.svg';
import WayPointIcon from '../../../../../assets/icons/wp-icon.svg';
import DestinationIcon from '../../../../../assets/icons/destination.svg';
import CommentIcon from '../../../../../assets/icons/comment-icon.svg';
import PhoneIcon from '../../../../../assets/icons/phone-icon.svg';
import EuroIcon from '../../../../../assets/icons/euro-icon.svg';
import BookingIcon from '../../../../../assets/icons/booking_icon.svg';
import RideNowIcon from '../../../../../assets/icons/start-trip.svg';
import CityIcon from '../../../../../assets/icons/cityIcon.svg';
import styled from 'styled-components';
import { isNewYearEve, isPBDateValid, roundUpDateTo5Minutes } from '../../../../../helpers/utils';
import { useDebouncedEffect } from '../../../../../hooks/useDebouncedEffect';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import AlertPanel from '../../../../custom-ui/alertPanel';
import commonStyle from '../../../../custom-ui/commonStyle';
import StyledCheckbox from '../../../../custom-ui/styledCheckbox';
import StyledDropdownInput from '../../../../custom-ui/styledDropdownInput';
import StyledInput, { StyledFeedback } from '../../../../custom-ui/styledInput';
import StyledPlacesInput from '../../../../custom-ui/styledPlacesInput';
import Collapsible from '../../../../HOCs/Collapsible';
import CarCategoriesSelector from './carCategoriesSelector';
import DateTimeInput from './dateTimeInput';
import RequestStatusTool from './requestStatusTool';
import TripSearchInput from './tripSearchInput';
import WPInstruments from './wpInstruments';

const DRIVERS_REFRESH_INTERVAL = 45;

const ZoneSeparation = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Title = styled.h4`
  font-weight: 700;
  padding-left: 15px;
  margin: 16px 0;
`;

const InputsContainer = styled.section`
  display: ${props => props.requesting ? 'none' : 'block'};
  padding: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  width: ${props => props.width};
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  height: 40px;
`;

const CreateTripForm = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore, createTripStore } } = useStores();
  const [fixedTripFare, setFixedTripFare] = useState(0);
  const [arriveData, setArriveData] = useState(null);
  const driversRefreshInterval = useRef(null);
  const submitPressed = useRef(false);

  const { userData, userPickups, lang } = authStore;

  const {
          isPreBookingsEnabled,
          selectedCarCategory,
          nearByDrivers,
          isPB,
          cities,
          selectedCity,
          officeCarrier,
          preBookedTime,
          riderFirstName,
          riderLastName,
          tripComment,
          pickUpAddress,
          destAddress,
          destLoc,
          waypoints,
          srcLoc,
          phoneNo,
          withInvoice,
          isError,
          fetchMessage,
          fareData,
          carCategories,
          taxiType,
          isTripRequest
        } = createTripStore;

  useDebouncedEffect(() => {
    if (driversRefreshInterval.current) {
      clearInterval(driversRefreshInterval.current);
    }
    createTripStore.getNearByDrivers();
    driversRefreshInterval.current = setInterval(() => {
      createTripStore.getNearByDrivers();
    }, DRIVERS_REFRESH_INTERVAL * 1000);
  },
    200,
    [srcLoc, officeCarrier, createTripStore],
    () => {
      if (driversRefreshInterval.current) {
        clearInterval(driversRefreshInterval.current);
      }
    });

  useEffect(() => {
    createTripStore.setSelectedCity(userData.defaultCity);
  }, [userData, cities, createTripStore]);

  useDebouncedEffect(() => {
    createTripStore.getCategories();
  }, 200, [officeCarrier, createTripStore]);

  useEffect(() => {
    createTripStore.resetTripData();
  }, [userData, createTripStore]);

  useEffect(() => {
    createTripStore.setPBTime(isPB ? moment((roundUpDateTo5Minutes()).toJSON()).add(30, 'minute').toDate() : null);
  }, [isPB, createTripStore]);

  useEffect(() => {
    createTripStore.toggleIsBP(false);
  }, [createTripStore, isPreBookingsEnabled]);

  useEffect(() => {
    const fareDetails = !!fareData && fareData.cost && taxiType && fareData.cost[taxiType] ? fareData.cost[taxiType] : null;
    createTripStore.setFareDetails(fareDetails);
  }, [taxiType, fareData, createTripStore]);

  useDebouncedEffect(() => {
    if (!selectedCarCategory) return;
    const nbd = toJS(nearByDrivers);
    if (!!srcLoc && nbd?.length) {
      const categoryDrivers = nbd.filter(drv => drv.carDetails.carCategory.includes(`${selectedCarCategory._id}`));
      if (categoryDrivers.length) {
        const minTime = _.min(categoryDrivers.map(drv => drv.arrivalTime));
        setArriveData(minTime);
      } else {
        setArriveData(null);
      }
    } else {
      setArriveData(null);
    }
  }, 200, [srcLoc, selectedCarCategory, nearByDrivers])

  useEffect(() => {
    createTripStore.calculateFare();
  }, [destLoc, srcLoc, waypoints, preBookedTime, officeCarrier, createTripStore]);

  const handlePlacesError = message => {
    createTripStore.loaded(true, message);
  };

  const formErrors = useMemo(() => {
    return {
      pickupValid: !!srcLoc,
      waypointsValid: waypoints.map(wp => !!wp.location),
      destinationValid: !!destLoc || !!(waypoints.length && destLoc) || (!waypoints.length && !destLoc),
      pbTimeValid: !isPB || (isPB && isPBDateValid(preBookedTime)),
      fareDataValid: (destLoc && fareData) || (!destLoc && !fareData)
    };
  }, [preBookedTime, isPB, srcLoc, waypoints, destLoc, fareData]);

  const checkDisabled = () => {
    return isTripRequest || Object.values(formErrors).some(value => Array.isArray(value) ? value.some(v => !v) : !value);
  }

  const pbOptions = useMemo(() => [
    {
      key: 'rideNow',
      value: false,
      label: t(langKeys.createTrip.rideNow),
      icon: <RideNowIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />
    }, {
      key: 'rideLater',
      value: true,
      label: t(langKeys.createTrip.preBooking),
      icon: <BookingIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />
    }], [t]);

  const cityOptions = useMemo(() => cities.map(city => ({
    key: city._id,
    value: `${city._id}`,
    label: city.cityName,
    icon: <CityIcon fill={commonStyle.colors.brandPrimary} />
  })), [cities]);

  const updateWaypoint = (data, index) => {
    const currentWps = [...waypoints];
    currentWps[index] = {
      address: data.address,
      location: data.latLng
    }
    createTripStore.updateWaypoints(currentWps);
  }

  const removeWP = (index) => {
    createTripStore.updateWaypoints(waypoints.filter((wp, i) => i !== index));
  }

  const handleIsPB = (data) => {
    createTripStore.toggleIsBP(data.value);
  };

  const handleCitySelect = (data) => {
    createTripStore.setSelectedCity(data.value);
  }

  const handleFixedFare = (value) => {
    const v = +value;
    createTripStore.toggleFixedPrice(!!v);
    setFixedTripFare(v);
  }

  const handleSwitchDirections = () => {
    const srcAddr = pickUpAddress;
    const sourceLoc = [...srcLoc];
    createTripStore.setPickup(destAddress, [...destLoc]);
    createTripStore.setDestination(srcAddr, sourceLoc);
  };

  const handleAddDestination = () => {
    createTripStore.updateWaypoints([...waypoints, { address: destAddress, location: [...destLoc] }]);
    createTripStore.setDestination('', null);
  };

  const handleAddWaypoint = () => {
    createTripStore.updateWaypoints([...waypoints, { address: '', location: null }]);
  };

  const handleCategorySelect = (catId) => {
    createTripStore.setSelectedCarCategory(catId);
  };

  const handleReset = () => {
    setFixedTripFare(0);
    createTripStore.resetTripData();
  };

  const handleSubmit = () => {
    if (submitPressed.current) return;
    submitPressed.current = true;
    setTimeout(() => {
      submitPressed.current = false;
    }, 1000);
    createTripStore.requestTrip(fixedTripFare);
  };

  const {
          pickupValid,
          waypointsValid,
          destinationValid,
          pbTimeValid,
        } = formErrors;

  return (
    <Form>
      <Title>{t(langKeys.createTrip.title)}</Title>
      <InputsContainer requesting={isTripRequest}>
        <AlertPanel message={fetchMessage} isSuccess={!isError}/>
        <RequestStatusTool />
        <TripSearchInput userData={userData}/>
        <ZoneSeparation>{t(langKeys.myRides.riderDetails)}</ZoneSeparation>
        <StyledInput
          label={t(langKeys.createTrip.fname)}
          value={riderFirstName}
          isClearable={true}
          prependIcon={<UserIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onChange={(v) => createTripStore.setFName(v)}
          resetValue={''}
        />
        <StyledInput
          label={t(langKeys.createTrip.lname)}
          value={riderLastName}
          isClearable={true}
          prependIcon={<UserIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onChange={(v) => createTripStore.setLName(v)}
          resetValue={''}
        />
        <StyledInput
          label={t(langKeys.createTrip.phoneNo)}
          value={phoneNo}
          isClearable={true}
          prependIcon={<PhoneIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onChange={(v) => createTripStore.setPhoneNo(v)}
          resetValue={''}
        />
        <StyledInput
          label={t(langKeys.createTrip.tripComment)}
          value={tripComment}
          isClearable={true}
          prependIcon={<CommentIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onChange={(v) => createTripStore.setTripComment(v)}
          resetValue={''}
        />
        <ZoneSeparation>{t(langKeys.myRides.tripDetails)}</ZoneSeparation>
        <StyledPlacesInput
          label={t(langKeys.createTrip.pickupAddress)}
          address={pickUpAddress}
          location={srcLoc}
          isValid={pickupValid}
          isClearable={true}
          prependIcon={<PickUpIcon height={21} width={20} fill={commonStyle.colors.brandPrimary} />}
          onError={handlePlacesError}
          knownPlaces={toJS(userPickups)}
          onSelect={dt => createTripStore.setPickup(dt.address, dt.latLng)}
          onChange={addr => createTripStore.setPickup(addr, null)}
        />
        {waypoints.map((wp, i) => <StyledPlacesInput
          key={`wp-${i}`}
          label={t(langKeys.createTrip.through)}
          address={wp.address}
          location={wp.location}
          isClearable={true}
          isValid={waypointsValid[i]}
          prependIcon={<WayPointIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onError={handlePlacesError}
          onClear={() => removeWP(i)}
          knownPlaces={toJS(userPickups)}
          onSelect={dt => updateWaypoint(dt, i)}
          onChange={addr => updateWaypoint({ address: addr, latLng: null }, i)}
        />)}
        <StyledPlacesInput
          label={t(langKeys.createTrip.destinationAddress)}
          address={destAddress}
          location={destLoc}
          isClearable={true}
          isValid={destinationValid}
          knownPlaces={toJS(userPickups)}
          prependIcon={<DestinationIcon height={18} width={18} fill={commonStyle.colors.brandPrimary} />}
          onError={handlePlacesError}
          onSelect={dt => createTripStore.setDestination(dt.address, dt.latLng)}
          onChange={addr => createTripStore.setDestination(addr, null)}
        />
        <WPInstruments
          onSwitch={handleSwitchDirections}
          onAddDestination={handleAddDestination}
          onAddWP={handleAddWaypoint}
          totalWps={waypoints.length}
          disabled={(!destLoc || !!(waypoints.length && waypoints.some(wp => !wp.location)))}
        />
        <StyledCheckbox
          value={withInvoice}
          label={t(langKeys.createTrip.withInvoice)}
          onChange={() => createTripStore.toggleWithInvoice(!withInvoice)}
        />
        {userData.fixedFareByDefault && <StyledInput
          label={t(langKeys.createTrip.fixedFare)}
          value={fixedTripFare}
          isClearable={true}
          prependIcon={<EuroIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />}
          onChange={(v) => handleFixedFare(v)}
          disabled={!destLoc}
          inputProps={{
            type: 'number',
            min: 0,
            step: 0.05,
          }}
          resetValue={''}
        />}
        {isPreBookingsEnabled && <StyledDropdownInput
          label={t(langKeys.createTrip.preBookingLabel)}
          options={pbOptions}
          additionalProps={{
            isSearchable: false
          }}
          value={pbOptions.find(op => op.value === isPB)}
          onSelect={handleIsPB}
        />}
        <Collapsible isCollapsed={!isPB}>
          <DateTimeInput
            value={preBookedTime}
            label={t(langKeys.createTrip.pbTime)}
            locale={lang}
            isValid={pbTimeValid}
            meta={fareData?.meta ?? null}
            errorMessage={isNewYearEve(preBookedTime) ? t(langKeys.createTrip.errorMessages.cannotMakePrebookingNY) : t(langKeys.createTrip.errorMessages.preBooking)}
            onChange={dt => createTripStore.setPBTime(dt)}
          />
        </Collapsible>
        {userData?.canChangeCity && <StyledDropdownInput
          options={cityOptions}
          additionalProps={{
            isSearchable: false
          }}
          onSelect={handleCitySelect}
          value={cityOptions.find(c => c.value === `${selectedCity?._id}`)}
          label={t(langKeys.createTrip.serviceCity)}
        />
        }
        <CarCategoriesSelector
          categories={carCategories}
          activeCategory={taxiType}
          fareData={fareData}
          fixedFare={fixedTripFare}
          onSelect={handleCategorySelect}
        />
        {arriveData !== null && !isPB &&
        <StyledFeedback
          style={{
            margin: '16px 0 8px'
          }}
          messageType={'success'}
        >
          {t(langKeys.createTrip.taxiWillArrive, { taxiType, arriveData })}
        </StyledFeedback>
        }
        {arriveData === null && !isPB &&
        <StyledFeedback
          style={{
            margin: '16px 0 8px'
          }}
          messageType={'error'}
        >
          {t(langKeys.createTrip.noCarsAvailable)}
        </StyledFeedback>
        }
      </InputsContainer>
      <ButtonsContainer>
        <StyledButton
          width={'35%'}
          variant={'outline-dark'}
          onClick={handleReset}
        >
          {t(langKeys.createTrip.reset)}
        </StyledButton>
        <StyledButton
          width={'60%'}
          variant={'dark'}
          onClick={handleSubmit}
          disabled={checkDisabled()}
        >
          {t(langKeys.createTrip.submit)}
        </StyledButton>
      </ButtonsContainer>
    </Form>
  );
};

export default observer(CreateTripForm);
