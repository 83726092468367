import { observer } from 'mobx-react-lite';
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStores } from '../../../../stores/helpers/use-stores';
import commonStyle from '../../../custom-ui/commonStyle';

const langs = [
  {
    label: 'DE',
    value: 'de'
  }, {
    label: 'EN',
    value: 'en'
  }];

const Content = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LangBtn = styled.div`
  cursor: ${props => !props.active ? 'pointer' : 'default'};
  color: ${props => props.active ? props.activeColor : props.inactiveColor};
  font-size: 18px;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  border: 1px solid ${props => props.color};
  margin: 0 8px;
`;

const LanguageSelector = props => {
  const { theme } = props;

  const colors = useMemo(() => {
    if (theme === 'dark') {
      return {
        activeColor: commonStyle.colors.brandPrimary,
        inactiveColor: commonStyle.colors.greyMedium
      }
    }
    return {
      activeColor: commonStyle.colors.background,
      inactiveColor: commonStyle.colors.alphaString(commonStyle.colors.background, 0.4)
    };
  }, [theme]);
  const {
    dataStores: {
      authStore
    }
  } = useStores();

  const { lang } = authStore;

  return (
    <Content>
      {langs.map((l, i) => <Fragment key={l.label}>
        <LangBtn
          activeColor={colors.activeColor}
          inactiveColor={colors.inactiveColor}
          active={l.value === lang}
          onClick={() => {
            if (l.value === lang) return;
            authStore.updateLanguage(l.value);
          }}
        >
          {l.label}
        </LangBtn>
        {i < (langs.length - 1) && <Separator color={colors.activeColor} />}
      </Fragment>)}
    </Content>
  );
};

LanguageSelector.propTypes = {
  theme: PropTypes.string,
};

export default observer(LanguageSelector);
