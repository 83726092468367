import { persistence } from 'mobx-persist-store';
import AuthStore, { authPersistOptions } from './auth';
import TripCreate, { createTripPersistOptions } from './createTrip';
import { FoodMenu } from './foodMenu';
import { FoodOrderDetails } from './foodOrderDetails';
import FoodOrders from './foodOrders';
import MyRides, { myRidesPersistOptions } from './myRides';
import { RestaurantSettings } from './restaurantSettings';
import { SettingsStore } from './settings';

export default class DataStore {
  constructor(rootStore) {
    this.authStore = persistence(authPersistOptions)(new AuthStore(rootStore));
    this.createTripStore = persistence(createTripPersistOptions)(new TripCreate(rootStore));
    this.myRidesStore = persistence(myRidesPersistOptions)(new MyRides(rootStore));
    this.foodOrdersStore = new FoodOrders(rootStore);
    this.foodMenuStore = new FoodMenu(rootStore);
    this.foodOrdersDetails = new FoodOrderDetails(rootStore);
    this.restaurantSettings = new RestaurantSettings(rootStore);
    this.accountSettings = new SettingsStore(rootStore);
  }
}
