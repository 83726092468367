import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLogin } from 'react-icons/ai';

import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import langKeys from '../../../../i18n/lang/keys';

const ButtonIcon = styled(AiOutlineLogin)`
  color: #FFF;
  margin-left: 10px;
  font-size: 18px;
  padding-bottom: 2px;
`;

const initialState = {
  email: '',
  password: ''
};

const LoginForm = props => {
  const [data, setData] = useState(initialState);
  const emailInput = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    emailInput?.current.focus();
  }, []);

  return (
    <Form style={{ width: '100%' }} onSubmit={e => props.onSubmit(e, data)}>
      <FormGroup>
        <FormControl
          ref={emailInput}
          autoComplete={'email'}
          value={data.email}
          placeholder={t(langKeys.login.email)}
          onChange={e => setData({ ...data, email: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormControl
          value={data.password}
          type={'password'}
          autoComplete={'current-password'}
          placeholder={t(langKeys.login.password)}
          onChange={e => setData({ ...data, password: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Button
          block
          type={'submit'}
          variant={'dark'}
          disabled={!data.email || !data.password}
        >
          {t(langKeys.login.login)}
          <ButtonIcon />
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginForm;
