import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineClose } from 'react-icons/ai';

import styled from 'styled-components';
import langKeys from '../../../i18n/lang/keys';
import commonStyle from '../../custom-ui/commonStyle';

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${commonStyle.colors.background};
  cursor: pointer;
  height: 40px;
  color: ${commonStyle.colors.background};
  font-size: 18px;
  transition: background-color 0.4s;

  &:hover {
    background-color: ${commonStyle.colors.alphaString(commonStyle.colors.background, 0.1)};
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin-left: ${props => props.show ? 14 : 0}px;
  display: ${props => props.show ? 'block' : 'none'};
`;

const CollapseButton = props => {
  const { fullWidth, closeOnly } = props;
  const { t } = useTranslation();

  const icon = useMemo(() => {
    if (closeOnly) {
      return <AiOutlineClose/>;
    }
    return fullWidth ? <AiOutlineDoubleLeft/> : <AiOutlineDoubleRight/>
  }, [fullWidth, closeOnly]);

  return (
    <CollapseContainer onClick={props.onToggle}>
      {icon}
      <Text show={!!closeOnly}>{t(langKeys.sidebar.close)}</Text>
      <Text show={!closeOnly && fullWidth}>{t(langKeys.sidebar.collapse)}</Text>
    </CollapseContainer>
  );
};

CollapseButton.propTypes = {
  onToggle: PropTypes.func,
  fullWidth: PropTypes.bool,
  closeOnly: PropTypes.bool,
};

export default CollapseButton;
