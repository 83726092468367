import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import { MainContainer } from '../orders';
import RestaurantOperatingControl from './restaurantOperating';
import WorkingHours from './workingHours';

const RestaurantSettings = props => {
  const { t } = useTranslation();
  const { dataStores: { restaurantSettings: { getRestaurantData } } } = useStores();
  useTitle(t(langKeys.navLinks.openHours));

  useEffect(() => {
    getRestaurantData();
  }, [getRestaurantData]);

  return (
    <MainContainer>
      <RestaurantOperatingControl />
      <WorkingHours />
    </MainContainer>
  );
};

export default observer(RestaurantSettings);
