import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import commonStyle from '../../../custom-ui/commonStyle';

export const DishesContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  padding: 16px 16px;
  @media (max-width: 480px) {
    padding: 16px 0;
  }
`;

export const RestaurantCategoryContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px;
  margin-bottom: 50px;
  @media (max-width: 480px) {
    padding: 0 8px;
  }
`;

export const CategoryName = styled.h4.attrs(() => ({}))`
  color: ${commonStyle.colors.brandPrimary};
  font-size: 24px;
  font-weight: 700;
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const MenuBox = styled.div.attrs(() => ({}))`
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const MenuItemLine = styled.div.attrs(() => ({}))`
  min-height: 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  padding: 0 16px;
  width: 100%;

  &:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:nth-child(odd) {
    background-color: ${commonStyle.colors.white};
  }
`;

export const EditButton = styled(Button).attrs(() => ({
  variant: 'outline-dark',
  size: 'sm'
}))`
  height: 24px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${commonStyle.colors.brandPrimary};

  &:hover,
  &:not(:disabled):not(.disabled):active {
    fill: ${commonStyle.colors.white};
  }
`;

export const MenuShortBlock = styled.div.attrs(() => ({}))`
  flex: 1;
  @media (max-width: 490px) {
    display: none;
  }
`;

export const EditButtonBlock = styled.div.attrs(() => ({}))`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
`;

export const CenteredShortBlock = styled.div.attrs(() => ({}))`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  @media (max-width: 490px) {
    flex: 2;
  }
`;

export const PriceBlock = styled.div.attrs(() => ({}))`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  @media (max-width: 490px) {
    display: none;
  }
`;

export const DishNameBlock = styled.div.attrs(() => ({}))`
  flex: 3;
  overflow: hidden;
  padding-right: 8px;
  overflow: ${props => props.hideText ? 'hidden' : 'auto'};
  text-overflow: ellipsis;
  white-space: ${props => props.hideText ? 'nowrap' : 'normal'};
  @media (max-width: 900px) {
    flex: 8;
  }
`;

export const DishDescBlock = styled.div.attrs(() => ({}))`
  flex: 3;
  padding: 0 8px;
  overflow: ${props => props.hideText ? 'hidden' : 'auto'};
  text-overflow: ellipsis;
  white-space: ${props => props.hideText ? 'nowrap' : 'normal'};
  @media (max-width: 900px) {
    display: none;
  }
`;
