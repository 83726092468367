import React from 'react';
import styled from 'styled-components';
import businessLogo from '../../../assets/images/cabdo-for-partner-logo-white.png';

const Logo = styled.img.attrs(() => ({
  alt: 'Logo'
}))`
  left: 25px;
  top: 10px;
  position: absolute;
  height: 50px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MicroLogo = props => {
  return (
    <Logo src={businessLogo} />
  );
};

export default MicroLogo;
