import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getCategoryIcon } from '../../../../../constants/carCategories';

const CarIcon = styled.img`
  position: absolute;
  height: ${props => props.zoom > 14 ? 45 : 22.5}px;
  width: ${props => props.zoom > 14 ? 19 : 9.5}px;
  top: -22.5px;
  left: -9.5px;
  transform: rotate(${props => props.heading}deg);
`;

const DriverMarker = props => {
  const { taxiType, heading, currentZoom } = props;
  const imgSrc = getCategoryIcon(taxiType);
  return <CarIcon src={imgSrc} heading={heading} zoom={currentZoom} />;
};

DriverMarker.propTypes = {
  taxiType: PropTypes.string,
  heading: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
  currentZoom: PropTypes.number,
};

export default DriverMarker;
