import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import { SubmitButton } from '../../restaurant-settings/styles';
import * as S from './styles';

const DishEditFooter = props => {
  const { t } = useTranslation();
  const { dataStores: { foodMenuStore } } = useStores();
  const { dishToEdit, editedDish } = foodMenuStore;

  const submitDisabled = useMemo(() => _.isEqual(dishToEdit, editedDish),
    [dishToEdit, editedDish]);

  return (
    <S.FooterContainer>
      <Button
        variant={'outline-dark'}
        onClick={props.onHide}
      >
        {t(langKeys.hide)}
      </Button>
      <SubmitButton
        style={{ margin: 0 }}
        onClick={props.onSubmit}
        disabled={submitDisabled}
      >
        {t(langKeys.createTrip.submit)}
      </SubmitButton>
    </S.FooterContainer>
  );
};

DishEditFooter.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default observer(DishEditFooter);
