import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { getAddressObject } from '../../helpers/utils';
import langKeys from '../../i18n/lang/keys';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyle from './commonStyle';
import DropMenuIcon from '../../assets/icons/dropMenu.svg'
import {
  StyledInputAppendContainer,
  StyledInputContainer, StyledInputInput,
  StyledInputInputContainer, StyledInputLabel,
  StyledInputPrependIcon
} from './styledInput';
import ToolTip from './toolTip';

const PIContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
`;

const StyledDropDownIcon = styled(DropMenuIcon)`
  fill: ${commonStyle.colors.brandPrimary};
`;

const PlacesButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  margin-left: 16px;
`;

const DropdownContainer = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  top: 45px;
  left: -40px;
  width: ${props => props.inputWidth ? props.inputWidth + 80 + (props.hasButton ? 56 : 0) : 300}px;
  background-color: ${commonStyle.colors.white};
  border: 1px solid ${commonStyle.colors.greyMedium2};
  border-radius: 8px;
  z-index: 2;
`;

const Suggestion = styled.div`
  cursor: pointer;
  background-color: ${commonStyle.colors.white};
  color: ${commonStyle.colors.brandPrimary};
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  &:hover {
    background-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.6)};
    color: ${commonStyle.colors.white};
  }

  &:first-child {
    border-top: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

const PlacesButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover > svg {
    fill: ${commonStyle.colors.white};
  }
`;

const StyledPlacesInput = props => {
  const { address, label, prependIcon, isClearable, knownPlaces, isValid } = props;
  const [labelClassName, setLabelClassName] = useState(!address ? '' : 'labeled');
  const [showPlacesDropdown, togglePlacesDropdown] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const { dataStores: { authStore: { placesSearchOptions } }, uiStore: { globalView } } = useStores();

  function hideDropdown() {
    togglePlacesDropdown(false);
  }

  useEffect(() => {
    if (showPlacesDropdown) {
      document.addEventListener('click', hideDropdown, true);
    } else {
      document.removeEventListener('click', hideDropdown, true);
    }
  }, [showPlacesDropdown])

  useEffect(() => {
    setLabelClassName(!address ? '' : 'labeled');
  }, [address]);

  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    } else {
      props.onChange('');
      setLabelClassName('');
    }
  }

  const handleSelect = (selectedAddress) => {
    geocodeByAddress(selectedAddress)
      .then(latLng => {
        const addressObject = getAddressObject(latLng[0]);
        props.onSelect({
          latLng: addressObject.gpsLoc,
          address: addressObject.full_address,
          ...addressObject
        })
      })
      .catch(e => props.onError(e.message));
  }

  const handleBlur = () => {
    if (['', undefined, null].includes(address)) {
      setLabelClassName('');
    }
  }

  if (!globalView.googleApiLoaded) {
    return null;
  }

  return (
    <PIContainer>
      <StyledInputContainer valid={isValid}>
        <StyledInputPrependIcon valid={isValid}>
          {prependIcon}
        </StyledInputPrependIcon>
        <StyledInputInputContainer>
          <StyledInputLabel className={labelClassName} valid={isValid}>
            {label}
          </StyledInputLabel>
          <PlacesAutocomplete
            value={address}
            debounce={400}
            searchOptions={placesSearchOptions}
            onChange={a => props.onChange(a)}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div style={{
                height: '100%',
              }}
              >
                <StyledInputInput
                  valid={isValid}
                  ref={inputRef}
                  {...getInputProps({
                    className: 'location-search-input',
                    onBlur: handleBlur,
                    onFocus: () => setLabelClassName('labeled')
                  })}
                />
                <DropdownContainer
                  show={!!suggestions.length}
                  hasButton={!!knownPlaces?.length}
                  inputWidth={inputRef?.current?.getBoundingClientRect()?.width}
                >
                  {loading && <Suggestion>Loading...</Suggestion>}
                  {suggestions.map(suggestion => {
                    return (
                      <Suggestion
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion)}
                      >
                        <IconContainer>{prependIcon}</IconContainer>
                        <span>
                          {suggestion.description}
                        </span>
                      </Suggestion>
                    );
                  })}
                </DropdownContainer>
              </div>
            )}
          </PlacesAutocomplete>
          {!!knownPlaces?.length && <DropdownContainer
            show={showPlacesDropdown}
            hasButton={true}
            inputWidth={inputRef?.current?.getBoundingClientRect()?.width}
          >
            {knownPlaces.map(addr => <Suggestion
              key={addr}
              onClick={() => handleSelect(addr)}
            >
              <IconContainer>{prependIcon}</IconContainer>
              <span>{addr}</span>
            </Suggestion>)}
          </DropdownContainer>}
        </StyledInputInputContainer>
        <StyledInputAppendContainer clearable={isClearable} onClick={handleClear}>
          {isClearable && !!address && <AiFillCloseCircle/>}
        </StyledInputAppendContainer>
      </StyledInputContainer>
      {!!knownPlaces?.length && <PlacesButtonContainer>
        <ToolTip text={t(langKeys.createTrip.favoriteAddresses)}>
          <PlacesButton
            variant="outline-dark"
            onClick={() => togglePlacesDropdown(!showPlacesDropdown)}
          >
            <StyledDropDownIcon />
          </PlacesButton>
        </ToolTip>
      </PlacesButtonContainer>}
    </PIContainer>
  );
};

StyledPlacesInput.propTypes = {
  address: PropTypes.string,
  location: PropTypes.array,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  prependIcon: PropTypes.element,
  isClearable: PropTypes.bool,
  isValid: PropTypes.bool,
  onClear: PropTypes.func,
  knownPlaces: PropTypes.array,
};

StyledPlacesInput.defaultProps = {
  isValid: true
};

export default observer(StyledPlacesInput);
