import { toJS } from 'mobx';
import moment from 'moment';
import { isCategoryMetered } from '../constants/carCategories';
import { COMPLETED } from '../constants/delivery-order-statuses';
import tripStatuses from '../constants/tripStatuses';
import apiConfig from '../services/apiConfig';

const EARTH_RADIUS = 6371;

const DEST_MARKER_URL = 'http://cdn.mcauto-images-production.sendgrid.net/41101713cc3f1461/a1ab3eb8-889c-4665-b3d3-9d0f98a6fc42/24x24.png';
const CAR_ICON = 'http://cdn.mcauto-images-production.sendgrid.net/41101713cc3f1461/27ba3871-ca96-4302-bb1d-a314c68bd551/24x24.png';

export const isStringEqual = (str) => {
  return (id) => (id ? id.toString() : id) === (str ? str.toString() : str);
};

export const formatPrice = (fare, options = {}) => {
  const { toValue, addEuroSign } = { addEuroSign: true, toValue: 2, ...options };
  if (fare !== 0 && !fare) {
    return 'N/A';
  }
  const fareString = fare.toFixed(toValue).replace('.', ',');
  return `${fareString}${addEuroSign ? '€' : ''}`;
};

export const roundPrice = (cost) => {
  const val = Math.round(cost * 100);
  return (val - (val % 5)) / 100;
};

export const getAddressObject = ({ address_components, formatted_address, geometry: { location: { lat, lng } } }) => {
  const ShouldBeComponent = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country'],
  };

  const latitude = isFunction(lat) ? lat() : lat;
  const longitude = isFunction(lng) ? lng() : lng;

  const address = {
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: '',
    full_address: formatted_address,
    gpsLoc: [latitude, longitude]
  };

  address_components.forEach(component => {
    for (let shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};


function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}

export const calculateManhattanDistance = (srcLoc, destLoc) => {
  const latDist = Math.abs(degreesToRadians(destLoc[0] - srcLoc[0]));
  const longDist = Math.abs(degreesToRadians(destLoc[1] - srcLoc[1]));

  const latA = Math.pow(Math.sin(latDist / 2), 2);
  const latC = 2 * Math.atan2(Math.sqrt(latA), Math.sqrt(1 - latA));
  const latGeoDist = EARTH_RADIUS * latC;

  const lngA = Math.pow(Math.sin(longDist / 2), 2);
  const lngC = 2 * Math.atan2(Math.sqrt(lngA), Math.sqrt(1 - lngA));
  const lngGeoDist = EARTH_RADIUS * lngC;

  return Math.abs(latGeoDist) + Math.abs(lngGeoDist);
};

export const formatAddressStringFromAddressObject = (addressObj, isFull = true) => {
  const hasObjData = !!addressObj.home && !!addressObj.street;
  const hasFullObjData = !!addressObj.home && !!addressObj.street && !!addressObj.city;
  if (!hasObjData && !isFull) {
    const placeName = addressObj.fullAddress.split(',')[0];
    return `${placeName}, ${addressObj.city}`;
  }
  if (!hasFullObjData && isFull) {
    return addressObj.fullAddress;
  }
  if (!isFull) {
    return `${addressObj.home}, ${addressObj.street}`;
  } else {
    return `${addressObj.home}, ${addressObj.street}${
      addressObj.postal_code ? `, ${addressObj.postal_code}` : ''
    }, ${addressObj.city}`;
  }
};

export const calculateManhattanArrivalTime = (src, dest) => {
  if (!src || !dest || !src.length || !dest.length || src.some(c => !c) || dest.some(c => !c)) return null;
  const distance = calculateManhattanDistance(src, dest);
  const currentTime = new Date().getHours();

  const dayBeginTime = 7;
  const dayEndTime = 20;
  const dayAverageSpeed = 36;
  const nightAverageSpeed = 57;
  const averageSpeed = currentTime >= dayBeginTime && currentTime <= dayEndTime
    ? dayAverageSpeed
    : nightAverageSpeed;
  return Math.round((distance / averageSpeed) * 60);
};

export const calculateArrivalTime = (driver, toLoc) => {
  let startLoc = [driver.gpsLoc[1], driver.gpsLoc[0]];
  const tripSrc = [toLoc[1], toLoc[0]];
  let additionalTime = 0;
  if (driver.currTripId && driver.currTripId.destLoc) {
    additionalTime = calculateManhattanArrivalTime(startLoc, driver.currTripId.destLoc);
    startLoc = driver.currTripId.destLoc;
  }
  return calculateManhattanArrivalTime(startLoc, tripSrc) + additionalTime;
}

export const isPBDateValid = (preBookedTime) => {
  return moment(preBookedTime).isBetween(moment().add(10, 'm'), moment().add(31, 'days')) && !isNewYearEve(preBookedTime);
}

export const isNewYearEve = (date) => {
  if (!date) return false;
  const thisNY = moment().month(0).date(1).hours(10).minutes(0).second(0);
  const isThisNYEve = moment().isBefore(thisNY);
  const year = moment().year() - (isThisNYEve ? 1 : 0);
  const nyStart = moment(`31.12.${year} 22:00`, 'DD.MM.YYYY HH:mm');
  const nyEnd = moment(`01.01.${year + 1} 10:00`, 'DD.MM.YYYY HH:mm');
  return moment(date).isBetween(nyStart, nyEnd);
};

export const formatAddressString = address => {
  if (!address) return address;
  const trimmedLine = address.match(/^[^,]*,[^,]*/);
  if (!trimmedLine || !trimmedLine.length) return address;
  return trimmedLine.join();
}

export const roundUpDateTo5Minutes = () => {
  const now = new Date();
  let minutes = now.getMinutes();
  const temp = minutes % 5;
  temp && (minutes = minutes - temp + 5);
  now.setMinutes(minutes);
  return now;
};

export const getDriversPhone = (driver, taxiType) => {
  if (!driver || !taxiType) return null;
  if (isCategoryMetered(taxiType)) {
    return driver.phoneNo;
  }
  return driver.carDetails && driver.carDetails.phoneNo
    ? driver.carDetails.phoneNo
    : '+49231588008800';
};

export const getDriverAndTripDataFromOrder = (order) => {
  if (!order.orderTripId) return null;
  const trip = order.orderTripId;
  const driver = toJS(order.orderTripId?.driverId);
  const carDetails = driver?.carDetails;
  const loc = driver?.gpsLoc?.length ? driver.gpsLoc.reverse() : '';
  return {
    carDetails,
    showTrip: [tripStatuses.arriving, tripStatuses.arrived, tripStatuses.enRoute].includes(trip.tripStatus),
    tripStatus: trip.tripStatus,
    driverName: `${driver?.fname} ${driver?.lname}`,
    driverPhoneNo: getDriversPhone(driver, trip.taxiType),
    car: `${carDetails?.licensePlateNumber} - ${carDetails?.make} ${carDetails?.model}`,
    location: loc
  };
};

export const formatDayTimeValueToString = (val) => {
  const decimalHours = Math.floor(val);
  const decimalMinutes = val % 1;
  const hours = decimalHours >= 10 ? decimalHours : `0${decimalHours}`;
  const minutes = decimalMinutes ? `${Math.floor(60 * decimalMinutes)}` : '00';
  return `${hours}:${minutes}`;
};

export const formatDayTimeValueToDecimal = (val) => {
  const [strHours, strMinutes] = val.split(':');
  const minutesVal = (parseInt(strMinutes) / 60).toFixed(2);
  return parseInt(strHours, 10) + parseFloat(minutesVal);
};

export const getDeliveryOrderDateString = (date, orderStatus, isSelfPickup, hasOwnDelivery = false) => {
  if (!date) return '';
  if (orderStatus === COMPLETED) {
    const currentYear = moment().format('YYYY');
    const orderYear = moment(date).format('YYYY');
    const isYearEqual = currentYear === orderYear;
    return moment(date).locale('de').format(isYearEqual ? 'DD MMM [um] HH:mm' : 'DD.MM.YY [um] HH:mm');
  }
  const orderDate = moment(date).format('DD.MM');
  const currentDate = moment().format('DD.MM');
  const isThisDay = orderDate === currentDate;
  if (hasOwnDelivery && !isSelfPickup) {
    return `Liefern am ${moment(date).locale('de').format(isThisDay ? 'HH:mm' : 'DD MMM [um] HH:mm')}!`;
  }
  return `Wird ${moment(date).subtract(isSelfPickup ? 0 : 5, 'minutes').locale('de').format(isThisDay ? '[um] HH:mm' : 'DD MMM [um] HH:mm')} abgeholt!`;
}


export const createMapImage = ({
   destination = null,
   carPosition = [],
   polyline = '',
   height = 300,
   width = 600,
   scale = 2
 }) => {
  const destMarker = destination ? `&markers=anchor:center|icon:${DEST_MARKER_URL}|${destination.join(',')}` : '';
  const car = carPosition ? `&markers=anchor:center|icon:${CAR_ICON}|${carPosition.join(',')}` : '';
  const path = polyline ? `&path=color:0x000000ff|weight:3|enc:${polyline}` : '';
  const size = `${width}x${height}`;
  return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&language=de&scale=${scale}${destMarker}${car}${path}&key=${apiConfig.googleMapsApiKey}`;
};
