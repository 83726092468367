import CabdoBlackLabel from '../assets/images/cars/labels/CabdoBlack.png';
import CabdoGreenLabel from '../assets/images/cars/labels/CabdoGreen.png';
import CabdoXLLabel from '../assets/images/cars/labels/CabdoXL.png';
import TaxiLabel from '../assets/images/cars/labels/Taxi.png';
import TaxiXLLabel from '../assets/images/cars/labels/TaxiXL.png';
import CabdoBlackIcon from '../assets/images/cars/mapIcons/CabdoBlack.png';
import CabdoGreenIcon from '../assets/images/cars/mapIcons/CabdoGreen.png';
import TaxiIcon from '../assets/images/cars/mapIcons/Taxi.png';
import TaxiXLIcon from '../assets/images/cars/mapIcons/TaxiXL.png';
import commonStyle from '../components/custom-ui/commonStyle';

const carCategoryTypes = {
  CabdoBlack: 'CabdoBlack',
  CabdoGreen: 'CabdoGreen',
  CabdoXL: 'CabdoXL',
  Taxi: 'Taxi',
  TaxiXL: 'TaxiXL',
  metered: 'metered',
  unmetered: 'unmetered'
};

export const getCategoryImage = catName => {
  switch (catName) {
    case carCategoryTypes.Taxi:
      return TaxiLabel;
    case carCategoryTypes.TaxiXL:
      return TaxiXLLabel;
    case carCategoryTypes.CabdoGreen:
      return CabdoGreenLabel;
    case carCategoryTypes.CabdoXL:
      return CabdoXLLabel;
    default:
      return CabdoBlackLabel;
  }
};

export const getCategoryIcon = catName => {
  switch (catName) {
    case carCategoryTypes.Taxi:
      return TaxiIcon;
    case carCategoryTypes.TaxiXL:
      return TaxiXLIcon;
    case carCategoryTypes.CabdoGreen:
      return CabdoGreenIcon;
    case carCategoryTypes.CabdoXL:
    default:
      return CabdoBlackIcon;
  }
};

export const defineCategoryColor = catName => {
  switch (catName) {
    case carCategoryTypes.Taxi:
    case carCategoryTypes.TaxiXL:
      return commonStyle.colors.brandFood;
    case carCategoryTypes.CabdoGreen:
      return commonStyle.colors.brandEco;
    case carCategoryTypes.CabdoXL:
    default:
      return commonStyle.colors.brandPrimary;
  }
};

export const isCategoryMetered = (catName) => [carCategoryTypes.Taxi, carCategoryTypes.TaxiXL].includes(catName);


export default carCategoryTypes;
