import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import FullContentSpinner from '../custom-ui/contentSpinner';
import Layout from './Layout';

/**
 * HOC for defining Private routes
 * if user is not authenticated he is redirecting to signin page
 * if user authenticated he is successfully passed to passed component
 */

const PrivateRoute = (props) => {
  const { authed, isAuthStoreSynced, ...rest } = props;

  if (isAuthStoreSynced && !authed) {
    return <Redirect to={{
      pathname: '/login',
      state: { from: props.location }
    }}
    />;
  }

  return <Layout>
    {isAuthStoreSynced ? <Route exact {...rest} /> : <FullContentSpinner/>}
  </Layout>;
}

export default PrivateRoute;
