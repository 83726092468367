import Color from 'color';

const commonStyle = {
  colors: {
    alphaString(hex, alpha) {
      return Color(hex).alpha(alpha).string();
    },
    lighten(hex, koef) {
      return Color(hex).lighten(koef).string();
    },
    darken(hex, koef) {
      return Color(hex).darken(koef).string();
    },
    brandFood: '#c3a144',
    brandFoodAccent: '#F1AF00',
    brandMed: '#00B4BD',
    brandEco: '#28a737',
    brandPrimary: '#001D23',
    facebookColor: '#3b5998',
    instagramColor: '#fd1d1d',
    brandInfo: '#62B1F6',
    brandSuccess: '#15A066',
    brandDanger: '#FD1D1D',
    brandWarning: '#f0ad4e',
    greyLight: '#FAFAFA',
    greyLight2: '#D7D7DB',
    greyMedium: '#98999C',
    greyMedium2: '#B5B5B5',
    greyDark: '#2b2a29',
    greyDark2: '#2b2b2b',
    inactive: '#e0e0e0',
    disabled: '#b5b5b5',
    checkboxInfoColor: '#3C3C3A',
    background: '#f3f3f3',
    panelBackground: '#fefefe',
    white: '#FFFFFF'
  },
};

export default commonStyle;
