import { toast } from 'react-toastify';

export const NotificationTypes = {
  white: toast.TYPE.DEFAULT,
  default: toast.TYPE.DARK,
  info: toast.TYPE.INFO,
  error: toast.TYPE.ERROR,
  success: toast.TYPE.SUCCESS,
};

const notify = (message, type = null, props = {}) => {
  toast(message, {
    type: type || NotificationTypes.default,
    style: {
      opacity: 0.95
    },
    ...props
  });
};

export default notify;
