import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createMapImage } from '../../../../../helpers/utils';
import tripStatuses from '../../../../../constants/tripStatuses';
import langKeys from '../../../../../i18n/lang/keys';
import * as S from './styles';

const MapSection = props => {
  const { mapData, order } = props;
  const { t } = useTranslation();
  const mapUrl = useMemo(() => {
    if (!mapData) return null;
    return createMapImage({
      carPosition: mapData.startLoc,
      destination: mapData.endLoc,
      polyline: mapData.polyline,
      width: 440,
      height: 250
    });
  }, [mapData]);

  const isDelivered = useMemo(() => !mapData && [tripStatuses.enRoute, tripStatuses.feedback].includes(order.orderTripId?.tripStatus), [mapData, order]);

  if (!mapData && !isDelivered) return null;

  return (
    <S.MapRow>
      <S.MapBlock>
        {!isDelivered && <S.MapHeaderRow>{`${t(langKeys.createTrip.tripRequestMessages.arriveIn)}: ~${mapData.duration} min`}</S.MapHeaderRow>}
        {isDelivered && <S.MapHeaderRow>{`${t(langKeys.foodOrders.delivered)}`}</S.MapHeaderRow>}
        <S.MapContainer>
          {!!mapUrl && <S.MapImage src={mapUrl} alt={'Driver Map'}/>}
        </S.MapContainer>
      </S.MapBlock>
    </S.MapRow>
  );
};

MapSection.propTypes = {
  mapData: PropTypes.shape({
    distance: PropTypes.number,
    duration: PropTypes.number,
    polyline: PropTypes.string,
    startLoc: PropTypes.array,
    endLoc: PropTypes.array,
  }),
  order: PropTypes.object,
};

export default MapSection;
