import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineLogout } from 'react-icons/ai';
import styled from 'styled-components';
import langKeys from '../../../i18n/lang/keys';
import commonStyle from '../../custom-ui/commonStyle';

const LogoutBtn = styled.button`
  position: absolute;
  right: 25px;
  height: 35px;
  top: 17.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 12px;
  border: none;
  background-color: transparent;
  color: ${commonStyle.colors.white};
  &:hover {
    color: ${commonStyle.colors.alphaString(commonStyle.colors.white, 0.7)};
  }
  @media (max-width: 600px) {
    border: 1px solid ${commonStyle.colors.background};
    border-radius: 8px;
    &:hover {
      color: ${commonStyle.colors.brandPrimary};
      background-color: ${commonStyle.colors.background};
    }
  }
`;

const LogoutText = styled.span`
  padding-top: ${navigator.platform.includes('Linux') ? 0 : 3}px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const LogOutIcon = styled(AiOutlineLogout)`
  margin-left: 5px;
  display: none;
  @media (max-width: 600px) {
    margin-left: 0;
    display: block;
  }
`;

const LogoutButton = props => {
  const { t } = useTranslation();
  return (
    <LogoutBtn onClick={props.onLogout}>
      <LogoutText>{t(langKeys.login.logout)}</LogoutText>
      <LogOutIcon />
    </LogoutBtn>
  );
};

LogoutButton.propTypes = {
  onLogout: PropTypes.func,
};

export default LogoutButton;
