import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoReturnUpBackOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../../../assets/images/cabdo_logo.png';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import AlertPanel from '../../../custom-ui/alertPanel';
import commonStyle from '../../../custom-ui/commonStyle';
import FullContentSpinner from '../../../custom-ui/contentSpinner';
import { UnAuthCenteredContainer, UnAuthLayoutWrapper } from '../../../custom-ui/un-auth-style';
import { ExtrasContainer, Header, Logo } from '../login';

const Error = styled.p`
  font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin-top: 8px;
  color: ${commonStyle.colors.brandDanger};
`;

const Icon = styled(IoReturnUpBackOutline)`
  margin-right: 5px;
  padding-bottom: 5px;
  font-size: 20px;
`;

const validateEmail = (val) =>
  val.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

const PasswordRecovery = () => {
  const { t } = useTranslation();
  useTitle(t(langKeys.login.forgotPass));

  const {
    dataStores: {
      authStore
    }
  } = useStores();
  const { authError, fetchMessage } = authStore;

  const [email, setEmail] = useState('');
  const [isValidated, setValidated] = useState(true);
  const emailInput = useRef();

  useEffect(() => {
    emailInput?.current?.focus();
    return () => {
      authStore.unSetLoading();
    }
  }, [authStore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateEmail(email);
    setValidated(!!valid);
    if (valid) {
      authStore.sendRecoveryMail(email);
    }
  };

  return (
    <UnAuthLayoutWrapper>
      <UnAuthCenteredContainer>
        <Logo src={logo} alt={'CABDO Logo'}/>
        <Header>{t(langKeys.passRestore.title)}</Header>
        <AlertPanel isSuccess={!authError} message={fetchMessage} />
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl
              ref={emailInput}
              value={email}
              placeholder={t(langKeys.login.email)}
              onChange={e => setEmail(e.target.value)}
            />
            {!isValidated && <Error>
              {t(langKeys.passRestore.errorEmailInvalid)}
            </Error>}
          </FormGroup>
          <Button
            block
            type={'submit'}
            variant={'dark'}
            disabled={!email}
          >
            {t(langKeys.passRestore.sendMail)}
          </Button>
        </Form>
        <ExtrasContainer>
          <Link to={'/login'}><Icon/>{t(langKeys.back)}</Link>
        </ExtrasContainer>
      </UnAuthCenteredContainer>
      <FullContentSpinner />
    </UnAuthLayoutWrapper>
  );
};

export default observer(PasswordRecovery);
