import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AiFillCloseCircle } from 'react-icons/ai';
import styled from 'styled-components';
import commonStyle from './commonStyle';

const defineColorByType = messageType => {
  switch(messageType) {
    case 'warning':
      return commonStyle.colors.brandWarning;
    case 'success':
      return commonStyle.colors.brandSuccess;
    default:
      return commonStyle.colors.brandDanger;
  }
}

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin: 8px 0 16px;
  border-bottom: 1px solid ${props => props.valid ? commonStyle.colors.greyMedium2 : commonStyle.colors.brandDanger};
`;

export const StyledInputPrependIcon = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.valid ? commonStyle.colors.brandPrimary : commonStyle.colors.brandDanger};
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  background-color: transparent;
  font-size: 18px;
`;

export const StyledInputInputContainer = styled.div`
  flex: 8;
  height: 100%;
  position: relative;
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
`;

export const StyledInputLabel = styled.label`
  position: absolute;
  pointer-events: none;
  bottom: -2px;
  max-width: 100%;
  left: 10px;
  color: ${props => props.valid ? commonStyle.colors.greyMedium : commonStyle.colors.brandDanger};
  transition: 0.2s;
  padding: 1px 5px;
  background-color: ${commonStyle.colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
   &.labeled {
    bottom: 22px;
    font-size: 0.8em;
  }
`;

export const StyledInputInput = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  color: ${props => props.valid ? commonStyle.colors.brandPrimary : commonStyle.colors.brandDanger};
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  padding-left: 15px;
  padding-top: 8px;
  outline: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:focus,
  &:focus-visible,
  &:active {
    border: none;
  }

  &:disabled {
    color: ${commonStyle.colors.greyMedium};
    background-color: transparent;
  }
`;

export const StyledInputAppendContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: ${props => props.clearable ? 'pointer' : 'default'};
  color: ${commonStyle.colors.brandDanger};
  &:hover {
    color: ${commonStyle.colors.greyMedium};
  }
`;

export const StyledFeedback = styled.div`
  margin: -8px 0 16px;
  color: ${props => defineColorByType(props.messageType)};
  font-size: 14px;
  font-weight: 700;
`;

const StyledInput = props => {
  const { value, label, inputProps, prependIcon, isClearable, resetValue, autoFocus, disabled, isValid } = props;
  const [labelClassName, setLabelClassName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && !disabled) {
      inputRef.current.focus();
    }
  }, [autoFocus, disabled]);

  useEffect(() => {
    const isEmpty = [null, '', undefined, 0].includes(value);
    setLabelClassName(isEmpty ? '' : 'labeled');
  }, [value]);

  const handleClear = () => {
    props.onChange(resetValue);
  }

  const handleBlur = () => {
    if (['', undefined, null, 0].includes(value)) {
      setLabelClassName('');
    }
  };

  return (
    <Fragment>
      <StyledInputContainer valid={isValid}>
        <StyledInputPrependIcon disabled={disabled} valid={isValid}>
          {prependIcon}
        </StyledInputPrependIcon>
        <StyledInputInputContainer>
          <StyledInputLabel className={labelClassName} disabled={disabled} valid={isValid}>{label}</StyledInputLabel>
          <StyledInputInput
            ref={inputRef}
            value={value}
            onChange={e => props.onChange(e.target.value)}
            onFocus={() => setLabelClassName('labeled')}
            onBlur={handleBlur}
            valid={isValid}
            disabled={!!disabled}
            {...inputProps}
          />
        </StyledInputInputContainer>
        <StyledInputAppendContainer clearable={isClearable} disabled={disabled} onClick={handleClear}>
          {isClearable && !!value && <AiFillCloseCircle size={15}/>}
        </StyledInputAppendContainer>
      </StyledInputContainer>
      {!isValid && <StyledFeedback messageType={'error'}>{props.errorMessage}</StyledFeedback>}
    </Fragment>
  );
};

StyledInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  prependIcon: PropTypes.element,
  isClearable: PropTypes.bool,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  resetValue: PropTypes.any,
  autoFocus: PropTypes.bool,
};

StyledInput.defaultProps = {
  inputProps: {},
  isClearable: false,
  resetValue: '',
  errorMessage: '',
  autoFocus: false,
  isValid: true
};

export default StyledInput;
