import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import * as S from '../styles';

const CorporatePreviewCard = props => {
  const { dataStores: { accountSettings } } = useStores();
  const { currentUserData } = accountSettings;
  const { t } = useTranslation();

  return (
    <S.PreviewContainer>
      <FormLabel>{t(langKeys.settings.preview)}:</FormLabel>
      {!!currentUserData.logo && <S.CorpImg src={currentUserData.logo} alt={'Corporate Logo'}/>}
      {!!currentUserData.logo && <S.RemoveLogoContainer
        onClick={() => accountSettings.changeCurrentData({ logo: null })}
      >
        <BsTrash />
      </S.RemoveLogoContainer>}
    </S.PreviewContainer>
  );
};

export default observer(CorporatePreviewCard);
