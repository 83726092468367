import React from 'react';
import io from 'socket.io-client';
import { playDing } from '../components/elements/dingSound';
import { formatPrice } from '../helpers/utils';
import langKeys from '../i18n/lang/keys';
import apiConfig from './apiConfig';
import notify, { NotificationTypes } from './notifier';
import i18n from '../i18n';

export const initSocket = (store = null) => {
  if (!store?.authStore?.token) return;
  const socket = io(apiConfig.serverUrl, {
    jsonp: false,
    transports: ['websocket'],
    query: `token=${store.authStore.token}`
  });

  socket.on('new-delivery-order', order => {
    playDing();
    if (store?.foodOrdersStore) {
      store.foodOrdersStore.getOrders();
    }
    notify(
      i18n.t(
        langKeys.foodOrders.newOrder,
        {
          orderNo: order?.orderNo,
          deliveryType: i18n.t(langKeys.foodOrders[order?.isSelfPickup ? 'pickup' : 'delivery']),
          price: formatPrice(order?.priceData?.orderItemsFare),
          dishesNum: order?.itemsQty
        }),
      NotificationTypes.info
    );
  });

  socket.on('noNearByDriver', () => {
    notify(i18n.t(langKeys.createTrip.tripRequestMessages.noDrivers), NotificationTypes.error);
  });

  socket.on('tripAccepted', (payload) => {
    const message = <div>
      <div><strong>{i18n.t(langKeys.createTrip.tripRequestMessages.auto)}</strong> {payload.car}</div>
      <div><strong>{i18n.t(langKeys.createTrip.tripRequestMessages.plateNumber)}</strong> {payload.licensePlateNumber}</div>
      <div><strong>{i18n.t(langKeys.createTrip.tripRequestMessages.driver)}</strong> {payload.driver}</div>
      <div><strong>{i18n.t(langKeys.createTrip.phoneNo)}</strong> {payload.driverPhoneNo}</div>
      <div><strong>{i18n.t(langKeys.createTrip.tripRequestMessages.arriveIn)}</strong> in {payload.eta + 2} Minuten</div>
      {payload.tripStatus === 'tripOnTrip' &&
      <div><strong>{i18n.t(langKeys.createTrip.tripRequestMessages.tripOnTrip)}!!!</strong></div>}
    </div>
    notify(message, NotificationTypes.success);
  });

  socket.on('preBookingAccepted', () => {
    notify(i18n.t(langKeys.createTrip.tripRequestMessages.bookingSuccess), NotificationTypes.success);
  })

  socket.on('forceLogout', () => {
    if (store?.authStore) {
      store.authStore.signOut();
    }
  });
};
