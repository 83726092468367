import { action, observable, makeAutoObservable, computed, runInAction } from 'mobx';
import { clearPersist, isSynchronized, startPersist, StorageAdapter } from 'mobx-persist-store';
import { readStore, writeStore } from '../dataStores/auth';

export const globalViewPersistOptions = {
  name: 'GlobalView',
  properties: [
    'sidebarWidth',
    'defaultRedirectRoute',
    'redirectRoute',
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 200,
  }
};

export default class GlobalView {
  @observable defaultRedirectRoute = '/portal/create-trip';
  @observable redirectRoute = null;
  @observable googleApiLoaded = false;
  @observable isGlobalLoading = false;
  @observable isSideBarHidden = true;
  @observable sidebarWidth = 200;
  @observable pageTitle = '';

  @observable windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    window.onresize = () => {
      runInAction(() => {
        this.windowDimensions = this.getWindowDimensions();
      });
    }
    this.startPersist();
  }

  @action setPageTitle = (title) => {
    this.pageTitle = title;
  }

  @action setRedirectPath = (path) => {
    this.redirectRoute = path;
  }

  @computed getWindowDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  @action setGoogleLibraryLoaded() {
    this.googleApiLoaded = true;
  }

  @action setLoading = () => {
    this.isGlobalLoading = true;
  }

  @action setLoaded = () => {
    this.isGlobalLoading = false;
  }

  @action toggleSideBar(toValue = undefined) {
    if (typeof toValue === 'boolean') {
      this.isSideBarHidden = toValue;
    } else {
      this.isSideBarHidden = !this.isSideBarHidden;
    }
  }

  @action setRedirectRoute = (route) => {
    this.redirectRoute = route;
  }

  @action expandSideBar() {
    this.sidebarWidth = 200;
  }

  @action collapseSideBar() {
    this.sidebarWidth = 50;
  }

  @action updateView(view) {
    this.currentView = view;
  }

  startPersist = async () => {
    startPersist(this)
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
}
