const downloadBinary = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.style = 'display: none';
  a.download = filename;
  a.href = url;

  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  });
};

export default downloadBinary;
