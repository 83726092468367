import { callAPI } from './apiService';

export const fetchMyRides = (token, params) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/trip?pageNo=${params.pageNo || 1}&limit=${params.limit || 9}&status=${params.status}`,
  });
};

export const cancelPreBooking = (token, tripId) => {
  return callAPI({
    method: 'DELETE',
    authentication: token,
    endpoint: `api/corporate/trip/${tripId}`,
  });
}

export const updatePreBooking = (token, tripId, tripData) => {
  return callAPI({
    method: 'PUT',
    authentication: token,
    body: { ...tripData },
    endpoint: `api/corporate/trip/${tripId}`,
  });
}

export const exportTrips = (token, rangeData) => {
  return callAPI({
    responseType: 'blob',
    method: 'POST',
    authentication: token,
    body: rangeData,
    endpoint: 'api/corporate/trip/exportCompleted',
  });
}
