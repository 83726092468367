import { useEffect } from 'react';
import { useStores } from '../stores/helpers/use-stores';

export const useTitle = (title) => {
  const {
          uiStore: {
            globalView
          }
        } = useStores();

  useEffect(() => {
    document.title = title;
    globalView.setPageTitle(title);
    // eslint-disable-next-line
  }, [title]);
};
