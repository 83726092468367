import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import OrderDetailsDrawerBody from './body';
import OrderDetailsDrawerFooter from './footer';

const OrderFullDetails = props => {
  const { orderId } = props;
  const { dataStores: { foodOrdersDetails, authStore }, uiStore: { drawerStore } } = useStores();
  const { t } = useTranslation();

  const { orderDetails } = foodOrdersDetails;
  const { userData } = authStore;

  useEffect(() => {
    if (orderId) {
      foodOrdersDetails.getFoodOrderDetails(orderId);
    } else {
      foodOrdersDetails.clearOrderDetails();
    }
    // eslint-disable-next-line
  }, [orderId]);

  useEffect(() => {
    if (!drawerStore.isOpened) {
      props.onHide();
    }
    // eslint-disable-next-line
  }, [drawerStore.isOpened]);

  const handleOrderComplete = () => {
    foodOrdersDetails.setOrderAsCompleted(orderId);
  }

  const title = useMemo(() => `${t(langKeys.foodOrders.order)} #${orderDetails?.orderNo}`, [orderDetails, t]);

  const body = useMemo(() => {
    return <OrderDetailsDrawerBody
      orderDetails={orderDetails}
      showMenuNumber={userData?.restaurantData?.showMessageMenuNumber}
    />;
  }, [orderDetails, userData]);

  const footer = useMemo(() => {
    return <OrderDetailsDrawerFooter
      hasOwnDelivery={userData?.restaurantData?.hasOwnDelivery}
      orderDetails={orderDetails}
      onHidePress={props.onHide}
      onOrderCompleted={handleOrderComplete}
    />;
    // eslint-disable-next-line
  }, [orderDetails, props.onHide]);

  useEffect(() => {
    if (orderDetails) {
      drawerStore.showDrawer({
        title,
        body,
        footer
      });
    } else {
      drawerStore.closeDrawer();
    }
    // eslint-disable-next-line
  }, [orderDetails, title, body, footer]);

  return null;
};

OrderFullDetails.propTypes = {
  orderId: PropTypes.string,
  onHide: PropTypes.func,
};

export default observer(OrderFullDetails);
