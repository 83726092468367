import { persistence } from 'mobx-persist-store';
import DrawerStore from './drawerStore';
import GlobalView, { globalViewPersistOptions } from './globalView';

export default class UiStore {
  globalView;
  drawerStore;

  constructor(rootStore) {
    this.globalView = persistence(globalViewPersistOptions)(new GlobalView(rootStore));
    this.drawerStore = new DrawerStore();
  }
}
