import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import commonStyle from './commonStyle';

const StyledToolTip = styled(Tooltip)`
  font-family: Circe, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  padding-bottom: 8px;
  .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.7)};
  }
  .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.7)};
  }
  .tooltip-inner {
    background-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.7)};
  }
`;

const ToolTip = props => {
  const { text, placement, delay } = props;

  const renderTooltip = (prps) => (
    <StyledToolTip {...prps}>
      {text}
    </StyledToolTip>
  );

  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={renderTooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string,
  placement: PropTypes.string,
  delay: PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number,
  }),
  tooltipProps: PropTypes.object,
};

ToolTip.defaultProps = {
  placement: 'bottom',
  delay: { show: 200, hide: 400 },
  tooltipProps: {}
}

export default ToolTip;
