import { observable, action, makeAutoObservable, computed, runInAction } from 'mobx';
import { StorageAdapter, clearPersist, startPersist, isSynchronized } from 'mobx-persist-store';
import Cookies from 'js-cookie';
import userTypes from '../../../constants/userTypes';
import * as UserService from '../../../services/userServices';

export const readStore = (name) => {
  return new Promise((resolve) => {
    const data = localStorage.getItem(name);
    resolve(data);
  });
};

export const writeStore = (name, content) => {
  return new Promise((resolve) => {
    localStorage.setItem(name, content);
    resolve();
  });
};

export const authPersistOptions = {
  name: 'Auth',
  properties: [
    'token',
    'userData',
    'userPickups',
    'isRestaurant',
    'lang',
    'isLoggedIn',
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 0,
  }
};

export default class AuthStore {
  @observable token = '';
  @observable userData = null;
  @observable isRestaurant = false;
  @observable userPickups = [];
  @observable lang = 'de';
  @observable isLoggedIn = false;
  @observable authError = false;
  @observable fetchMessage = null;
  @observable placesSearchOptions = {
    location: {
      lat: () => 51.5017,
      lng: () => 7.4858
    },
    radius: 800,
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.startPersist();
    this.rootStore = rootStore;
  }

  @computed get getAuthToken() {
    return this.token;
  }

  @action setToken(token) {
    this.token = token;
    this.isLoggedIn = true;
    Cookies.set('id_token_portal', token, { secure: true, expires: 30 });
  }

  @action setLoading() {
    if (this.rootStore) {
      this.rootStore.uiStore.globalView.isGlobalLoading = true;
    }
    this.fetchMessage = null;
    this.authError = false;
  }

  @action unSetLoading(isError = false, message = null) {
    if (this.rootStore) {
      this.rootStore.uiStore.globalView.isGlobalLoading = false;
    }
    this.fetchMessage = message;
    this.authError = isError;
  }

  @action login = async ({ email, password }) => {
    this.setLoading();
    const resp = await UserService.loginUser({ email, password });
    this.handleUserData(resp);
  }

  @action setupUser = async () => {
    const token = Cookies.get('id_token_portal');
    if (!token) {
      return this.signOut();
    }
    this.setLoading();
    const resp = await UserService.setupUser(token);
    if (resp.success) {
      this.handleUserData(resp);
    } else {
      this.signOut();
      this.unSetLoading();
    }
  }

  @action handleUserData(resp) {
    if (resp.success && resp.data?.user) {
      const { jwtAccessToken, user } = resp.data;
      if (user.userType !== userTypes.corporate) {
        this.unSetLoading(true, 'Only corporate users may sign in!');
      } else {
        this.setToken(jwtAccessToken);
        this.updateLanguage(user.language);
        this.setUserData(user);
        this.setUserPickups(user);
        this.setPlacesSearchOptions(user);
        this.unSetLoading();
      }
      if (!!user.restaurantData) {
        this.rootStore.uiStore.globalView.setRedirectRoute('/food/orders');
      }
    } else {
      this.unSetLoading(true, resp?.message || 'Failed to login');
    }
  }

  @action setUserPickups = (user) => {
    this.userPickups = [user.fullAddress, ...user.pickups];
  }

  @action setUserData(data) {
    this.userData = data;
    this.isRestaurant = !!data?.restaurantData;
  }

  @action setPlacesSearchOptions(user) {
    this.placesSearchOptions = {
      location: {
        lat: () => user.gpsLoc[0],
        lng: () => user.gpsLoc[1]
      },
      radius: 800,
    }
  }

  @action sendRecoveryMail = async (email) => {
    this.setLoading();
    const resp = await UserService.passRecovery({ email });
    const success = resp && resp.success;
    const message = success ? 'Neues Passwort an Ihre E-Mail gesendet' : 'Benutzer mit der angegebenen E-Mail wurde nicht gefunden';
    this.unSetLoading(!success, message);
  }

  @action
  async updateLanguage(lang = 'de') {
    this.lang = lang;
    // update lang for user on service
  }

  @action signOut = async () => {
    if (this.token) {
      await UserService.logoutUser(this.token);
    }
    runInAction(() => {
      this.userData = null;
      this.isRestaurant = false;
      this.isLoggedIn = false;
      this.userPickups = [];
      this.token = null;
      if (this.rootStore) {
        this.rootStore.uiStore.globalView.setRedirectRoute(null);
      }
    });
    Cookies.remove('id_token_portal', { secure: true });
    localStorage.clear();
  }

  startPersist = async () => {
    startPersist(this);
    this.setupUser();
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
};
