import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import makeInspectable from 'mobx-devtools-mst';
import { ToastContainer } from 'react-toastify';
import App from './App';
import DingSound from './components/elements/dingSound';
import reportWebVitals from './reportWebVitals';
import { createStore } from './stores/helpers/create-store';
import { StoreProvider } from './stores/helpers/store-context';

import './i18n';
import './assets/fonts/circe-regular-webfont.ttf';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const browserHistory = createBrowserHistory();
const rootStore = createStore();
const history = syncHistoryWithStore(browserHistory, rootStore.routeStore);
makeInspectable(rootStore);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore} routing={rootStore.routeStore}>
      <Router history={history}>
        <DingSound />
        <App/>
        <ToastContainer/>
      </Router>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
