import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import commonStyle from '../../../../custom-ui/commonStyle';

export const ContentContainer = styled.div`
  padding: 16px 20px 8px;
  @media (max-width: 648px) {
    padding: 16px 0 8px;
  }
`;

export const SortExportContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  height: 55px;
  display: none;
  margin-top: -16px;
  @media (max-width: 1500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PaginationContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  margin-top: 10px;
`;

export const NoOrders = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 24px;
`;

export const OrdersContainer = styled.div.attrs(() => ({}))`
  width: 100%;
`;

export const OrderContainer = styled.div.attrs(() => ({}))`
  width: 90%;
  max-width: 410px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 16px;
`;

export const OrderHeaderRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
`;

export const NewOrderBadge = styled.div.attrs(() => ({}))`
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderNo = styled.div.attrs(() => ({}))`
  font-size: 14px;
  font-weight: 700;
  color: ${commonStyle.colors.brandPrimary};
`;

export const DeliveryType = styled.div.attrs(() => ({}))`
  height: 30px;
  width: 100px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  justify-content: center;
  background-color: ${props => props.isDelivery ? commonStyle.colors.brandFood : commonStyle.colors.greyMedium};
  color: ${commonStyle.colors.white};
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.1)};
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  @media (max-width: 370px) {
    margin-bottom: 8px;
  }
`;

export const DeliveryDate = styled.div.attrs(() => ({}))`
  font-size: 14px;
  margin: 0 8px;
  color: ${commonStyle.colors.brandPrimary};
`;

export const MoreButton = styled(Button).attrs(() => ({
  variant: 'outline-dark',
  size: 'sm'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

export const OrderDataContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const DriverSeparator = styled.div.attrs(() => ({}))`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${commonStyle.colors.brandPrimary};
  color: ${commonStyle.colors.white};
  padding-left: 48px;
`;

export const OrderDataRow = styled.div.attrs(() => ({}))`
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  width: 100%;

  &:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:nth-child(odd) {
    background-color: ${commonStyle.colors.white};
  }
`;

export const OrderDataIconContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const OrderDataValueContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex: 9;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  overflow: hidden;
  & > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0;
  }
`;
