import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import commonStyle from '../../../custom-ui/commonStyle';
import ToolTip from '../../../custom-ui/toolTip';
import * as S from './styles';

const TripItemDataRow = props => {
  const { icon, value, tooltip } = props;
  return (
    <S.TripDataRow>
      <ToolTip text={tooltip} placement={'top'}>
        <S.TripDataIconContainer>
          {cloneElement(icon, { fill: commonStyle.colors.greyMedium })}
        </S.TripDataIconContainer>
      </ToolTip>
      <S.TripDataValueContainer>
        {value}
      </S.TripDataValueContainer>
    </S.TripDataRow>
  );
};

TripItemDataRow.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.string,
  tooltip: PropTypes.string,
};

export default TripItemDataRow;
