import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { clearPersist, isSynchronized, startPersist, StorageAdapter } from 'mobx-persist-store';
import notify, { NotificationTypes } from '../../../services/notifier';
import { readStore, writeStore } from '../auth';
import * as RestaurantMenuService from '../../../services/restaurantMenuService';

export const foodMenuPersistOptions = {
  name: 'FoodMenu',
  properties: [
    'searchQuery',
    'searchList',
    'restaurantMenu',
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 0,
  }
};

export class FoodMenu {
  @observable searchQuery = '';
  @observable searchList = [];
  @observable restaurantMenu = [];
  @observable dishToEdit = null;
  @observable editedDish = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action changeSearchQuery = (query) => {
    this.searchQuery = query;
  }

  @action getRestaurantMenu = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantMenuService.getRestaurantMenu(this.rootStore.dataStores.authStore.token);
      if (resp.success) {
        runInAction(() => {
          this.restaurantMenu = resp.data;
        });
      }
      this.rootStore.uiStore.globalView.setLoaded();
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action setDishToEdit = dish => {
    this.dishToEdit = dish;
    this.editedDish = dish;
  }

  @action updateEditedDish = (data) => {
    this.editedDish = { ...this.editedDish, ...data };
  }

  @action updateDishData = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantMenuService.updateDishData(this.rootStore.dataStores.authStore.token, this.editedDish);
      if (resp.success) {
        if (this.searchQuery.length < 3) {
          this.getRestaurantMenu();
        } else {
          this.searchDishes();
        }
        this.setDishToEdit(resp.data);
      } else {
        notify(resp.message, NotificationTypes.error);
        this.rootStore.uiStore.globalView.setLoaded();
      }
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action updateDishStock = async dishId => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantMenuService.updateDishStock(this.rootStore.dataStores.authStore.token, dishId);
      if (resp.success) {
        if (this.searchQuery.length < 3) {
          this.getRestaurantMenu();
        } else {
          this.searchDishes();
        }
      } else {
        notify(resp.message, NotificationTypes.error);
        this.rootStore.uiStore.globalView.setLoaded();
      }
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action searchDishes = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantMenuService.findRestaurantDishes(this.rootStore.dataStores.authStore.token, this.searchQuery);
      if (resp.success) {
        this.searchList = resp.data;
      } else {
        notify(resp.message, NotificationTypes.error);
        this.rootStore.uiStore.globalView.setLoaded();
      }
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action clearSearchList = () => {
    this.searchList = [];
  }

  startPersist = async () => {
    startPersist(this);
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
}
