import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import { MainContainer } from '../orders';
import DishEditModal from './editModal';
import FoundDishes from './foundDishes';
import RestaurantMenu from './restaurantMenu';
import SearchBar from './searchBar';
import * as S from './styles';

const FoodMenu = props => {
  const { t } = useTranslation();
  useTitle(t(langKeys.navLinks.foodMenu));

  const { dataStores: { foodMenuStore } } = useStores();
  const { searchQuery, clearSearchList } = foodMenuStore;

  useEffect(() => {
    if (searchQuery.length <= 2) {
      clearSearchList();
    }
  }, [searchQuery, clearSearchList]);

  const handleDishEdit = dish => {
    foodMenuStore.setDishToEdit(dish);
  };

  return (
    <MainContainer>
      <SearchBar/>
      <S.DishesContainer>
        {searchQuery.length > 2 ?
          <FoundDishes
            onStockChange={(dishId => foodMenuStore.updateDishStock(dishId))}
            onEdit={handleDishEdit}
          /> :
          <RestaurantMenu onEdit={handleDishEdit}/>
        }
      </S.DishesContainer>
      <DishEditModal />
    </MainContainer>
  );
};

export default observer(FoodMenu);
