import { callAPI } from './apiService';

export const getRestaurantData = async (token) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/restaurant-data`,
  });
};

export const updateRestaurantData = async (token, data) => {
  return callAPI({
    method: 'PUT',
    authentication: token,
    body: data,
    endpoint: `api/corporate/food/restaurant-data`,
  });
};
