import { callAPI } from './apiService';

export const getCitiesList = (token) => {
  return callAPI({
    method: 'GET',
    endpoint: 'api/cities/list',
    authentication: token,
  });
};

export const getCarCategories = (token, cityId) => {
  return callAPI({
    method: 'GET',
    endpoint: `api/carcategories/app?cityId=${cityId}`,
    authentication: token
  });
};

export const getTripFromNumber = (token, tripNumber) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/findTrip?tripNumber=${tripNumber}`
  });
};

export const estimateFare = (token, data) => {
  return callAPI({
    method: 'POST',
    authentication: token,
    endpoint: `api/payment/fare/calculate`,
    body: data
  });
};

export const getNearByDrivers = (token, cityId, pickup) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/drivers?cityId=${cityId}&pickup=${pickup.join(',')}`,
  });
};

export const sendTripRequest = (token, trip, carCategory) => {
  return callAPI({
    method: 'POST',
    authentication: token,
    endpoint: `api/corporate/trip`,
    body: {
      trip,
      carCategory
    }
  });
};

export const getRequestUpdates = (token, tripId) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/trips/${tripId}`,
  });
}

export const cancelTripByPortal = (token, tripId) => {
  return callAPI({
    method: 'DELETE',
    authentication: token,
    endpoint: `api/corporate/trips/${tripId}`,
  });
}
