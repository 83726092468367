import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import langKeys from '../../../i18n/lang/keys';
import commonStyle from '../../custom-ui/commonStyle';
import LanguageSelector from '../../routes/common/login/languageSelector';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  & a {
    font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: ${commonStyle.colors.background};
    text-decoration: none;
    font-size: 14px;
  }
`;

const BottomLinks = props => {
  const { t } = useTranslation();
  return (
    <Container>
      <a href={'https://www.cabdo.de/impressum'} target={'_blank'} rel="noreferrer">{t(langKeys.login.imprint)}</a>
      <LanguageSelector theme={'light'} />
    </Container>
  );
};

export default BottomLinks;
