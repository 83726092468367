import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import langKeys from '../../../../../i18n/lang/keys';
import AddDestinationIcon from '../../../../../assets/icons/addDestination.svg';
import AddWaypointIcon from '../../../../../assets/icons/addWaypoint.svg';
import SwitchDirectionsIcon from '../../../../../assets/icons/switchDirections.svg';
import commonStyle from '../../../../custom-ui/commonStyle';
import ToolTip from '../../../../custom-ui/toolTip';

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 16px;
`;

const SButton = styled(Button)`
  height: 40px;
  width: 80px;
  &:hover > svg {
    fill: ${props => props.disabled ? commonStyle.colors.brandPrimary : commonStyle.colors.white};
  }
`;

const WPInstruments = props => {
  const { disabled, totalWps } = props;
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <ToolTip text={t(langKeys.createTrip.switchDirections)}>
        <SButton
          variant={'outline-dark'}
          disabled={disabled}
          size={'sm'}
          onClick={props.onSwitch}
        >
          <SwitchDirectionsIcon fill={commonStyle.colors.brandPrimary} style={{ marginTop: -1 }} />
        </SButton>
      </ToolTip>
      {totalWps < 6 && <ToolTip text={t(langKeys.createTrip.addWp)}>
        <SButton
          variant={'outline-dark'}
          disabled={disabled}
          size={'sm'}
          onClick={props.onAddWP}
        >
          <AddWaypointIcon fill={commonStyle.colors.brandPrimary} />
        </SButton>
      </ToolTip>}
      {totalWps < 6 && <ToolTip text={t(langKeys.createTrip.addDest)}>
        <SButton
          variant={'outline-dark'}
          disabled={disabled}
          onClick={props.onAddDestination}
        >
          <AddDestinationIcon fill={commonStyle.colors.brandPrimary} />
        </SButton>
      </ToolTip>}
    </ButtonContainer>
  );
};

WPInstruments.propTypes = {
  onSwitch: PropTypes.func,
  onAddDestination: PropTypes.func,
  onAddWP: PropTypes.func,
  disabled: PropTypes.bool,
  totalWps: PropTypes.number,
};

export default WPInstruments;
