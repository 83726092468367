import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import commonStyle from '../../../custom-ui/commonStyle';
import OrdersContent from './content';
import FoodOrdersModal from './modals';
import FoodOrderTabs from './tabs';

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 16px;
  @media (max-width: 648px) {
    padding: 0;
  }
`;

const ViewContainer = styled.div`
  position: relative;
  background-color: ${commonStyle.colors.greyLight};
  border-radius: 15px;
  width: 100%;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

const FoodOrders = props => {
  const { t } = useTranslation();
  useTitle(t(langKeys.navLinks.foodOrders));
  const { dataStores: { foodOrdersStore } } = useStores();
  const { currentTab, orderUpdates } = foodOrdersStore;

  return (
    <MainContainer>
      <ViewContainer>
        <FoodOrderTabs currentTab={currentTab} onTabPress={tab => foodOrdersStore.setActiveTab(tab)} orderUpdates={orderUpdates} />
        <OrdersContent />
      </ViewContainer>
      <FoodOrdersModal />
    </MainContainer>
  );
};

export default observer(FoodOrders);
