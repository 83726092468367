import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortLogo from '../../../assets/images/cabdo-for-partner-logo-white.png';

const LogoContainer = styled.div`
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 250px) {
    display: none;
  }
`;

const LogoImage = styled.img.attrs(() => ({
  alt: 'Logo'
}))`
  height: 100%;
  @media (max-width: 480px) {
    display: none;
  }
`;

const ShortLogo = styled.img.attrs(() => ({
  alt: 'Logo'
}))`
  display: none;
  max-width: 110px;
  @media (max-width: 600px) {
    display: ${props => props.hasLogo ? 'none' : 'block'};
  }
  @media (max-width: 480px) {
    display: block;
  }
`;

const BusinessLogo = props => {
  const { userLogo, isRestaurant } = props;
  const hasLogo = !!userLogo && !isRestaurant;
  return (
    <LogoContainer>
      {hasLogo && <LogoImage src={userLogo}/>}
      <ShortLogo hasLogo={hasLogo} src={shortLogo}/>
    </LogoContainer>
  );
};

BusinessLogo.propTypes = {
  userLogo: PropTypes.string,
  isRestaurant: PropTypes.bool,
};

export default BusinessLogo;
