import moment from 'moment';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/de'
import DateIcon from '../../assets/icons/date.svg';
import commonStyle from './commonStyle';
import {
  StyledFeedback,
  StyledInputContainer,
  StyledInputInput,
  StyledInputPrependIcon
} from './styledInput';

const Input = props => {
  return <StyledInputContainer style={{ marginTop: 0 }} valid={props.isValid}>
    <StyledInputPrependIcon valid={props.isValid}>
      <DateIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />
    </StyledInputPrependIcon>
    <StyledInputInput {...props} valid={props.isValid} />
  </StyledInputContainer>
};

const StyledDateInput = props => {
  const { value, label, locale, isValid, errorMessage } = props;

  const renderInput = (props) => <Input {...props} isValid={isValid}/>;

  return (
    <Fragment>
      <FormGroup>
        <FormLabel style={{ marginBottom: 3 }}>{label}</FormLabel>
        <DateTimePicker
          value={value}
          renderInput={renderInput}
          initialValue={moment().add(30, 'minute').toDate()}
          dateFormat={'DD.MM.YYYY'}
          timeFormat={false}
          locale={locale}
          onChange={props.onChange}
          strictParsing={true}
          input={true}
        />
        {!isValid && <StyledFeedback>{errorMessage}</StyledFeedback>}
      </FormGroup>
    </Fragment>
  );
};

StyledDateInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  locale: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default StyledDateInput;
