import { callAPI } from './apiService';

export const updateAccountData = (token, data) => {
  return callAPI({
    method: 'POST',
    endpoint: 'api/corporate/account',
    body: data,
    authentication: token,
  });
};

export const getAvailableRestaurantTags = (token) => {
  return callAPI({
    method: 'GET',
    endpoint: 'api/corporate/food/restaurant-data/tags',
    authentication: token,
  });
}
