import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDayTimeValueToDecimal, formatDayTimeValueToString } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import * as S from '../styles';
import DayRow from './dayRow';
import LunchControlRow from './lunchControl';

const WorkingHours = props => {
  const { t } = useTranslation();
  const { dataStores: { restaurantSettings: { restaurantData, updateRestaurantData }, authStore: { lang } } } = useStores();
  const [currentData, setCurrentData] = useState(null);
  const [datesError, setDatesError] = useState(false);

  const days = useMemo(() => [...new Array(7)].reduce((aggr, v, i) => ({
    ...aggr,
    [moment().locale(lang).day(i).format('dddd')]: i,
  }), {}), [lang]);

  const generatedDays = useMemo(() => {
    if (!days) return [];
    const daysArr = Object.keys(days);
    return [...daysArr.slice(1, 7), daysArr[0]];
  }, [days]);

  const timeOptions = useMemo(() => {
    const startTime = formatDayTimeValueToDecimal('07:00');
    return [...new Array(33)].map((v, i) => formatDayTimeValueToString(startTime + (i * 0.5))).map((v) => ({
      label: v,
      value: v
    }));
  }, []);

  useEffect(() => {
    if (!currentData?.orderHours?.length) return;
    setDatesError(currentData.orderHours.some(day => {
      if (!day.isWorking) return false;
      const dinnerFinishTime = formatDayTimeValueToDecimal(day.dinnerTo);
      const dinnerStartTime = formatDayTimeValueToDecimal(day.dinnerFrom);
      const closeTime = formatDayTimeValueToDecimal(day.to);
      const openTime = formatDayTimeValueToDecimal(day.from);
      return openTime >= closeTime || dinnerFinishTime <= dinnerStartTime;
    }))
  }, [currentData]);

  useEffect(() => {
    setCurrentData({ ..._.cloneDeep(toJS(restaurantData)) });
  }, [restaurantData]);

  const handleCurrentDataChange = (data, dayIndex) => {
    const openData = _.cloneDeep(currentData);
    openData.orderHours[dayIndex] = { ...openData.orderHours[dayIndex], ...data };
    setCurrentData(openData);
  }

  const handleLunchTimeChange = (data) => {
    setCurrentData(_.cloneDeep({ ...currentData, orderHours: data }));
  }

  const handleDatesUpdate = () => {
    updateRestaurantData({ orderHours: currentData.orderHours, updateFallbackData: false });
  }

  if (!currentData) return null;

  return (
    <S.WorkingHoursContainer>
      <S.WorkingHoursTitle>{t(langKeys.navLinks.openHours)}</S.WorkingHoursTitle>
      <S.DaysMainContainer>
        <S.DayRow>
          <S.OffsetCol />
          <S.DayContainer>
            <S.DayStr>{t(langKeys.restaurantWorkingHours.weekDay)}</S.DayStr>
          </S.DayContainer>
          <S.TimeCol active bold>
            {t(langKeys.restaurantWorkingHours.opening)}
          </S.TimeCol>
          <S.TimeCol active bold>
            {t(langKeys.restaurantWorkingHours.closing)}
          </S.TimeCol>
          <S.OffsetCol/>
        </S.DayRow>
        {generatedDays.map((day) =>
          <DayRow
            key={day}
            day={day}
            dayIndex={days[day]}
            timeOptions={timeOptions}
            hoursData={currentData.orderHours}
            onChange={handleCurrentDataChange}
          />)}
        <LunchControlRow
          timeOptions={timeOptions}
          orderingData={currentData.orderHours}
          onChange={handleLunchTimeChange}
        />
      </S.DaysMainContainer>
      {datesError && <S.DataFailure>{t(langKeys.restaurantWorkingHours.dataError)}</S.DataFailure>}
      <S.SubmitButton
        disabled={_.isEqual(toJS(restaurantData), currentData) || datesError}
        onClick={handleDatesUpdate}
      >
        {t(langKeys.restaurantWorkingHours.apply)}
      </S.SubmitButton>
    </S.WorkingHoursContainer>
  );
};

export default observer(WorkingHours);
