import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import DishItem from '../restaurantMenu/dishItem';
import * as S from '../styles';

const FoundDishes = props => {
  const { dataStores: { foodMenuStore } } = useStores();
  const { t } = useTranslation();
  return (
    <S.RestaurantCategoryContainer>
      <S.CategoryName>{t(langKeys.foodMenu.found)}:</S.CategoryName>
      {!!foodMenuStore.searchList.length && <S.MenuBox>
        {foodMenuStore.searchList.map(dish => <DishItem
          key={dish._id}
          dish={dish}
          onStockChange={() => props.onStockChange(dish._id)}
          onEdit={() => props.onEdit(dish)}
        />)}
      </S.MenuBox>}
      {!foodMenuStore.searchList.length && <S.CategoryName style={{ textAlign: 'center', width: '100%' }}>{t(langKeys.foodMenu.notFound)}</S.CategoryName>}
    </S.RestaurantCategoryContainer>
  );
};

FoundDishes.propTypes = {
  onStockChange: PropTypes.func,
  onEdit: PropTypes.func,
}

export default observer(FoundDishes);
