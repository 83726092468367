import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import langKeys from '../../../../../i18n/lang/keys';
import StyledDateInput from '../../../../custom-ui/styledDateInput';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px 20px;
`;

const SButton = styled(Button)`
`;

export const useExportData = (onConfirm, onHide, titleString = null, submitText = null) => {
  const [startRange, setStartDate] = useState(moment().startOf('week'));
  const [endRange, setEndDate] = useState(moment().endOf('week'));
  const { t } = useTranslation();

  const handleSubmit = () => {
    onConfirm({ startRange, endRange, zone: moment().utcOffset() });
  };

  const title = titleString || t(langKeys.myRides.exportTrips);

  const bodyContent = <Row>
    <Col md={6}>
      <StyledDateInput
        value={startRange}
        errorMessage={''}
        locale={'de'}
        label={t(langKeys.myRides.fromDate)}
        isValid={!!startRange && startRange.isBefore(endRange)}
        onChange={date => setStartDate(moment(date).startOf('day'))}
      />
    </Col>
    <Col md={6}>
      <StyledDateInput
        value={endRange}
        errorMessage={''}
        locale={'de'}
        label={t(langKeys.myRides.toDate)}
        isValid={!!endRange && endRange.isAfter(startRange)}
        onChange={date => setEndDate(moment(date).endOf('day'))}
      />
    </Col>
  </Row>;

  const footerContent = <ButtonContainer>
    <SButton
      variant={'outline-dark'}
      onClick={onHide}
    >
      {t(langKeys.hide)}
    </SButton>
    <SButton
      variant={'success'}
      onClick={handleSubmit}
      disabled={!startRange || !endRange || moment(endRange).isBefore(moment(startRange))}
    >
      {submitText ?? t(langKeys.myRides.export)}
    </SButton>
  </ButtonContainer>;

  return { title, bodyContent, footerContent };
};
