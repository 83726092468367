import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { useStores } from '../../../stores/helpers/use-stores';
import BusinessLogo from './businessLogo';
import LogoutButton from './logoutButton';
import MicroLogo from './microLogo';
import SidebarToggler from './sidebarToggler';

const Header = styled(Navbar).attrs(() => ({
  fixed: 'top',
  role: 'navigation',
  sticky: 'top'
}))`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 70px;
`;

const NavigationBar = props => {
  const {
    uiStore: {
      globalView
    },
    dataStores: {
      authStore
    }
  } = useStores();

  return (
    <Header>
      <MicroLogo />
      <SidebarToggler onToggle={(v) => globalView.toggleSideBar(v)} isSidebarVisible={!globalView.isSideBarHidden}/>
      <BusinessLogo userLogo={authStore.userData?.logo} isRestaurant={authStore.isRestaurant} />
      <LogoutButton onLogout={() => authStore.signOut()} />
    </Header>
  );
};

export default observer(NavigationBar);
