import { callAPI } from './apiService';

export const loginUser = async (credentials) => {
  return await callAPI({
    method: 'POST',
    endpoint: 'api/auth/login',
    body: credentials,
  });
}

export const setupUser = async (jwtAccessToken) => {
  return await callAPI({
    method: 'PUT',
    endpoint: 'api/auth/login',
    body: { jwtAccessToken },
  });
}

export const logoutUser = async (token) => {
  return await callAPI({
    method: 'GET',
    endpoint: 'api/auth/logout',
    authentication: token
  });
}

export const passRecovery = async (data) => {
  return await callAPI({
    method: 'POST',
    endpoint: 'api/users/recovery',
    body: data
  });
}
