import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import commonStyle from '../../../../custom-ui/commonStyle';

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const BodyGeneralRow = styled.div.attrs(() => ({}))`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 8px;
`;

export const PriceBlock = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AddressRow = styled.a.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 8px 0;
  text-decoration: none;
  color: ${commonStyle.colors.brandPrimary};
  fill: ${commonStyle.colors.brandPrimary};

  &:hover {
    text-decoration: none;
    color: ${commonStyle.colors.brandFood};
    fill: ${commonStyle.colors.brandFood};
  }
`;

export const DishesBlock = styled.div.attrs(() => ({}))`
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DishesTitle = styled.h4.attrs(() => ({}))`
  font-size: 14px;
  font-weight: 700;
  align-self: flex-start;
`;

export const DishesBody = styled.div.attrs(() => ({}))`
  width: 98%;
  background-color: ${commonStyle.colors.greyLight};
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const OrderItem = styled.div.attrs(() => ({}))`
  padding: 8px 16px;
  &:last-child {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  &:nth-child(even) {
    background-color: ${commonStyle.colors.white};
  }
`;

export const OrderClientRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0;
  flex-wrap: wrap;
  width: 100%;
`;

export const ClientInfoBox = styled.div.attrs(() => ({}))`
  width: 90%;
  margin: 8px;
  max-width: 200px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const ClientTopRow = styled.div.attrs(() => ({}))`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: ${commonStyle.colors.white};
  background-color: ${commonStyle.colors.brandPrimary};
  padding: 0 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const DriverLocBtn = styled.a.attrs(() => ({}))`
  align-items: center;
  justify-content: center;
  display: flex;
  fill: ${commonStyle.colors.white};
  &:hover {
    fill: ${commonStyle.colors.brandFood};
  }
`;

export const ClientRow = styled.div.attrs(() => ({}))`
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  width: 100%;
  padding: 0 8px;

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:nth-child(even) {
    background-color: ${commonStyle.colors.greyLight};
  }
`;

export const FooterContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const CloseButton = styled(Button).attrs(() => ({
  variant: 'outline-dark'
}))``;

export const StarBlock = styled.div.attrs(() => ({}))`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StarItem = styled.div.attrs(() => ({}))`
  height: 30px;
  width: 30px;
  margin: 0 4px;
  color: ${commonStyle.colors.brandFood};
  font-size: 28px;
`;

export const CommentBlock = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  margin: 16px;
  font-weight: 700;
`;

export const MapRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  width: 100%;
`;

export const MapBlock = styled.div.attrs(() => ({}))`
  width: 98%;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const MapHeaderRow = styled.div.attrs(() => ({}))`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: ${commonStyle.colors.white};
  background-color: ${commonStyle.colors.brandPrimary};
  padding: 0 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;`;

export const MapContainer = styled.div.attrs(() => ({}))`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 250px;
  overflow: hidden;
`;

export const MapImage = styled.img.attrs(() => ({}))`
  height: 100%;
  width: 100%;
`;
