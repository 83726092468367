import styled from 'styled-components';
import commonStyle from './commonStyle';

import backgroundImage from '../../assets/images/mainBackgroundImage.png';

export const UnAuthLayoutWrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${commonStyle.colors.brandPrimary};
  background-image: url(${backgroundImage});
  background-size: cover;
`;

export const UnAuthCenteredContainer = styled.div`
  background-color: #FFF;
  border-radius: 20px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  margin: 0 8px;
  @media (max-width: 320px) {
    padding: 20px 8px;
  }
`;
