import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import Masonry from 'react-masonry-component';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import PaginationRow from '../../../../custom-ui/paginationRow';
import OrderFullDetails from '../orderFullDetails';
import SortControl from '../sortControl';
import OrderDetails from './orderDetails';
import * as S from './styles';

const OrdersContent = props => {
  const [detailsOrderId, setDetailsOrderId] = useState(null);
  const { dataStores: { foodOrdersStore, authStore } } = useStores();
  const { currentTab, orderList, ordersMeta, getOrders, displayMeta } = foodOrdersStore;
  const refreshInterval = useRef(null);

  useEffect(() => {
    getOrders();
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }
    refreshInterval.current = setInterval(() => {
      getOrders();
    }, 30 * 1000);
    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [currentTab, displayMeta, getOrders]);

  useEffect(() => {
    if (!detailsOrderId) {
      getOrders();
    }
    // eslint-disable-next-line
  }, [detailsOrderId]);

  const list = useMemo(() => {
    return toJS(orderList);
  }, [orderList]);

  return (
    <S.ContentContainer>
      <S.SortExportContainer>
        <SortControl justify={'space-around'} selectorBg={commonStyle.colors.greyLight}/>
      </S.SortExportContainer>
      {!list.length && <S.NoOrders/>}
      {!!list.length && <S.OrdersContainer>
        <Masonry>
          {list.map(o => <OrderDetails
            key={o._id}
            order={o}
            hasOwnDelivery={authStore?.userData?.restaurantData?.hasOwnDelivery}
            confirmOrder={() => foodOrdersStore.setOrderAsConfirmed(o._id)}
            onOrderDetails={() => setDetailsOrderId(o._id)}
          />)}
        </Masonry>
      </S.OrdersContainer>}
      {!!toJS(ordersMeta)?.totalNoOfPages && <S.PaginationContainer>
        <PaginationRow
          numOfPages={ordersMeta.totalNoOfPages}
          currentPage={displayMeta.pageNo}
          currentLimit={displayMeta.limit}
          label={'Best.'}
          buttonBgColor={commonStyle.colors.greyLight}
          onLimitChange={limit => foodOrdersStore.updateDisplayMeta({ limit, pageNo: 1 })}
          onPageChange={pageNo => foodOrdersStore.updateDisplayMeta({ pageNo })}
        />
      </S.PaginationContainer>}
      <OrderFullDetails orderId={detailsOrderId} onHide={() => setDetailsOrderId(null)}/>
    </S.ContentContainer>
  );
};

OrdersContent.propTypes = {
  meta: PropTypes.object,
};

export default observer(OrdersContent);
