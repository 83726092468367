import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import tripStatuses, { bookingStatuses } from '../../../../constants/tripStatuses';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import ModalContainer from './modals/modalContainer';
import Trips from './rides';

const MyRidesContainer = styled.div`
  min-height: 100%;
  margin: 0;
  padding: 5px;
`;

const MyTrips = props => {
  const { t } = useTranslation();
  useTitle(t(langKeys.navLinks.myTrips));
  const [modalData, setModalData] = useState({ tripData: null, isVisible: false, type: '', onConfirm: () => {} });
  const { dataStores: { myRidesStore, createTripStore } } = useStores();
  const { bookedTrips, bookedMeta, activeTrips, activeMeta, completedTrips, completedMeta } = myRidesStore;

  const handleShowModal = (type, trip = null) => {
    const data = {};
    switch (type) {
      case 'cancel':
        data.isVisible = true;
        data.tripData = trip;
        data.type = type;
        data.onConfirm = async () => {
          if (bookingStatuses.includes(trip.tripStatus)) {
            await myRidesStore.cancelPreBooking(trip._id);
          } else if ([tripStatuses.arriving, tripStatuses.arrived].includes(trip.tripStatus) && trip.riderId._id) {
            await createTripStore.cancelLastTripById(trip.riderId._id);
          }
          handleModalHide();
          myRidesStore.toggleRefreshFlag();
        };
        break;
      case 'edit':
        data.isVisible = true;
        data.tripData = trip;
        data.type = type;
        data.onConfirm = async (tripData) => {
          await myRidesStore.updatePreBooking(trip._id, tripData);
          handleModalHide();
          myRidesStore.toggleRefreshFlag();
        }
        break;
      case 'export':
      default:
        data.isVisible = true;
        data.type = type;
        data.onConfirm = (rangeData) => {
          handleModalHide();
          myRidesStore.exportTrips(rangeData);
        }
        break;
    }
    setModalData({  ...modalData, ...data });
  }

  const handleModalHide = () => {
    setModalData({
      tripData: null,
      isVisible: false,
      type: '',
      onConfirm: () => {}
    });
  };

  return (
    <MyRidesContainer>
      <Trips
        title={t(langKeys.myRides.bookedRidesTitle)}
        trips={bookedTrips}
        showCounter={true}
        meta={bookedMeta}
        onEdit={trip => handleShowModal('edit', trip)}
        onCancel={trip => handleShowModal('cancel', trip)}
        onGetTrips={(meta) => myRidesStore.getTrips('booked', meta)}
      />
      <Trips
        title={t(langKeys.myRides.activeRidesTitle)}
        trips={activeTrips}
        showCounter={true}
        meta={activeMeta}
        onEdit={() => {}}
        onCancel={trip => handleShowModal('cancel', trip)}
        onGetTrips={(meta) => myRidesStore.getTrips('active', meta)}
      />
      <Trips
        title={t(langKeys.myRides.completedRidesTitle)}
        trips={completedTrips}
        meta={completedMeta}
        showExport={true}
        onEdit={() => {}}
        onExport={() => handleShowModal('export')}
        onCancel={() => {}}
        onGetTrips={(meta) => myRidesStore.getTrips('completed', meta)}
      />
      <ModalContainer onHide={handleModalHide} {...modalData} />
    </MyRidesContainer>
  );
};

export default observer(MyTrips);
