import { observer } from 'mobx-react-lite';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyle from './commonStyle';

const Backdrop = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 1900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  &.opened {
    background-color: ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.7)};
    pointer-events: auto;
  }
`;

const Drawer = styled.div`
  min-height: 100%;
  width: 470px;
  right: -470px;
  top: 0;
  background-color: ${commonStyle.colors.white};
  position: absolute;
  z-index: 2000;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &.opened {
    right: 0;
  }
  @media (max-width: 500px) {
    width: 100vw;
    right: -100vh;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid ${commonStyle.colors.greyLight2};
  & > p {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
`;

const CloseContainer = styled.div`
  color: ${commonStyle.colors.greyMedium2};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  &:hover {
    color: ${commonStyle.colors.brandPrimary};
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 60px;
  border-top: 1px solid ${commonStyle.colors.greyLight2};
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${commonStyle.colors.white};
`;

const BodyContainer = styled.div`
  width: 100%;
  padding: 16px 16px;
  margin-bottom: 60px;
  @media (max-width: 500px) {
    padding: 16px 4px;
  }
`;


const RightDrawer = props => {
  const { uiStore: { drawerStore } } = useStores();

  const { isOpened, title, body, footer, closeOnBackdrop } = drawerStore;

  const handleBackdrop = () => {
    if (!closeOnBackdrop) return;
    drawerStore.closeDrawer();
  }

  return (
    <>
      <Backdrop className={isOpened ? 'opened' : ''} onClick={handleBackdrop}/>}
      <Drawer className={isOpened ? 'opened' : ''}>
        <HeaderContainer>
          <p>{title}</p>
          <CloseContainer onClick={() => drawerStore.closeDrawer()}>
            <AiOutlineClose />
          </CloseContainer>
        </HeaderContainer>
        <BodyContainer>
          {body}
        </BodyContainer>
        <FooterContainer>
          {footer}
        </FooterContainer>
      </Drawer>
    </>
  );
};

export default observer(RightDrawer);
