import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { defineCategoryColor, getCategoryImage } from '../../../../../constants/carCategories';
import { formatPrice } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import commonStyle from '../../../../custom-ui/commonStyle';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: ${props => props.active ? 'default' : 'pointer'};
  height: 150px;
  width: 130px;
  background-color: ${props => props.active ? commonStyle.colors.greyMedium2 : commonStyle.colors.white};
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid ${commonStyle.colors.greyMedium2}
`;

const CategoryName = styled.div`
  font-size: 18px;
  height: 20px;
  color: ${commonStyle.colors.brandPrimary};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  margin-top: -5px;
`;

const CategoryLabel = styled.img`
  max-height: 50px;
  width: 120px;
`;

const CapacityLabel = styled.div`
  color: ${commonStyle.colors.brandPrimary};
  font-size: 14px;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

const FareBox = styled.div`
  width: 120px;
  height: 40px;
  background-color: ${props => props.color};
  color: ${commonStyle.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  text-align: center;
`;

const CategoryItem = props => {
  const { active, fare, categoryData, distance, fixedFare } = props;
  const hasExtra = useMemo(() => {
    return categoryData.name.indexOf('XL') >= 0;
  }, [categoryData]);
  const { t } = useTranslation();
  return (
    <CategoryContainer active={active} onClick={props.onSelect}>
      <CategoryName active={active}>{categoryData.name}</CategoryName>
      <CategoryLabel src={getCategoryImage(categoryData.name)} alt={categoryData.name} />
      {fare && <FareBox color={defineCategoryColor(categoryData.name)}>
        <Text>{categoryData.isMetered && t(langKeys.createTrip.pricePrefix)}{formatPrice(fixedFare || fare.totalFare)}{!categoryData.isMetered && t(langKeys.createTrip.fixed)}</Text>
        <Text>{distance.toFixed(1)} km</Text>
      </FareBox>}
      <CapacityLabel active={active}>{hasExtra && t(langKeys.createTrip.upTo)}{t(langKeys.createTrip.numOfPassengers, { number: categoryData.capacity })}</CapacityLabel>
    </CategoryContainer>
  );
};

CategoryItem.propTypes = {
  active: PropTypes.bool,
  categoryData: PropTypes.object,
  fare: PropTypes.object,
  distance: PropTypes.number,
  onSelect: PropTypes.func,
  fixedFare: PropTypes.number,
};

export default CategoryItem;
