import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from 'react-bootstrap';
import styled from 'styled-components';
import commonStyle from './commonStyle';

const _isImage = (file) => {
  return !!(file.type.match('image/(png|jpg|jpeg)?') && file.size <= 2512000);
};

const InputContainer = styled.div`
  width: 130px;
  margin: 8px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UploadContainer = styled.div`
  height: 130px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid ${commonStyle.colors.brandPrimary};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  & > p {
    color: ${commonStyle.colors.greyMedium};
    margin: 4px 0;
    font-size: 14px;
  }
`;

const UploadInput = styled.input.attrs(() => ({
  type: 'file',
  accept: 'image/*'
}))`
  display: none;
`;

const StyledImageInput = props => {
  const { label } = props;
  const inputRef = useRef(null);

  const handleUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file && _isImage(file)) {
      reader.onloadend = () => {
        props.onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <InputContainer>
      <FormLabel>{label}:</FormLabel>
      <UploadContainer onClick={() => inputRef.current.click()}>
        <p>Maximum: 2.5Mb</p>
        <p>Only: jpg, jpeg, png</p>
        <UploadInput
          ref={inputRef}
          onChange={handleUpload}
        />
      </UploadContainer>
    </InputContainer>
  );
};

StyledImageInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default StyledImageInput;
