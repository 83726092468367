import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { defineDishType, dishTypes } from '../../../../../constants/dishTypes';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import StyledInput from '../../../../custom-ui/styledInput';
import DishIcon from '../../../../../assets/icons/fork_knife.svg';
import StyledTextArea from '../../../../custom-ui/styledTextArea';
import PriceTool from './priceTool';
import * as S from './styles';

const DishEditBody = props => {
  const { t } = useTranslation();
  const { dataStores: { foodMenuStore } } = useStores();
  const { editedDish } = foodMenuStore;

  const dishType = useMemo(() => defineDishType(editedDish), [editedDish]);

  if (!editedDish) return null;

  return (
    <S.BodyContainer>
      <S.ItemContainer>
        <StyledInput
          value={editedDish.name}
          label={t(langKeys.foodMenu.dishName)}
          onChange={v => foodMenuStore.updateEditedDish({ name: v })}
          prependIcon={<DishIcon fill={commonStyle.colors.brandPrimary} />}
        />
      </S.ItemContainer>
      {dishType !== dishTypes.constructorAddition && <S.ItemContainer>
        <StyledTextArea
          label={`${t(langKeys.foodMenu.dishDesc)}:`}
          value={editedDish.description}
          onChange={v => foodMenuStore.updateEditedDish({ description: v })}
          numberOfLines={5}
        />
      </S.ItemContainer>}
      <S.ItemContainer>
        <PriceTool />
      </S.ItemContainer>
    </S.BodyContainer>
  );
};

export default observer(DishEditBody);
