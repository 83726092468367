import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tabs from '../../../../../constants/foodOrderTabs';
import commonStyle from '../../../../custom-ui/commonStyle';
import SortControl from '../sortControl';
import FoodTab from './tab';

const TabsRow = styled.div`
  position: relative;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${commonStyle.colors.white};
  box-shadow: 1px 1px 2px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.1)};

  & > .tab.first {
    border-top-left-radius: 15px;
  }

  & > .tab.last {
    border-top-right-radius: 15px;
  }
`;

const SortBtnsContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 16px;
  @media (max-width: 1500px) {
    display: none;
  }
`;

const FoodOrderTabs = props => {
  const { currentTab, orderUpdates } = props;

  const handleTabPress = (tab) => {
    if (currentTab !== tab) {
      props.onTabPress(tab);
    }
  };

  const tabsArr = useMemo(() => {
    return Object.values(tabs);
  }, []);

  return (
    <TabsRow>
      {tabsArr.map((tab, idx) =>
        <FoodTab
          key={tab}
          isFirst={!idx}
          isLast={idx === (tabsArr.length - 1)}
          isActive={currentTab === tab}
          numOfTabs={tabsArr.length}
          type={tab}
          tabInfo={orderUpdates[tab]}
          onPress={() => handleTabPress(tab)}
        />)}
      <SortBtnsContainer>
        <SortControl />
      </SortBtnsContainer>
    </TabsRow>
  );
};

FoodOrderTabs.propTypes = {
  currentTab: PropTypes.string,
  onTabPress: PropTypes.func,
  orderUpdates: PropTypes.object,
};

export default FoodOrderTabs;
