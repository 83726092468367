import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from '../../../../../stores/helpers/use-stores';
import DishEditBody from './body';
import DishEditFooter from './footer';

const DishEditModal = props => {
  const { dataStores: { foodMenuStore }, uiStore: { drawerStore } } = useStores();
  const { dishToEdit } = foodMenuStore;

  const title = useMemo(() => dishToEdit?.name ?? '', [dishToEdit]);

  const footer = useMemo(() => {
    return <DishEditFooter
      onHide={() => foodMenuStore.setDishToEdit(null)}
      onSubmit={() => foodMenuStore.updateDishData()}
    />
    // eslint-disable-next-line
  }, [foodMenuStore]);

  useEffect(() => {
    if (!drawerStore.isOpened) {
      foodMenuStore.setDishToEdit(null);
    }
    // eslint-disable-next-line
  }, [drawerStore.isOpened]);

  useEffect(() => {
    if (dishToEdit) {
      drawerStore.showDrawer({
        title,
        footer,
        body: <DishEditBody />
      });
    } else {
      drawerStore.closeDrawer();
    }
    // eslint-disable-next-line
  }, [dishToEdit, title, footer]);

  return null;
};

export default observer(DishEditModal);
