import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import notify, { NotificationTypes } from '../../../services/notifier';
import * as RestaurantSettingsService from '../../../services/restaurantSettingsService';

export class RestaurantSettings {
  @observable restaurantData = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action getRestaurantData = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantSettingsService.getRestaurantData(this.rootStore.dataStores.authStore.token);
      if (resp.success) {
        runInAction(() => {
          this.restaurantData = resp.data;
        });
      } else {
        notify(resp.message, NotificationTypes.error);
      }
      this.rootStore.uiStore.globalView.setLoaded();
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action updateRestaurantData = async (updateData) => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await RestaurantSettingsService.updateRestaurantData(this.rootStore.dataStores.authStore.token, updateData);
      if (resp.success) {
        this.getRestaurantData();
      } else {
        notify(resp.message, NotificationTypes.error);
      }
      this.rootStore.uiStore.globalView.setLoaded();
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }
}
