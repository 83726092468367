import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineMenu } from 'react-icons/ai';
import EuroIcon from '../../../../../assets/icons/euro-icon.svg';
import WarningIcon from '../../../../../assets/icons/warning.svg';
import DishesIcon from '../../../../../assets/icons/dishes.svg';
import CommentIcon from '../../../../../assets/icons/comment-icon.svg';
import DriverIcon from '../../../../../assets/icons/driverIcon.svg';
import PhoneIcon from '../../../../../assets/icons/phone-icon.svg';
import CarIcon from '../../../../../assets/icons/car-thin.svg';
import RatingIcon from '../../../../../assets/icons/rating.svg';
import DestinationIcon from '../../../../../assets/icons/dest-icon.svg';
import OrderStatusIcon from '../../../../../assets/icons/food-icon.svg';
import ClientIcon from '../../../../../assets/icons/profile.svg';
import { COMPLETED } from '../../../../../constants/delivery-order-statuses';
import {
  formatAddressString,
  formatPrice, getDeliveryOrderDateString,
  getDriverAndTripDataFromOrder,
} from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import commonStyle from '../../../../custom-ui/commonStyle';
import ToolTip from '../../../../custom-ui/toolTip';
import OrderInfoRow from './orderInfoRow';
import * as S from './styles';

const OrderDetails = props => {
  const { order, hasOwnDelivery } = props;
  const { t } = useTranslation();

  const dishesString = useMemo(() => {
    let res = `${order.itemsQty ?? ''} ${t(langKeys.foodOrders.dishes)} (`;
    const dishes = order.orderItems.map(item => ({ name: item?.dishId?.name, amt: item.qty }));
    const lastInd = dishes.length - 1;
    dishes.forEach((d, ind) => {
      res += `${d.amt}x ${d.name}`;
      res += ind === lastInd ? ')' : ', ';
    })
    return res;
  }, [order, t]);

  const orderTripData = useMemo(() => {
    return getDriverAndTripDataFromOrder(order);
  }, [order]);

  const dateString = useMemo(() => {
    return getDeliveryOrderDateString(order.deliveryDate, order.orderStatus, order.isSelfPickup, hasOwnDelivery);
  }, [order, hasOwnDelivery]);

  return (
    <S.OrderContainer>
      <S.OrderHeaderRow>
        {!order.providerConfirmed && order.orderStatus !== COMPLETED && <ToolTip text={t(langKeys.foodOrders.confirmOrder)} placement={'bottom'}>
          <S.NewOrderBadge
            onClick={props.confirmOrder}
          >
            <WarningIcon fill={commonStyle.colors.brandDanger}/>
          </S.NewOrderBadge>
        </ToolTip>}
        <S.OrderNo>{`${t(langKeys.foodOrders.orderShort)} #${order.orderNo}`}</S.OrderNo>
        <S.DeliveryType isDelivery={!order.isSelfPickup}>
          {t(langKeys.foodOrders[order.isSelfPickup ? 'pickup' : 'delivery'])}
        </S.DeliveryType>
        <S.DeliveryDate>
          {dateString}
        </S.DeliveryDate>
        <ToolTip text={t(langKeys.foodOrders.showMore)} placement={'bottom'}>
          <S.MoreButton
            onClick={props.onOrderDetails}
          >
            <AiOutlineMenu />
          </S.MoreButton>
        </ToolTip>
      </S.OrderHeaderRow>
      <S.OrderDataContainer>
        <OrderInfoRow
          tooltip={t(langKeys.foodOrders.dishes)}
          value={dishesString}
          icon={<DishesIcon/>}
        />
        {!order.isSelfPickup && <OrderInfoRow
          tooltip={t(langKeys.foodOrders.deliveryAddress)}
          value={formatAddressString(order.deliveryPlace.address)}
          icon={<DestinationIcon/>}
        />}
        <OrderInfoRow
          tooltip={t(langKeys.foodOrders.price)}
          icon={<EuroIcon/>}
          value={`${formatPrice(order.priceData?.orderItemsFare, { addEuroSign: false })} (${t(langKeys.paymentMethod[order?.paymentMethod])})`}
        />
        <OrderInfoRow
          tooltip={t(langKeys.foodOrders.orderStatus)}
          icon={<OrderStatusIcon/>}
          value={capitalize(order.orderStatus)}
        />
        {order.foodRatingByClient > 0 && <OrderInfoRow
          tooltip={''}
          icon={<RatingIcon/>}
          value={`${order.foodRatingByClient}${!!(order.clientFeedbackComment?.trim()) ? ` - ${order.clientFeedbackComment}` : ''}`}
        />}
        {order.orderStatus !== COMPLETED && <>
          <OrderInfoRow
            tooltip={t(langKeys.foodOrders.client)}
            icon={<ClientIcon/>}
            value={order.isSelfPickup ? order.clientOrderName : order.clientOrderLName}
          />
          <OrderInfoRow
            tooltip={t(langKeys.createTrip.phoneNo)}
            icon={<PhoneIcon/>}
            value={order.clientPhoneNo}
          />
        </>}
        {!!order.orderComment && <OrderInfoRow
          tooltip={t(langKeys.foodOrders.comment)}
          icon={<CommentIcon/>}
          value={order.orderComment}
        />}
        {!!orderTripData && orderTripData.showTrip && <>
        <S.DriverSeparator>
          {t(langKeys.foodOrders.driver)}
        </S.DriverSeparator>
        <OrderInfoRow
          tooltip={t(langKeys.foodOrders.driver)}
          icon={<DriverIcon/>}
          value={orderTripData.driverName}
        />
        <OrderInfoRow
          tooltip={t(langKeys.createTrip.phoneNo)}
          icon={<PhoneIcon/>}
          value={orderTripData.driverPhoneNo}
        />
        <OrderInfoRow
          tooltip={t(langKeys.myRides.carDetails)}
          icon={<CarIcon/>}
          value={orderTripData.car}
        />
        </>}
      </S.OrderDataContainer>
    </S.OrderContainer>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object,
  hasOwnDelivery: PropTypes.bool,
  confirmOrder: PropTypes.func,
  onOrderDetails: PropTypes.func,
};

export default OrderDetails;
