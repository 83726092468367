import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import langKeys from '../../../../../i18n/lang/keys';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px 20px;
`;

const Question = styled.p`
  margin: 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const SButton = styled(Button)`
`;

export const CancelModalBody = props => {
  const { tripNumber } = props;
  const { t } = useTranslation();
  return <Question>
    {t(langKeys.myRides.sureToRemove, { tripNumber: tripNumber })}
  </Question>
};

CancelModalBody.propTypes = {
  tripNumber: PropTypes.number,
};

export const CancelModalFooter = props => {
  const { t } = useTranslation();

  return <ButtonContainer>
    <SButton
      variant={'outline-dark'}
      onClick={props.onHide}
    >
      {t(langKeys.hide)}
    </SButton>
    <SButton
      variant={'danger'}
      onClick={props.onConfirm}
    >
      {t(langKeys.confirm)}
    </SButton>
  </ButtonContainer>;
};


CancelModalFooter.propTypes = {
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};
