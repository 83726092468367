import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import styled from 'styled-components';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

const RequestingComponent = props => {
  const { t } = useTranslation();
  const { requestCounter, status, hasSpinner } = props;
  if (requestCounter && requestCounter > 7000 && status === 'REQUESTED') {
    props.resetInterval();
    return null;
  }
  return <Alert variant={'warning'} style={{ width: '100%' }}>
    {t(langKeys.createTrip.tripRequestMessages.requesting)}<br/>
    <FlexCenter>
      {hasSpinner && <Spinner animation={'border'} variant={'primary'}/>}
    </FlexCenter>
  </Alert>;
};

RequestingComponent.propTypes = {
  requestCounter: PropTypes.number,
  status: PropTypes.string,
  hasSpinner: PropTypes.bool,
  resetInterval: PropTypes.func,
};

const NoNearByDriver = (props) => {
  const { t } = useTranslation();
  return <Alert variant={'danger'} style={{ width: '100%' }} onClose={props.onHide} dismissible>
    {t(langKeys.createTrip.tripRequestMessages.noDrivers)}
  </Alert>;
}

const PreBookingSuccess = (props) => {
  const { t } = useTranslation();
  return <Alert variant={'success'} style={{ width: '100%' }} onClose={props.onHide} dismissible>
    {t(langKeys.createTrip.tripRequestMessages.bookingSuccess)}
  </Alert>;
};

const DriverArriving = (props) => {
  const { t } = useTranslation();
  const { trip } = props;
  return <Alert variant={'success'} style={{ width: '100%' }} onClose={props.onHide} dismissible>
    <div><strong>{t(langKeys.createTrip.tripRequestMessages.auto)}</strong> {trip.car}</div>
    <div><strong>{t(langKeys.createTrip.tripRequestMessages.plateNumber)}</strong> {trip.licensePlateNumber}</div>
    <div><strong>{t(langKeys.createTrip.tripRequestMessages.driver)}</strong> {trip.driver}</div>
    <div><strong>{t(langKeys.createTrip.phoneNo)}</strong> {trip.driverPhoneNo}</div>
    <div><strong>{t(langKeys.createTrip.tripRequestMessages.arriveIn)}</strong> in {trip.eta + 2} Minuten</div>
    {trip.tripStatus === 'tripOnTrip' &&
    <div><strong>{t(langKeys.createTrip.tripRequestMessages.tripOnTrip)}!!!</strong></div>}
    <FlexEnd>
      <StyledButton variant="danger" onClick={props.cancelLastTrip}>
        <AiOutlineCloseCircle style={{ marginRight: 8 }}/> {t(langKeys.createTrip.tripRequestMessages.cancel)}
      </StyledButton>
    </FlexEnd>
  </Alert>;
};

DriverArriving.propTypes = {
  trip: PropTypes.object,
  cancelLastTrip: PropTypes.func,
  onHide: PropTypes.func,
}

const RequestStatusTool = props => {
  const { dataStores: { createTripStore } } = useStores();
  const { requestedTrip, requestCounter } = createTripStore;

  const handleHide = () => {
    createTripStore.resetRequestedTrip();
  };

  const resetInterval = () => createTripStore.resetRequestInterval();
  if (!requestedTrip) return null;
  switch (requestedTrip.tripStatus) {
    case 'request':
    case 'REQUESTED':
      return <RequestingComponent
        hasSpinner={true}
        requestCounter={requestCounter}
        status={requestedTrip.tripStatus}
        resetInterval={resetInterval}
      />;
    case 'arriving':
    case 'tripOnTrip':
    case 'arrived':
      resetInterval();
      return <DriverArriving
        trip={toJS(requestedTrip)}
        cancelLastTrip={() => createTripStore.cancelLastTrip()}
        onHide={handleHide}
      />;
    case 'noNearByDriver':
      resetInterval();
      return <NoNearByDriver
        onHide={handleHide}
      />;
    case 'booking':
    case 'booked':
      resetInterval();
      return <PreBookingSuccess
        onHide={handleHide}
      />
    default:
      resetInterval();
      return <RequestingComponent
        hasSpinner={false}
        resetInterval={() => {}}
        status={requestedTrip.tripStatus}
        requestCounter={requestCounter}
      />;
  }
};

export default observer(RequestStatusTool);
