import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CategoryItem from './categoryItem';

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 8px 0 16px;
`;

const CarCategoriesSelector = props => {
  const { categories, fareData, activeCategory, fixedFare } = props;

  return (
    <CategoriesContainer>
      {categories.map(cat => <CategoryItem
        key={cat.name}
        active={cat.name === activeCategory}
        categoryData={cat}
        distance={fareData?.distance}
        fixedFare={fixedFare}
        fare={fareData?.cost && fareData.cost[cat.name] ? fareData.cost[cat.name] : null}
        onSelect={() => props.onSelect(cat._id)}
      />)}
    </CategoriesContainer>
  );
};

CarCategoriesSelector.propTypes = {
  categories: PropTypes.array.isRequired,
  fareData: PropTypes.object,
  activeCategory: PropTypes.string,
  fixedFare: PropTypes.number,
  onSelect: PropTypes.func,
};

export default CarCategoriesSelector;
