import { observer } from 'mobx-react-lite';
import React  from 'react';
import { Route, Switch } from 'react-router-dom';
import FullContentSpinner from '../../custom-ui/contentSpinner';
import NotFound from '../common/404/404';
import FoodMenu from './menu';
import FoodOrders from './orders';
import RestaurantSettings from './restaurant-settings';

const FoodRoutes = props => {
  return (
    <Switch>
      <Route exact path={'/food/orders'} render={() => <FoodOrders />} />
      <Route exact path={'/food/menu'} render={() => <FoodMenu />} />
      <Route exact path={'/food/restaurant-settings'} render={() => <RestaurantSettings />} />
      <Route render={() => <NotFound />} />
      <FullContentSpinner />
    </Switch>
  );
};

export default observer(FoodRoutes);
