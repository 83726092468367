import React, { Fragment, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import { playDing } from './components/elements/dingSound';

import PrivateRoute from './components/HOCs/PrivateRoute';
import NotFound from './components/routes/common/404/404';
import Login from './components/routes/common/login';
import PasswordRecovery from './components/routes/common/recovery';
import FoodRoutes from './components/routes/food';
import PortalRoutes from './components/routes/portal';
import config from './services/apiConfig';
import notify, { NotificationTypes } from './services/notifier';
import { initSocket } from './services/socketService';
import { useStores } from './stores/helpers/use-stores';

const redirectToPath = (path) => () => {
  return <Redirect to={path} push/>;
}

const loader = new Loader({
  apiKey: config.googleMapsApiKey,
  libraries: ['places', 'visualization']
});

const App = (props) => {
  const stores = useStores();
  const {
          dataStores: {
            authStore,
            foodOrdersStore
          },
          uiStore: {
            globalView
          }
        } = stores;
  const { isLoggedIn, lang, isSynchronized, isRestaurant, token } = authStore;
  const { orderUpdates, getOrdersUpdates } = foodOrdersStore;
  const {
          defaultRedirectRoute,
          redirectRoute,
          isSynchronized: isGlobalViewSynced,
          googleApiLoaded,
          pageTitle
        } = globalView;
  const { i18n } = useTranslation();
  const pollInterval = useRef(null);
  const blinkInterval = useRef(null);
  const fallBackTitle = useRef(null)

  const clearBlinkInterval = () => {
    if (blinkInterval.current) {
      clearInterval(blinkInterval.current);
      document.title = fallBackTitle.current;
    }
  }

  useEffect(() => {
    if (!globalView.isSynchronized || globalView.googleApiLoaded) return;
    loader
      .load()
      .then(() => {
        globalView.setGoogleLibraryLoaded();
      })
      .catch(e => {
        notify(e.message, NotificationTypes.error);
      });
  }, [globalView, isGlobalViewSynced, googleApiLoaded]);

  useEffect(() => {
    initSocket(stores.dataStores);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (isSynchronized) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, isSynchronized]);

  useEffect(() => {
    if (!isRestaurant) return;
    if (pollInterval.current) {
      clearInterval(pollInterval.current);
    }
    getOrdersUpdates();
    pollInterval.current = setInterval(() => getOrdersUpdates(), 15 * 1000);
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    }
  }, [isRestaurant, getOrdersUpdates]);

  useEffect(() => {
    if (orderUpdates?.urgent?.new) {
      playDing();
      clearBlinkInterval();
      blinkInterval.current = setInterval(() => {
        const title = document.title !== 'Neue Bestellung!' ? 'Neue Bestellung!' : fallBackTitle.current;
        document.title = title;
        globalView.setPageTitle(title);
      }, 1000);
    } else {
      clearBlinkInterval();
    }
    return () => {
      clearBlinkInterval();
    }
    // eslint-disable-next-line
  }, [orderUpdates]);

  useEffect(() => {
    if (pageTitle !== 'Neue Bestellung!') {
      fallBackTitle.current = pageTitle;
    }
  }, [pageTitle]);

  return (
    <Fragment>
      <Switch>
        <Route exact path={'/'} render={redirectToPath(redirectRoute || defaultRedirectRoute)}/>
        <Route path={'/login'} render={() => <Login/>}/>
        <Route path={'/recovery'} render={() => <PasswordRecovery/>}/>
        <PrivateRoute
          path={'/portal'}
          isAuthStoreSynced={isSynchronized}
          authed={isLoggedIn}
          render={() => <PortalRoutes/>}
        />
        <PrivateRoute
          path={'/food'}
          isAuthStoreSynced={isSynchronized}
          authed={isLoggedIn && !!isRestaurant}
          render={() => <FoodRoutes/>}
        />
        <Route path={'*'} render={() => <NotFound/>}/>
      </Switch>
    </Fragment>
  );
};

export default observer(App);

