import styled from 'styled-components';
import commonStyle from '../../../custom-ui/commonStyle';
import { Button } from 'react-bootstrap';


export const OperatingContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  flex-wrap: wrap;
  @media (max-width: 468px) {
    padding: 24px 8px;
  }
`;

export const RestaurantName = styled.h4.attrs(() => ({}))`
  font-size: 24px;
  font-weight: 700;
  color: ${commonStyle.colors.brandPrimary};
  margin: 8px 0;
`;

export const OperatingTogglerContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RestLegend = styled.p.attrs(() => ({}))`
  margin: 0 8px;
`;

export const WorkingHoursContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 40px;
  @media (max-width: 648px) {
    padding: 0 8px;
    font-size: 12px;
  }
`;

export const WorkingHoursTitle = styled.h5.attrs(() => ({}))`
  font-weight: 700;
  margin: 0;
  font-size: 14px;
  padding: 16px 20px;
`;

export const DaysMainContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const DayRow = styled.div.attrs(() => ({}))`
  min-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  width: 100%;

  &:nth-child(odd) {
    background-color: ${commonStyle.colors.white};
  }

  &:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: ${commonStyle.colors.greyMedium};
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: ${commonStyle.colors.greyMedium};
  }
`;

export const OffsetCol = styled.div.attrs(() => ({}))`
  flex: 1;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const DayContainer = styled.div.attrs(() => ({}))`
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
`;

export const DayStr = styled.p.attrs(() => ({}))`
  font-weight: 700;
  margin: 0;
`;

export const TimeCol = styled.div.attrs(() => ({}))`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.active ? commonStyle.colors.brandPrimary : commonStyle.colors.greyMedium};
  font-weight: ${props => props.bold ? 700 : 400};
  margin: 0 4px;
`;

export const TogglerContainer = styled.div.attrs(() => ({}))`
  margin-right: 8px;
`;

export const DataFailure = styled.p.attrs(() => ({}))`
  font-weight: 600;
  color: ${commonStyle.colors.brandDanger};
  padding: 16px 20px;
`;

export const SubmitButton = styled(Button).attrs(() => ({
}))`
  margin: 20px;
  padding: 6px 32px;
  border-radius: 4px;
  background-color: ${commonStyle.colors.brandFood};
  color: ${commonStyle.colors.white};
  border: 1px solid ${commonStyle.colors.brandFood};
  font-weight: 700;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
  &:hover {
    background-color: ${props => props.disabled ? 'transparent' : commonStyle.colors.darken(commonStyle.colors.brandFood, 0.1)};
    color: ${props => props.disabled ? commonStyle.colors.brandFood : commonStyle.colors.white};
    border: 1px solid ${props => props.disabled ? commonStyle.colors.brandFood : commonStyle.colors.darken(commonStyle.colors.brandFood, 0.1)};
  }
  &:disabled {
    background-color: ${commonStyle.colors.white};
    color: ${commonStyle.colors.brandFood};
    border: 1px solid ${commonStyle.colors.brandFood};
  }
  &:focus {
    background-color: ${commonStyle.colors.brandFood};
    color: ${commonStyle.colors.white};
    border: 1px solid ${commonStyle.colors.brandFood};
  }
  &:not(:disabled):not(.disabled):active {
    background-color: ${commonStyle.colors.darken(commonStyle.colors.brandFood, 0.2)};
    color: ${commonStyle.colors.white};
    border: 1px solid ${commonStyle.colors.darken(commonStyle.colors.brandFood, 0.2)};
  }
`;
