import { observer } from 'mobx-react-lite';
import React  from 'react';
import { Route, Switch } from 'react-router-dom';
import FullContentSpinner from '../../custom-ui/contentSpinner';
import NotFound from '../common/404/404';
import AccountSettings from './accountSettings';
import CreateTrip from './createTrip';
import MyTrips from './myTrips';

const PortalRoutes = props => {
  return (
    <Switch>
      <Route exact path={'/portal/create-trip'} render={() => <CreateTrip />} />
      <Route exact path={'/portal/trips'} render={() => <MyTrips />} />
      <Route exact path={'/portal/settings'} render={() => <AccountSettings />} />
      <Route render={() => <NotFound />} />
      <FullContentSpinner />
    </Switch>
  );
};

export default observer(PortalRoutes);
