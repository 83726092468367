import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAddressStringFromAddressObject } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import StyledInput from '../../../../custom-ui/styledInput';
import UserIcon from '../../../../../assets/icons/profile.svg';
import PlusIcon from '../../../../../assets/icons/plus_icon.svg';
import EmailIcon from '../../../../../assets/icons/emailIcon.svg';
import PhoneIcon from '../../../../../assets/icons/phone-icon.svg';
import LocationIcon from '../../../../../assets/icons/locationIcon.svg';
import StyledPlacesInput from '../../../../custom-ui/styledPlacesInput';
import LogoInputs from './logoInputs';
import * as S from '../styles';
import RestaurantTagsInput from './restaurantTagsInput';

const FormInputs = props => {
  const { t } = useTranslation();
  const { dataStores: { authStore, accountSettings } } = useStores();
  const { isRestaurant } = authStore;
  const { currentUserData } = accountSettings;

  const handleAdditionalValueChange = (value, index, key) => {
    let currentData = _.cloneDeep(currentUserData[key]);
    if (value === null) {
      currentData = currentData.filter((m, i) => i !== index);
    } else {
      currentData[index] = value;
    }
    accountSettings.changeCurrentData({ [key]: currentData });
  };

  const handleAddAdditionalValue = (key) => {
    const currentData = _.cloneDeep(currentUserData[key]) ?? [];
    currentData.push('');
    accountSettings.changeCurrentData({ [key]: currentData });
  }

  const handleCompanyAddressChange = (addressData) => {
    const companyUpdateObj = {
      address: formatAddressStringFromAddressObject(addressData),
      city: addressData.city,
      state: addressData.region,
      country: addressData.country,
      gpsLoc: addressData.gpsLoc,
      fullAddress: addressData.full_address,
    };
    accountSettings.changeCurrentData(companyUpdateObj);
  }

  return (
    <>
      <StyledInput
        label={t(isRestaurant ? langKeys.settings.restaurantName : langKeys.settings.firstName)}
        value={currentUserData.fname}
        onChange={value => accountSettings.changeCurrentData({ fname: value })}
        resetValue={''}
        prependIcon={<UserIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
      />
      {!isRestaurant && <StyledInput
        label={t(langKeys.settings.lastName)}
        value={currentUserData.lname}
        onChange={value => accountSettings.changeCurrentData({ lname: value })}
        resetValue={''}
        prependIcon={<UserIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
      />}
      {isRestaurant &&
      <RestaurantTagsInput
        restaurant={currentUserData}
        onSelect={values => accountSettings.changeCurrentData({
          restaurantData: {
            ...currentUserData.restaurantData,
            tags: values.map(v => v.value)
          }
        })
        }
      />}
      <LogoInputs
        isRestaurant={isRestaurant}
        onChange={data => accountSettings.changeCurrentData(data)}
      />
      <StyledInput
        label={t(langKeys.settings.generalEmail)}
        value={currentUserData.email}
        prependIcon={<EmailIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={false}
        disabled={true}
      />
      {currentUserData.additionalEmails &&
      !!currentUserData.additionalEmails.length &&
      currentUserData.additionalEmails.map((email, index) => <StyledInput
        key={`email-${index}`}
        label={t(langKeys.settings.optionalEmail)}
        prependIcon={<EmailIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
        value={email}
        onChange={mail => handleAdditionalValueChange(mail, index, 'additionalEmails')}
        resetValue={null}
      />)
      }
      <S.AddButton
        onClick={() => handleAddAdditionalValue('additionalEmails')}
      >
        <PlusIcon style={{ marginRight: 8 }}/> {t(langKeys.settings.addEmail)}
      </S.AddButton>
      <StyledInput
        label={t(langKeys.settings.generalPhoneNo)}
        value={currentUserData.phoneNo}
        prependIcon={<PhoneIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
        resetValue={''}
        onChange={phoneNo => accountSettings.changeCurrentData({ phoneNo })}
      />
      {currentUserData.additionalPhones &&
      !!currentUserData.additionalPhones.length &&
      currentUserData.additionalPhones.map((phoneNo, index) => <StyledInput
        key={`phone-${index}`}
        label={t(langKeys.settings.optionalPhoneNo)}
        prependIcon={<PhoneIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
        value={phoneNo}
        onChange={phone => handleAdditionalValueChange(phone, index, 'additionalPhones')}
        resetValue={null}
      />)
      }
      <S.AddButton
        onClick={() => handleAddAdditionalValue('additionalPhones')}
      >
        <PlusIcon style={{ marginRight: 8 }}/> {t(langKeys.settings.addPhoneNo)}
      </S.AddButton>
      <StyledPlacesInput
        prependIcon={<LocationIcon fill={commonStyle.colors.brandPrimary}/>}
        label={t(langKeys.settings.companyAddress)}
        address={currentUserData.address}
        onChange={address => accountSettings.changeCurrentData({ address })}
        onSelect={handleCompanyAddressChange}
        isClearable={false}
      />
      {currentUserData.pickups &&
      !!currentUserData.pickups.length &&
      currentUserData.pickups.map((pickup, index) => <StyledPlacesInput
        key={`address-${index}`}
        label={t(langKeys.settings.optionalAddresses)}
        prependIcon={<LocationIcon fill={commonStyle.colors.brandPrimary}/>}
        isClearable={true}
        address={pickup}
        onClear={() => handleAdditionalValueChange(null, index, 'pickups')}
        onChange={address => handleAdditionalValueChange(address, index, 'pickups')}
        onSelect={data => handleAdditionalValueChange(formatAddressStringFromAddressObject(data), index, 'pickups')}
      />)}
      <S.AddButton
        onClick={() => handleAddAdditionalValue('pickups')}
      >
        <PlusIcon style={{ marginRight: 8 }}/> {t(langKeys.settings.addAddress)}
      </S.AddButton>
    </>
  );
};

export default observer(FormInputs);
