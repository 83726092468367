import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AiOutlineFrown } from 'react-icons/ai';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import commonStyle from '../../../custom-ui/commonStyle';
import { UnAuthCenteredContainer, UnAuthLayoutWrapper } from '../../../custom-ui/un-auth-style';

const SadIcon = styled(AiOutlineFrown)`
  font-size: 58px;
`;

const TextWrapper = styled.div`
  font-size: ${props => props.title ? 36 : 28}px;
  font-weight: ${props => props.title ? 800 : 600};
  text-align: center;
  color: ${commonStyle.colors.brandPrimary};
  @media (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const NotFound = props => {
  const { t } = useTranslation();
  useTitle('Not Found');
  return (
    <UnAuthLayoutWrapper>
      <UnAuthCenteredContainer>
        <SadIcon />
        <TextWrapper title>{t(langKeys.notMatched.title)}</TextWrapper>
        <TextWrapper>{t(langKeys.notMatched.description)}</TextWrapper>
      </UnAuthCenteredContainer>
    </UnAuthLayoutWrapper>
  );
};

export default NotFound;
