import { observable, action, makeAutoObservable, computed, runInAction } from 'mobx';
import { clearPersist, isSynchronized, startPersist, StorageAdapter } from 'mobx-persist-store';
import FoodOrderTabs from '../../../constants/foodOrderTabs';
import downloadBinary from '../../../helpers/file';
import notify, { NotificationTypes } from '../../../services/notifier';
import { readStore, writeStore } from '../auth';
import * as FoodOrderService from '../../../services/foodOrdersService';

export const foodOrdersPersistOptions = {
  name: 'FoodOrders',
  properties: [
    'currentTab',
    'orderList',
    'ordersMeta',
    'displayMeta'
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 0,
  }
};

export default class FoodOrders {
  @observable currentTab = FoodOrderTabs.urgent;
  @observable orderList = [];
  @observable displayMeta = {
    sortBy: 'deliveryDate',
    isDescending: true,
    pageNo: 1,
    limit: 9,
    dateRange: null
  };
  @observable ordersMeta = null;
  @observable orderUpdates = {
    [FoodOrderTabs.urgent]: {
      total: 0,
      new: 0
    },
    [FoodOrderTabs.future]: {
      total: 0,
      new: 0
    },
  };
  @observable modalData = {
    isVisible: false,
    title: '',
    type: null,
    onConfirm: () => {}
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.startPersist();
    this.rootStore = rootStore;
  }

  @action setActiveTab = tab => {
    this.currentTab = tab;
    this.orderList = [];
    this.ordersMeta = null;
    this.updateDisplayMeta({ ...this.displayMeta, pageNo: 1 });
  }

  @action updateDisplayMeta = (meta) => {
    this.displayMeta = { ...this.displayMeta, ...meta };
  }

  @action getOrders = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await FoodOrderService.getFoodOrdersList(
        this.rootStore.dataStores.authStore.token,
        {
          status: this.currentTab,
          ...this.displayMeta
        });
      if (resp.success) {
        runInAction(() => {
          this.orderList = resp.data;
          this.ordersMeta = resp.meta;
        });
      } else {
        notify(resp.message, NotificationTypes.error);
      }
      this.rootStore.uiStore.globalView.setLoaded();
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action setOrderAsConfirmed = async (orderId) => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await FoodOrderService.setOrderAsConfirmed(this.rootStore.dataStores.authStore.token, orderId);
      if (resp.success) {
        this.getOrders();
        this.getOrdersUpdates();
      } else {
        notify(resp.message, NotificationTypes.error);
        this.rootStore.uiStore.globalView.setLoaded();
      }
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action exportCompletedOrders = async ({ startRange, endRange }) => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const resp = await FoodOrderService.exportOrders(this.rootStore.dataStores.authStore.token, startRange, endRange);
      this.rootStore.uiStore.globalView.setLoaded();
      if (resp.message) {
        notify(resp.message, NotificationTypes.error);
      } else {
        downloadBinary(resp, 'completed-orders.xlsx');
      }
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action showFoodModal = ({ title, type, onConfirm }) => {
    this.modalData = {
      title,
      type,
      onConfirm,
      isVisible: true,
    }
  }

  @action hideFoodModal = () => {
    this.modalData = {
      isVisible: false,
      title: '',
      type: null,
      onConfirm: () => {}
    }
  }

  @action getOrdersUpdates = async () => {
    const resp = await FoodOrderService.getOrdersUpdates(this.rootStore.dataStores.authStore.token);
    if (resp.success) {
      runInAction(() => {
        this.orderUpdates = resp.data;
      });
    }
  }

  startPersist = async () => {
    startPersist(this);
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
}
