import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import commonStyle from '../../../custom-ui/commonStyle';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
`;

export const TitleContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-right: 15px;
`;

export const Binded = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h4`
  font-weight: 700;
  padding-left: 15px;
  margin: 8px 0 16px;
`;

export const TripsContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  padding: 10px 0;
  border-radius: 15px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
  background-color: ${commonStyle.colors.white};
  min-height: 100px;
  z-index: 2;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  background-color: ${commonStyle.colors.white};
  margin-top: -16px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 32px 16px 16px;
`;

export const TripGeneralContainer = styled.div.attrs(() => ({
}))`
  width: 90%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 16px;
`;

export const TripHeaderRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
`;

export const TripNo = styled.h5.attrs(() => ({}))`
  color: ${commonStyle.colors.brandPrimary};
  font-size: 14px;
  font-weight: bolder;
  margin: 0;
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const CategoryName = styled.p.attrs(() => ({}))`
  color: ${commonStyle.colors.brandPrimary};
  margin: 0;
  font-size: 14px;
  padding-bottom: 5px;
`;

export const CategoryLabel = styled.img.attrs(() => ({}))`
  max-height: 18px;
  margin-left: 8px;
`;

export const ControlButtonsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 90px;
  height: 40px;
`;

export const SButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  padding: 0;
  margin-left: 5px;
`;

export const TripDataContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
`;

export const TripDataRow = styled.div.attrs(() => ({}))`
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  width: 100%;
  &:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:nth-child(even) {
    background-color: ${commonStyle.colors.greyLight};
  }
`;

export const TripDataIconContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const TripDataValueContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex: 9;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const ButtonWPadding = styled(Button).attrs(() => ({}))`
`;
