import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTitle } from '../../../../hooks/useTitle';
import langKeys from '../../../../i18n/lang/keys';
import { useStores } from '../../../../stores/helpers/use-stores';
import AlertPanel from '../../../custom-ui/alertPanel';
import commonStyle from '../../../custom-ui/commonStyle';
import FullContentSpinner from '../../../custom-ui/contentSpinner';
import { UnAuthCenteredContainer, UnAuthLayoutWrapper } from '../../../custom-ui/un-auth-style';

import logo from '../../../../assets/images/cabdo_logo.png';
import LanguageSelector from './languageSelector';
import LoginForm from './loginForm';

export const Logo = styled.img`
  height: 160px;
  width: 160px;
  border-radius: 80px;
  margin-bottom: 18px;
`;

export const Header = styled.h1`
  font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-size: 32px;
  margin-bottom: 25px;
`;

export const ExtrasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 16px;
  & a {
    font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: ${commonStyle.colors.brandPrimary};
    text-decoration: none;
    font-size: 14px;
  }
`;

const Login = props => {
  const { t } = useTranslation();
  const history = useHistory();
  useTitle('Cabdo Login');
  const {
          dataStores: {
            authStore
          }
        } = useStores();

  const { authError, fetchMessage, isLoggedIn } = authStore;

  useEffect(() => {
    authStore.setupUser();
    return () => {
      authStore.unSetLoading();
    }
  }, [authStore]);

  useEffect(() => {
    if (isLoggedIn) {
      return history.push('/');
    }
  }, [isLoggedIn, history]);

  const handleSubmit = (e, data) => {
    e.preventDefault();
    authStore.login(data);
  };

  return (
    <UnAuthLayoutWrapper>
      <UnAuthCenteredContainer>
        <Logo src={logo} alt={'CABDO Logo'}/>
        <Header>CABDO Portal</Header>
        <AlertPanel isSuccess={!authError} message={fetchMessage} />
        <LoginForm onSubmit={handleSubmit}/>
        <ExtrasContainer>
          <Link to={'/recovery'}>{t(langKeys.login.forgotPass)}</Link>
          <a href={'https://www.cabdo.de/impressum'}>{t(langKeys.login.imprint)}</a>
        </ExtrasContainer>
        <LanguageSelector theme={'dark'} />
        <FullContentSpinner />
      </UnAuthCenteredContainer>
    </UnAuthLayoutWrapper>
  );
};

export default observer(Login);
