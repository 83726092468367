import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import StyledImageInput from '../../../../custom-ui/imageInput';
import * as S from '../styles';
import CorporatePreviewCard from './corporatePreviewCard';
import RestaurantPreviewCard from './restaurantPreviewCard';

const LogoInputs = props => {
  const { t } = useTranslation();
  const { isRestaurant } = props;

  return (
    <S.LogoInputsContainer>
      <StyledImageInput
        label={t(langKeys.settings.addImage)}
        onChange={(logo) => props.onChange({ logo })}
      />
      {isRestaurant && <RestaurantPreviewCard />}
      {!isRestaurant && <CorporatePreviewCard />}
    </S.LogoInputsContainer>
  );
};

LogoInputs.propTypes = {
  isRestaurant: PropTypes.bool,
  onChange: PropTypes.func,
};

export default LogoInputs;
