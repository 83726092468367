import styled from 'styled-components';
import commonStyle from '../../../../custom-ui/commonStyle';

export const FooterContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const BodyContainer = styled.div.attrs(() => ({}))`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 8px;
`;

export const ItemContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const PriceChangeNotice = styled.div.attrs(() => ({}))`
  width: 100%;
  text-align: center;
  color: ${commonStyle.colors.brandDanger};
  margin-top: 8px;
`;

