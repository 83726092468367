import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import commonStyle from '../../custom-ui/commonStyle';

const LinkContainer = styled(NavLink)`
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    background-color: ${commonStyle.colors.darken(commonStyle.colors.brandPrimary, 0.7)};
    text-decoration: none;
  }
  & > div > svg {
    fill: ${commonStyle.colors.white};
  }
  &.active-nav {
    background-color: ${commonStyle.colors.background};
  }
  &.active-nav > .nav-text {
    color: ${commonStyle.colors.brandPrimary};
  }
  &.active-nav > div > svg {
    fill: ${commonStyle.colors.brandPrimary};
  }
  &.blink {
    background-color: ${commonStyle.colors.brandSuccess};
  }
  &.blink > .nav-text {
    color: ${commonStyle.colors.white};
  }
  &.blink > div > svg {
    fill: ${commonStyle.colors.white};
  }
`;

const IconContainer = styled.div`
  width: 50px;
  font-size: 25px;
  color: ${commonStyle.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 150px;
  color: ${commonStyle.colors.background};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  background-color: ${props => props.background || 'transparent'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextToolTip = styled.div`
  position: fixed;
  left: 50px;
  height: 40px;
  font-family: 'Circe', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 150px;
  color: ${commonStyle.colors.background};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 6px;
  background-color: ${commonStyle.colors.brandPrimary};
`;

const LinkItem = props => {
  const { linkPath, icon, showText, linkText, canBlink, blinkTitle, currentTitle } = props;
  const [showToolTip, setShowToolTip] = useState(false);
  const [blinkClass, setBlinkClass] = useState('');

  const handleToolTip = (isShow = false) => {
    if (showText) return;
    setShowToolTip(isShow);
  };

  useEffect(() => {
    if (canBlink && currentTitle) {
      setBlinkClass(currentTitle === blinkTitle ? 'blink' : '');
    }
  }, [currentTitle, blinkTitle, canBlink]);

  return (
    <LinkContainer
      to={linkPath}
      activeClassName={'active-nav'}
      className={blinkClass}
    >
      <IconContainer
        onMouseEnter={() => handleToolTip(true)}
        onMouseLeave={() => handleToolTip()}
      >
        {icon}
      </IconContainer>
      {!!showText && <TextContainer className={'nav-text'}>
        {linkText}
      </TextContainer>}
      {showToolTip && <TextToolTip>
        {linkText}
      </TextToolTip>}
    </LinkContainer>
  );
};

LinkItem.propTypes = {
  linkPath: PropTypes.string,
  icon: PropTypes.element,
  showText: PropTypes.bool,
  linkText: PropTypes.string,
  canBlink: PropTypes.bool,
  blinkTitle: PropTypes.string,
  currentTitle: PropTypes.string,
};

LinkItem.defaultProps = {
  canBlink: false,
  blinkTitle: null,
  currentTitle: null,
};

export default LinkItem;
