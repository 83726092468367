import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import StyledModal from '../../../../custom-ui/styledModal';
import { CancelModalBody, CancelModalFooter } from './cancelModal';
import EditPBForm from './editPBForm';
import { useExportData } from './exportModal';

const ModalContainer = props => {
  const { isVisible, tripData, type } = props;
  const { t } = useTranslation();

  const exportData = useExportData(props.onConfirm, props.onHide);

  const modalData = useMemo(() => {
    const data = {};
    switch (type) {
      case 'cancel':
        data.bodyContent = <CancelModalBody tripNumber={tripData?.tripNumber || 0} />;
        data.footerContent = <CancelModalFooter onHide={props.onHide} onConfirm={props.onConfirm} />;
        break;
      case 'edit':
        data.title = t(langKeys.myRides.pbNo, { tripNumber: tripData?.tripNumber || '' });
        data.bodyContent = <EditPBForm initialTripData={tripData} onHide={props.onHide} onConfirm={props.onConfirm} />;
        break;
      case 'export':
      default:
        data.title = exportData.title;
        data.bodyContent = exportData.bodyContent;
        data.footerContent = exportData.footerContent;
        break;
    }
    return data;
  }, [tripData, type, props.onConfirm, props.onHide, t, exportData]);

  return (
    <StyledModal
      isVisible={isVisible}
      onHide={props.onHide}
      {...modalData}
    />
  );
};

ModalContainer.propTypes = {
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  type: PropTypes.string,
  tripData: PropTypes.func,
};

export default ModalContainer;
