import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyle from '../../custom-ui/commonStyle';
import bgImage from '../../../assets/images/mainBackgroundImage.png';
import BottomLinks from './bottomTools';
import CollapseButton from './collapseButton';
import LinkList from './linkList';

const breakPoints = [50, 200];

const SideBarContainer = styled.aside`
  top: 70px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  left: 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  position: fixed;
  @media (max-width: 768px) {
    background-color: ${commonStyle.colors.brandPrimary};
    background-image: url(${bgImage});
    background-size: cover;
    left: ${props => props.isHidden ? -breakPoints[1] : 0}px;
    width: 200px;
  }
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  width: ${props => props.width}px;
  @media (max-height: 500px) {
    background-color: ${commonStyle.colors.brandPrimary};
  }
`;

const Sidebar = props => {
  const [height, setHeight] = useState(100);
  const [userForced, setUserForced] = useState(false);
  const {
          uiStore: {
            globalView
          },
          dataStores: {
            authStore
          }
        } = useStores();

  const { windowDimensions, isSideBarHidden, sidebarWidth } = globalView;
  const { isRestaurant } = authStore;
  useEffect(() => {
    setHeight(windowDimensions.height - 70);
  }, [windowDimensions.height]);

  const isExtended = useMemo(() => sidebarWidth === 200 || windowDimensions.width < 768, [windowDimensions.width, sidebarWidth]);

  useEffect(() => {
    if (windowDimensions.width > 768 && windowDimensions.width < 1200 && !userForced) {
      globalView.collapseSideBar();
    } else if (!userForced) {
      globalView.expandSideBar();
    }
  }, [windowDimensions.width, globalView, userForced]);


  const handleToggle = () => {
    if (windowDimensions.width < 768) {
      globalView.toggleSideBar(true);
    } else if (sidebarWidth === 50) {
      globalView.expandSideBar();
      setUserForced(true);
    } else {
      globalView.collapseSideBar();
      setUserForced(true);
    }
  }

  return (
    <SideBarContainer width={sidebarWidth} height={height} isHidden={isSideBarHidden}>
      <LinkList isCollapsed={!isExtended} isRestaurant={isRestaurant}/>
      <BottomContainer width={isExtended ? 200 : sidebarWidth}>
        {isExtended && <BottomLinks/>}
        <CollapseButton onToggle={handleToggle} fullWidth={isExtended} closeOnly={windowDimensions.width < 768}/>
      </BottomContainer>
    </SideBarContainer>
  );
};

export default observer(Sidebar);
