import _ from 'lodash';
import { action, makeAutoObservable, observable, computed, runInAction } from 'mobx';
import notify, { NotificationTypes } from '../../../services/notifier';
import * as AccountService from '../../../services/accountSettingsService';

export class SettingsStore {
  @observable initialUserData = null;
  @observable currentUserData = null;
  @observable availableRestaurantTags = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action setUserData = (data) => {
    this.initialUserData = data;
    this.currentUserData = _.cloneDeep(data);
  }

  @action changeCurrentData = data => {
    this.currentUserData = { ...this.currentUserData, ...data };
  }

  @computed isDataChanged = () => {
    return !_.isEqual(this.currentUserData, this.initialUserData);
  }

  @computed isDataValid = () => {
    return !!this.currentUserData.fname &&
      !!this.currentUserData.lname &&
      !!this.currentUserData.email &&
      !!this.currentUserData.phoneNo &&
      !!this.currentUserData.address &&
      !!this.currentUserData.gpsLoc?.length &&
      (!this.currentUserData.additionalEmails?.length || this.currentUserData.additionalEmails.every(Boolean)) &&
      (!this.currentUserData.additionalPhones?.length || this.currentUserData.additionalPhones.every(Boolean)) &&
      (!this.currentUserData.pickups?.length || this.currentUserData.pickups.every(Boolean));
  }

  @action updateAccountData = async () => {
    try {
      this.rootStore.uiStore.globalView.setLoading();
      const {
              fname,
              lname,
              email,
              logo,
              phoneNo,
              additionalEmails,
              additionalPhones,
              pickups,
              address,
              city,
              state,
              country,
              gpsLoc,
              fullAddress,
            } = this.currentUserData;
      const restaurantData = this.currentUserData.restaurantData ? {
        tags: this.currentUserData.restaurantData.tags
      } : null;
      const updateData = {
        fname,
        lname,
        email,
        logo,
        phoneNo,
        additionalEmails,
        additionalPhones,
        pickups,
        address,
        city,
        state,
        country,
        gpsLoc: [gpsLoc[1], gpsLoc[0]],
        fullAddress,
        restaurantData
      };
      const resp = await AccountService.updateAccountData(
        this.rootStore.dataStores.authStore.token,
        updateData
      );
      if (resp.success && resp.data) {
        this.setUserData(resp.data);
        this.rootStore.dataStores.authStore.setUserData(resp.data);
      } else {
        notify(resp.message, NotificationTypes.error);
      }
      this.rootStore.uiStore.globalView.setLoaded();
    } catch (e) {
      notify(e.message, NotificationTypes.error);
      this.rootStore.uiStore.globalView.setLoaded();
    }
  }

  @action getAvailableRestaurantTags = async () => {
    const resp = await AccountService.getAvailableRestaurantTags(
      this.rootStore.dataStores.authStore.token,
    );
    if (resp && resp.success) {
      runInAction(() => {
        this.availableRestaurantTags = resp.data;
      })
    }
  }
}
