import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from '../../../../../stores/helpers/use-stores';
// import commonStyle from '../../../../custom-ui/commonStyle';
// import Toggler from '../../../../custom-ui/toggler';
import * as S from '../styles';

const RestaurantOperatingControl = props => {
  const { dataStores: { restaurantSettings, authStore } } = useStores();

  const restaurantData = useMemo(() => toJS(restaurantSettings.restaurantData), [restaurantSettings.restaurantData]);

  if (!restaurantData) return null;

  // const toggleRestaurantOperating = () => {
  //   restaurantSettings.updateRestaurantData({ isOperating: !restaurantData.isOperating });
  // }

  return (
    <S.OperatingContainer>
      <S.RestaurantName>{authStore?.userData?.fname}</S.RestaurantName>
      {/*// Temporally disabled */}
      {/*<S.OperatingTogglerContainer>*/}
      {/*  <S.RestLegend>{!!restaurantData?.isOperating ? 'Online' : 'Offline'}</S.RestLegend>*/}
        {/*<Toggler*/}
        {/*  isEnabled={restaurantData.isOperating}*/}
        {/*  onChange={toggleRestaurantOperating}*/}
        {/*  height={30}*/}
        {/*  width={60}*/}
        {/*  disabledBallColor={commonStyle.colors.brandDanger}*/}
        {/*  disabledColor={commonStyle.colors.brandDanger}*/}
        {/*  enabledColor={commonStyle.colors.brandSuccess}*/}
        {/*  disabledBGColor={'transparent'}*/}
        {/*  invertedBallColor={commonStyle.colors.white}*/}
        {/*/>*/}
      {/*</S.OperatingTogglerContainer>*/}
    </S.OperatingContainer>
  );
};

export default observer(RestaurantOperatingControl);
