import React from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  height: 40px;
  margin: 8px 0;
`;

const StyledCheckbox = props => {
  const { value, label } = props;
  return (
    <CheckboxContainer>
      <FormCheck
        type={'checkbox'}
        label={label}
        checked={value}
        onChange={props.onChange}
      />
    </CheckboxContainer>
  );
};

StyledCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default StyledCheckbox;
