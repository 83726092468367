import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { defineDishType, dishTypes } from '../../../../../constants/dishTypes';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import StyledInput from '../../../../custom-ui/styledInput';
import EuroIcon from '../../../../../assets/icons/euro-icon.svg';
import * as S from './styles';

const PriceTool = props => {
  const { t } = useTranslation();
  const { dataStores: { foodMenuStore } } = useStores();
  const { editedDish } = foodMenuStore;

  const dishType = useMemo(() => defineDishType(editedDish), [editedDish]);

  return (
    <>
      {![dishTypes.multiSize, dishTypes.looseSize].includes(dishType) && <StyledInput
        value={editedDish.price}
        label={t(langKeys.foodMenu.dishPrice)}
        onChange={v => foodMenuStore.updateEditedDish({ price: +v })}
        inputProps={{ type: 'number', min: 0, step: 0.1 }}
        prependIcon={<EuroIcon fill={commonStyle.colors.brandPrimary} />}
      />}
      {[dishTypes.multiSize, dishTypes.looseSize].includes(dishType) && <S.PriceChangeNotice>{t(langKeys.foodMenu.priceNotice)}</S.PriceChangeNotice>}
    </>
  );
};

export default observer(PriceTool);
