const langKeys = {
  intro: 'intro',
  back: 'back',
  confirm: 'confirm',
  hide: 'hide',
  revert: 'revert',
  sidebar: {
    collapse: 'sidebar.collapse',
    close: 'sidebar.close',
  },
  navLinks: {
    createTrip: 'navLinks.createTrip',
    myTrips: 'navLinks.myTrips',
    foodOrders: 'navLinks.foodOrders',
    foodMenu: 'navLinks.foodMenu',
    openHours: 'navLinks.openHours',
    accountSettings: 'navLinks.accountSettings',
  },
  login: {
    email: 'login.email',
    password: 'login.password',
    login: 'login.login',
    logout: 'login.logout',
    forgotPass: 'login.forgotPass',
    imprint: 'login.imprint'
  },
  notMatched: {
    title: 'notMatched.title',
    description: 'notMatched.description',
  },
  passRestore: {
    title: 'passRestore.title',
    errorEmailInvalid: 'passRestore.errorEmailInvalid',
    sendMail: 'passRestore.sendMail',
  },
  paymentMethod: {
    cash: 'paymentMethod.cash',
    card: 'paymentMethod.card',
    paypal: 'paymentMethod.paypal',
    debit: 'paymentMethod.debit',
  },
  createTrip: {
    title: 'createTrip.title',
    tripNumber: 'createTrip.tripNumber',
    fname: 'createTrip.fname',
    lname: 'createTrip.lname',
    valid: 'createTrip.valid',
    favoriteAddresses: 'createTrip.favoriteAddresses',
    invalid: 'createTrip.invalid',
    pickupAddress: 'createTrip.pickupAddress',
    through: 'createTrip.through',
    destinationAddress: 'createTrip.destinationAddress',
    tripComment: 'createTrip.tripComment',
    phoneNo: 'createTrip.phoneNo',
    fixedFare: 'createTrip.fixedFare',
    preBookingLabel: 'createTrip.preBookingLabel',
    taxiType: 'createTrip.taxiType',
    taxiWillArriveIn: 'createTrip.taxiWillArriveIn',
    taxiWillNotArrive: 'createTrip.taxiWillNotArrive',
    submit: 'createTrip.submit',
    reset: 'createTrip.reset',
    preBooked: 'createTrip.preBooked',
    preBooking: 'createTrip.preBooking',
    rideNow: 'createTrip.rideNow',
    serviceCity: 'createTrip.serviceCity',
    withInvoice: 'createTrip.withInvoice',
    switchDirections: 'createTrip.switchDirections',
    addWp: 'createTrip.addWp',
    addDest: 'createTrip.addDest',
    pbTime: 'createTrip.pbTime',
    upTo: 'createTrip.upTo',
    numOfPassengers: 'createTrip.numOfPassengers',
    pricePrefix: 'createTrip.pricePrefix',
    fixed: 'createTrip.fixed',
    preBookingsLeftInfo: 'createTrip.preBookingsLeftInfo',
    taxiWillArrive: 'createTrip.taxiWillArrive',
    noCarsAvailable: 'createTrip.noCarsAvailable',
    errorMessages: {
      preBooking: 'createTrip.errorMessages.preBooking',
      cannotMakePrebookingNY: 'createTrip.errorMessages.cannotMakePrebookingNY',
    },
    tripRequestMessages: {
      requesting: 'createTrip.tripRequestMessages.requesting',
      noDrivers: 'createTrip.tripRequestMessages.noDrivers',
      success: 'createTrip.tripRequestMessages.success',
      bookingSuccess: 'createTrip.tripRequestMessages.bookingSuccess',
      auto: 'createTrip.tripRequestMessages.auto',
      plateNumber: 'createTrip.tripRequestMessages.plateNumber',
      driver: 'createTrip.tripRequestMessages.driver',
      arriveIn: 'createTrip.tripRequestMessages.arriveIn',
      tripOnTrip: 'createTrip.tripRequestMessages.tripOnTrip',
      cancel: 'createTrip.tripRequestMessages.cancel',
    }
  },
  myRides: {
    bookedRidesTitle: 'myRides.bookedRidesTitle',
    activeRidesTitle: 'myRides.activeRidesTitle',
    completedRidesTitle: 'myRides.completedRidesTitle',
    tripNo: 'myRides.tripNo',
    pbNo: 'myRides.pbNo',
    tripId: 'myRides.tripId',
    no: 'myRides.no',
    date: 'myRides.date',
    totalFare: 'myRides.totalFare',
    action: 'myRides.action',
    export: 'myRides.export',
    carDetails: 'myRides.carDetails',
    fromDate: 'myRides.fromDate',
    toDate: 'myRides.toDate',
    exportTrips: 'myRides.exportTrips',
    invoice: 'myRides.invoice',
    first: 'myRides.first',
    last: 'myRides.last',
    next: 'myRides.next',
    prev: 'myRides.prev',
    sureToRemove: 'myRides.sureToRemove',
    pbRemoveSuccess: 'myRides.pbRemoveSuccess',
    pbRemoveError: 'myRides.pbRemoveError',
    pbUpdateSuccess: 'myRides.pbUpdateSuccess',
    pbUpdateError: 'myRides.pbUpdateError',
    bookedAt: 'myRides.bookedAt',
    category: 'myRides.category',
    tripDetails: 'myRides.tripDetails',
    riderDetails: 'myRides.riderDetails',
  },
  foodOrders: {
    urgent: 'foodOrders.urgent',
    future: 'foodOrders.future',
    done: 'foodOrders.done',
    orderShort: 'foodOrders.orderShort',
    order: 'foodOrders.order',
    acceptOrder: 'foodOrders.acceptOrder',
    pickup: 'foodOrders.pickup',
    delivery: 'foodOrders.delivery',
    showMore: 'foodOrders.showMore',
    dishes: 'foodOrders.dishes',
    driver: 'foodOrders.driver',
    client: 'foodOrders.client',
    deliveryAddress: 'foodOrders.deliveryAddress',
    price: 'foodOrders.price',
    orderDate: 'foodOrders.orderDate',
    comment: 'foodOrders.comment',
    orderItems: 'foodOrders.orderItems',
    deliveryDate: 'foodOrders.deliveryDate',
    pickupDate: 'foodOrders.pickupDate',
    orderStatus: 'foodOrders.orderStatus',
    showOnMap: 'foodOrders.showOnMap',
    exportOrders: 'foodOrders.exportOrders',
    orderRating: 'foodOrders.orderRating',
    rating: 'foodOrders.rating',
    confirmOrder: 'foodOrders.confirmOrder',
    confirmPickup: 'foodOrders.confirmPickup',
    confirmDelivery: 'foodOrders.confirmDelivery',
    newOrder: 'foodOrders.newOrder',
    pickRange: 'foodOrders.pickRange',
    delivered: 'foodOrders.delivered',
  },
  foodMenu: {
    searchPlaceholder: 'foodMenu.searchPlaceholder',
    found: 'foodMenu.found',
    notFound: 'foodMenu.notFound',
    dishName: 'foodMenu.dishName',
    dishDesc: 'foodMenu.dishDesc',
    dishPrice: 'foodMenu.dishPrice',
    priceNotice: 'foodMenu.priceNotice',
  },
  restaurantWorkingHours: {
    lunch: 'restaurantWorkingHours.lunch',
    opening: 'restaurantWorkingHours.opening',
    closing: 'restaurantWorkingHours.closing',
    apply: 'restaurantWorkingHours.apply',
    weekDay: 'restaurantWorkingHours.weekDay',
    dataError: 'restaurantWorkingHours.dataError',
  },
  settings: {
    firstName: 'settings.firstName',
    lastName: 'settings.lastName',
    restaurantName: 'settings.restaurantName',
    restaurantDescription: 'settings.restaurantDescription',
    logo: 'settings.logo',
    addImage: 'settings.addImage',
    preview: 'settings.preview',
    generalEmail: 'settings.generalEmail',
    optionalEmail: 'settings.optionalEmail',
    addEmail: 'settings.addEmail',
    generalPhoneNo: 'settings.generalPhoneNo',
    optionalPhoneNo: 'settings.optionalPhoneNo',
    addPhoneNo: 'settings.addPhoneNo',
    companyAddress: 'settings.companyAddress',
    optionalAddresses: 'settings.optionalAddresses',
    addAddress: 'settings.addAddress',
    tags: 'settings.tags',
  }
};

export default langKeys;
