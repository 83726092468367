import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import commonStyle from '../../../custom-ui/commonStyle';

export const Container = styled.div.attrs(() => ({}))`
  padding: 40px;
  @media (max-width: 600px) {
    padding: 8px;
  }
`;

export const FormContainer = styled.div.attrs(() => ({}))`
  width: 615px;
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.2)};
  border-radius: 5px;
  padding: 16px;
  background-color: ${commonStyle.colors.white};
  @media (max-width: 780px) {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 615px;
  padding-left: 20px;
  margin-top: 30px;
  @media (max-width: 764px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    padding-left: 0;
    justify-content: space-between;
  }
`;

export const LogoInputsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 40px;

  @media (max-width: 520px) {
    justify-content: space-around;
  }
`;

export const PreviewContainer = styled.div.attrs(() => ({}))`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;


export const RemoveLogoContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${commonStyle.colors.white};
  background-color: ${commonStyle.colors.brandDanger};
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: 20px;
  right: -5px;
  font-size: 12px;
  &:hover {
    color: ${commonStyle.colors.brandDanger};
    background-color: ${commonStyle.colors.white};
  }
`;

export const CorpImg = styled.img.attrs(() => ({}))`
  height: 130px;
`;

export const RestaurantContainer = styled.div.attrs(() => ({}))`
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 340px;
  background-color: ${commonStyle.colors.brandPrimary};
  border-radius: 5px;
  border: 1px solid ${commonStyle.colors.white};
  overflow: hidden;
  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const RestaurantLogoContainer = styled.div.attrs(() => ({}))`
  height: 100%;
  width: 102.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-width: 1px;
  border-color: ${commonStyle.colors.white};
  overflow: hidden;
`;

export const RestaurantLogo = styled.img.attrs(() => ({}))`
  height: 100%;
`;

export const RestaurantContentContainer = styled.div.attrs(() => ({}))`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 4px 10px 10px 20px;
  width: ${340 - 96.5}px;
  @media (max-width: 350px) {
    width: 200px;
  }
`;

export const RestaurantHeaderRow = styled.div.attrs(() => ({}))`
  height: 30px;
  width: 100%;
  font-size: 16px;
  color: ${commonStyle.colors.brandFood};
  font-weight: 700;
`;

export const RestaurantDescription = styled.div.attrs(() => ({}))`
  height: 30px;
  font-size: 12px;
  width: 100%;
  color: ${commonStyle.colors.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const WHButton = styled.div.attrs(() => ({}))`
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  width: 30px;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: ${commonStyle.colors.white};
  background-color: ${commonStyle.colors.brandFoodAccent};
  border-bottom-left-radius: 5px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddButton = styled(Button).attrs(() => ({
  variant: 'outline-dark',
  size: 'sm'
}))`
  margin: 20px 0 40px;
  padding: 6px 24px;
  border-radius: 4px;
  color: ${commonStyle.colors.brandFood};
  background-color: transparent;
  border: 1px solid ${commonStyle.colors.brandFood};
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 30px;
  &:hover {
    background-color: ${props => props.disabled ? 'transparent' : commonStyle.colors.brandFood};
    color: ${props => props.disabled ? commonStyle.colors.brandFood : commonStyle.colors.white};
    border: 1px solid ${props => props.disabled ? commonStyle.colors.brandFood : commonStyle.colors.darken(commonStyle.colors.brandFood, 0.1)};
  }
  &:disabled {
    background-color: ${commonStyle.colors.white};
    color: ${commonStyle.colors.brandFood};
    border: 1px solid ${commonStyle.colors.brandFood};
    cursor: not-allowed;
  }
  &:focus {
    background-color: ${commonStyle.colors.brandFood};
    color: ${commonStyle.colors.white};
    border: 1px solid ${commonStyle.colors.brandFood};
  }
  &:not(:disabled):not(.disabled):active {
    background-color: ${commonStyle.colors.brandFood};
    color: ${commonStyle.colors.white};
    border: 1px solid ${commonStyle.colors.brandFood};
  }
  & > svg,
  &:disabled > svg {
    fill: ${commonStyle.colors.brandFood};
  }
  &:hover > svg {
    fill: ${props => props.disabled ? commonStyle.colors.brandFood : commonStyle.colors.white};
  }
  &:focus > svg,
  &:not(:disabled):not(.disabled):active > svg {
    fill: ${commonStyle.colors.white};
  }
`;
