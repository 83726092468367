import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { clearPersist, isSynchronized, startPersist, StorageAdapter } from 'mobx-persist-store';
import downloadBinary from '../../../helpers/file';
import langKeys from '../../../i18n/lang/keys';
import notify, { NotificationTypes } from '../../../services/notifier';
import { readStore, writeStore } from '../auth';
import * as MyRidesService from '../../../services/myRidesService';
import i18n from '../.././../i18n';

export const myRidesPersistOptions = {
  name: 'Trips',
  properties: [
    'bookedTrips',
    'activeTrips',
    'completedTrips',
    'bookedMeta',
    'activeMeta',
    'completedMeta',
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    delay: 0,
  }
};

export default class MyRides {
  @observable refreshFlag = false;

  @observable bookedTrips = [];
  @observable activeTrips = [];
  @observable completedTrips = [];

  @observable bookedMeta = null;
  @observable activeMeta = null;
  @observable completedMeta = null;

  @observable bookedLoading = false;
  @observable activeLoading = false;
  @observable completedLoading = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.startPersist();
  }

  @action toggleRefreshFlag = () => {
    this.refreshFlag = !this.refreshFlag;
  }

  @action getTrips = async (status, params) => {
    runInAction(() => {
      this[`${status}Loading`] = true;
    });
    const resp = await MyRidesService.fetchMyRides(this.rootStore.dataStores.authStore.token, {
      status,
      ...params
    });

    if (resp.success) {
      runInAction(() => {
        this[`${status}Trips`] = resp.data || [];
        this[`${status}Meta`] = resp.meta;
      });
    } else {
      notify(resp.message, NotificationTypes.error);
    }
    runInAction(() => {
      this[`${status}Loading`] = false;
    });
  }

  @action cancelPreBooking = async (tripId) => {
    const resp = await MyRidesService.cancelPreBooking(this.rootStore.dataStores.authStore.token, tripId);
    if (resp.success) {
      notify(i18n.t(langKeys.myRides.pbRemoveSuccess), NotificationTypes.success);
    } else {
      notify(i18n.t(langKeys.myRides.pbRemoveError, { message: resp.message }), NotificationTypes.error);
    }
  }

  @action updatePreBooking = async (tripId, tripData) => {
    const resp = await MyRidesService.updatePreBooking(this.rootStore.dataStores.authStore.token, tripId, tripData);
    if (resp.success) {
      notify(i18n.t(langKeys.myRides.pbUpdateSuccess), NotificationTypes.success);
    } else {
      notify(i18n.t(langKeys.myRides.pbUpdateError, { message: resp.message }), NotificationTypes.error);
    }
  }

  @action exportTrips = async (rangeData) => {
    const resp = await MyRidesService.exportTrips(this.rootStore.dataStores.authStore.token, rangeData);
    if (resp.message) {
      notify(resp.message, NotificationTypes.error);
    } else {
      downloadBinary(resp, 'completed-trips.xlsx');
    }
  }

  startPersist = async () => {
    startPersist(this)
  }

  clearStore = async () => {
    await clearPersist(this)
  }

  @computed get isSynchronized() {
    return isSynchronized(this)
  }
};
