import moment from 'moment';
import { callAPI } from './apiService';

export const getFoodOrdersList = async (token, params) => {
  const dateRangeQuery = params.dateRange ? `&dateFrom=${params.dateRange.startRange}&dateTo=${params.dateRange.endRange}` : '';
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/orders?pageNo=${params.pageNo || 1}&limit=${params.limit || 9}&status=${params.status}&sortBy=${params.sortBy}&desc=${params.isDescending ? '1' : '-1'}${dateRangeQuery}`,
  });
};

export const setOrderAsConfirmed = async (token, orderId) => {
  return callAPI({
    method: 'PATCH',
    authentication: token,
    endpoint: `api/corporate/food/order/${orderId}`,
  });
}

export const setOrderAsCompleted = async (token, orderId) => {
  return callAPI({
    method: 'PUT',
    authentication: token,
    endpoint: `api/corporate/food/order/${orderId}`,
  });
}

export const getOrderDetails = async (token, orderId) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/order/${orderId}`,
  });
}

export const exportOrders = async (token, fromDate, toDate) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    responseType: 'blob',
    endpoint: `api/corporate/food/orders/export?from=${moment(fromDate).toISOString()}&to=${moment(toDate).toISOString()}`,
  });
}

export const getOrdersUpdates = (token) => {
  return callAPI({
    method: 'GET',
    authentication: token,
    endpoint: `api/corporate/food/orders/updates`,
  });
};
