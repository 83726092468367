import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import langKeys from '../../../../../i18n/lang/keys';
import { useStores } from '../../../../../stores/helpers/use-stores';
import commonStyle from '../../../../custom-ui/commonStyle';
import { RestaurantContentContainer } from '../styles';
import ClockIcon from '../../../../../assets/icons/clock-icon_white.svg';
import * as S from '../styles';

const RestaurantPreviewCard = props => {
  const { dataStores: { accountSettings: { currentUserData } } } = useStores();
  const { t } = useTranslation();

  return (
    <S.PreviewContainer  style={{ backgroundColor: commonStyle.colors.brandPrimary, padding: 4, borderRadius: 5 }}>
      <FormLabel style={{ color: commonStyle.colors.white }}>{t(langKeys.settings.preview)}</FormLabel>
      <S.RestaurantContainer>
        <S.RestaurantLogoContainer>
          <S.RestaurantLogo src={currentUserData.logo} alt={'Restaurant logo'}/>
        </S.RestaurantLogoContainer>
        <RestaurantContentContainer>
          <S.WHButton>
            <ClockIcon />
          </S.WHButton>
          <S.RestaurantHeaderRow>
            {currentUserData.fname}
          </S.RestaurantHeaderRow>
          <S.RestaurantDescription>
            {!!currentUserData.restaurantData.tags?.length
              ? currentUserData.restaurantData.tags.join(' | ')
              : currentUserData.restaurantData.description}
          </S.RestaurantDescription>
        </RestaurantContentContainer>
      </S.RestaurantContainer>
    </S.PreviewContainer>
  );
};

export default observer(RestaurantPreviewCard);
