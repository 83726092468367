import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiFillCloseCircle } from 'react-icons/ai';
import EditIcon from '../../../../assets/icons/editIcon.svg'
import PickupIcon from '../../../../assets/icons/pickup.svg';
import WaypointIcon from '../../../../assets/icons/wp-icon.svg';
import DestinationIcon from '../../../../assets/icons/destination.svg';
import EuroIcon from '../../../../assets/icons/euro-icon.svg';
import DateIcon from '../../../../assets/icons/date.svg';
import CarIcon from '../../../../assets/icons/car-thin.svg';
import ClockIcon from '../../../../assets/icons/clock-icon_white.svg';
import PhoneIcon from '../../../../assets/icons/phone-icon.svg';
import DriverIcon from '../../../../assets/icons/driverIcon.svg';
import { getCategoryImage, isCategoryMetered } from '../../../../constants/carCategories';
import tripStatuses, { bookingStatuses, onGoingTripStatuses } from '../../../../constants/tripStatuses';
import { formatAddressString, formatPrice } from '../../../../helpers/utils';
import langKeys from '../../../../i18n/lang/keys';
import commonStyle from '../../../custom-ui/commonStyle';
import * as S from './styles';
import TripItemDataRow from './tripItemData';

const TripItem = props => {
  const { t } = useTranslation();
  const { trip } = props;
  const {
          tripNumber,
          taxiType,
          pickUpAddress,
          waypoints,
          destAddress,
          tripAmt,
          preBookedTime,
          withInvoice,
          driverId,
          tripStatus,
          carDetails,
          arrivalTime,
          bookingTime,
          endTime
        } = trip;
  const driver = driverId && driverId.fname ? driverId : null;

  const driverPhoneNo = useMemo(() => {
    if (!driverId || !driverId.phoneNo) {
      return '-----';
    }
    if (isCategoryMetered(taxiType)) {
      return driverId.phoneNo.trim();
    }
    if (!driverId.carDetails) return '------';
    return driverId.carDetails?.phoneNo?.trim();
  }, [driverId, taxiType]);

  const tripDate = useMemo(() => {
    const date = preBookedTime || bookingTime;
    let formattedDate = moment(date).format('DD MMM YY [um] HH:mm');
    if (tripStatus === tripStatuses.endTrip && endTime) {
      formattedDate += ` → ${moment(endTime).format('DD MMM YY [um] HH:mm')}`
    }
    return formattedDate;
  }, [preBookedTime, bookingTime, tripStatus, endTime]);

  return (
    <S.TripGeneralContainer>
      <S.TripHeaderRow>
        <S.TripNo>{t(langKeys.myRides.tripNo, { tripNumber })}</S.TripNo>
        <S.CategoryContainer>
          <S.CategoryName>{taxiType}</S.CategoryName>
          <S.CategoryLabel src={getCategoryImage(taxiType)}/>
        </S.CategoryContainer>
        {[tripStatuses.arriving, tripStatuses.arrived, ...bookingStatuses].includes(tripStatus) &&
        <S.ControlButtonsContainer>
          {bookingStatuses.includes(tripStatus) && <S.SButton
            variant={'outline-dark'}
            onClick={props.onEdit}
          >
            <EditIcon fill={commonStyle.colors.brandPrimary}/>
          </S.SButton>}
          <S.SButton
            variant={'outline-dark'}
            onClick={props.onCancel}
          >
            <AiFillCloseCircle fill={commonStyle.colors.brandDanger} size={22}/>
          </S.SButton>
        </S.ControlButtonsContainer>}
      </S.TripHeaderRow>
      <S.TripDataContainer>
        <TripItemDataRow
          tooltip={t(langKeys.createTrip.pickupAddress)}
          icon={<PickupIcon/>}
          value={formatAddressString(pickUpAddress)}
        />
        {waypoints.map((wp, i) =>
          <TripItemDataRow
            tooltip={t(langKeys.createTrip.through)}
            key={`${wp.address}-${i}`}
            icon={<WaypointIcon/>}
            value={formatAddressString(wp.address)}
          />)}
        <TripItemDataRow
          tooltip={t(langKeys.createTrip.destinationAddress)}
          icon={<DestinationIcon/>}
          value={formatAddressString(destAddress)}
        />
        {onGoingTripStatuses.includes(tripStatus) && !!carDetails &&
        <TripItemDataRow
          tooltip={t(langKeys.myRides.carDetails)}
          icon={<CarIcon/>}
          value={`${carDetails.licensePlateNumber} - ${carDetails.model} ${carDetails.make}`}
        />
        }
        {[tripStatuses.arriving].includes(tripStatus) &&
        <TripItemDataRow
          tooltip={t(langKeys.createTrip.tripRequestMessages.arriveIn)}
          icon={<ClockIcon/>}
          value={`${arrivalTime} min`}
        />
        }
        {onGoingTripStatuses.includes(tripStatus) &&
        <TripItemDataRow
          tooltip={t(langKeys.createTrip.phoneNo)}
          icon={<PhoneIcon/>}
          value={driverPhoneNo}
        />
        }
        {!!tripAmt && <TripItemDataRow
          tooltip={t(langKeys.myRides.totalFare)}
          icon={<EuroIcon/>}
          value={`${formatPrice(tripAmt, { addEuroSign: false })}${withInvoice ? ` - ${t(langKeys.createTrip.withInvoice)}` : ''}`}
        />}
        {!!driver && [...onGoingTripStatuses, ...bookingStatuses].includes(tripStatus) &&
        <TripItemDataRow
          tooltip={t(langKeys.createTrip.tripRequestMessages.driver)}
          icon={<DriverIcon/>}
          value={`${driver.fname} ${driver.lname}`}
        />
        }
        <TripItemDataRow
          tooltip={t(langKeys.myRides.date)}
          icon={<DateIcon/>}
          value={tripDate}
        />
      </S.TripDataContainer>
    </S.TripGeneralContainer>
  );
};

TripItem.propTypes = {
  trip: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default TripItem;
