import React from 'react';
import dingSound from '../../../assets/audio/ding.mp3';

export const playDing = () => {
  const audio = document.getElementById('ding-sound');
  if (audio) {
    audio.play();
  }
}

const DingSound = () => {
  return (
    <audio controls name={'media'} id={'ding-sound'} style={{ display: 'none' }}>
      <source src={dingSound} type={'audio/x-wav'}/>
    </audio>
  );
};

export default DingSound;
