import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import GoogleMap from 'google-map-react';
import PropTypes from 'prop-types';
import Polyline from '@mapbox/polyline';
import styled from 'styled-components';
import config from '../../../../../services/apiConfig';
import { useStores } from '../../../../../stores/helpers/use-stores';
import DriverMarker from './driverMarker';

const MapContainer = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border-radius: 8px;
  transition: height, width 0.15s;
`;

const MapData = props => {
  const { containerDimensions: { height, width } } = props;
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [mapLine, setMapLine] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(16);
  const { dataStores: { createTripStore, authStore }, uiStore: { globalView } } = useStores();
  const {
          srcLoc,
          destLoc,
          waypoints,
          selectedCarCategory,
          nearByDrivers,
          pickUpAddress,
          destAddress,
          polyline
        } = createTripStore;
  const { userData } = authStore;

  useEffect(() => {
    if (!selectedCarCategory) return;
    setDrivers(toJS(nearByDrivers).filter(drv => drv.carDetails.carCategory.includes(`${selectedCarCategory._id}`)));
  }, [nearByDrivers, selectedCarCategory]);

  useEffect(() => {
    if (!mapsInstance || !mapInstance) return;
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);
    const mrkrs = [
      ...waypoints, { address: pickUpAddress, location: srcLoc }, {
        address: destAddress,
        location: destLoc
      }].filter(m => !!m.location);
    const mapMarkers = mrkrs.map(m => new mapsInstance.Marker({
      position: { lat: m.location[0], lng: m.location[1] },
      map: mapInstance,
      title: m.address
    }));
    const infoWindow = new mapsInstance.InfoWindow();

    mapMarkers.forEach(m => {
      m.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(m.getTitle());
        infoWindow.open(m.getMap(), m);
      });

    });
    setMarkers(mapMarkers);
    // eslint-disable-next-line
  }, [destLoc, destAddress, srcLoc, pickUpAddress, waypoints, mapInstance, mapsInstance]);

  useEffect(() => {
    if (!mapsInstance || !mapInstance) return;
    if (mapLine) {
      mapLine.setMap(null);
      setMapLine(null);
    }
    if (!polyline) return;
    const decodedPolyline = Polyline.decode(polyline, 5);
    const line = new mapsInstance.Polyline({
      path: decodedPolyline.map(latLng => ({ lat: latLng[0], lng: latLng[1] })),
      strokeColor: '#000',
      strokeOpacity: 0.6,
      strokeWeight: 3,
    });
    line.setMap(mapInstance);
    setMapLine(line);
    // eslint-disable-next-line
  }, [polyline, mapsInstance, mapInstance]);

  useEffect(() => {
    if (!mapsInstance || !mapInstance || !markers.length) return;
    const bounds = new mapsInstance.LatLngBounds();
    markers.forEach(marker => bounds.extend(marker.getPosition()));
    mapInstance.fitBounds(bounds);
    if (mapInstance.getZoom() > 16) {
      mapInstance.setZoom(16);
    }
  }, [markers, mapInstance, mapsInstance]);

  const handleApiLoaded = ({ map, maps }) => {
    setMapsInstance(maps);
    setMapInstance(map);
    map.addListener('zoom_changed', () => {
      setCurrentZoom(map.getZoom());
    });
  };

  if (!globalView.googleApiLoaded) {
    return null;
  }

  return (
    <MapContainer height={height} width={width}>
      <GoogleMap
        bootstrapURLKeys={{ key: config.googleMapsApiKey, libraries: ['places', 'visualization'] }}
        defaultCenter={{
          lat: userData.gpsLoc[0],
          lng: userData.gpsLoc[1],
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={14}
        onGoogleApiLoaded={handleApiLoaded}
      >
        {drivers.map(drv => <DriverMarker
          key={drv._id}
          taxiType={selectedCarCategory.name}
          heading={drv.geoHeading}
          lat={drv.gpsLoc[0]}
          lng={drv.gpsLoc[1]}
          currentZoom={currentZoom}
        />)}
      </GoogleMap>
    </MapContainer>
  );
};

MapData.propTypes = {
  containerDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
}

export default observer(MapData);
