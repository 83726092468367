import moment from 'moment';
import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { useTranslation } from 'react-i18next';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/de'
import DateIcon from '../../../../../assets/icons/date.svg';
import { roundUpDateTo5Minutes } from '../../../../../helpers/utils';
import langKeys from '../../../../../i18n/lang/keys';
import commonStyle from '../../../../custom-ui/commonStyle';
import {
  StyledFeedback,
  StyledInputContainer,
  StyledInputInput,
  StyledInputPrependIcon
} from '../../../../custom-ui/styledInput';

const Input = props => {
  return <StyledInputContainer style={{ marginTop: 0 }} valid={props.isValid}>
    <StyledInputPrependIcon valid={props.isValid}>
      <DateIcon height={20} width={20} fill={commonStyle.colors.brandPrimary} />
    </StyledInputPrependIcon>
    <StyledInputInput {...props} valid={props.isValid} />
  </StyledInputContainer>
};

const DateTimeInput = props => {
  const { value, label, locale, isValid, errorMessage, meta } = props;
  const { t } = useTranslation();

  const renderInput = (props) => <Input {...props} isValid={isValid}/>;

  const showPSMessage = useMemo(() => {
    return !!meta && meta.hasOwnProperty('currentPBs') && meta.hasOwnProperty('bookingsLimit');
  }, [meta]);

  return (
    <Fragment>
      <FormGroup>
      <FormLabel style={{ marginBottom: 3 }}>{label}</FormLabel>
      <DateTimePicker
        value={value}
        renderInput={renderInput}
        initialValue={moment((roundUpDateTo5Minutes()).toJSON()).add(30, 'minute')}
        dateFormat={'DD.MM.YYYY'}
        timeFormat={'HH:mm'}
        locale={locale}
        className={'dateTimePicker'}
        onChange={props.onChange}
        strictParsing={true}
        input={true}
        timeConstraints={{ minutes: { min: 0, max: 59, step: 5 } }}
        isValidDate={date => date.isBetween(moment().subtract(1, 'day'), moment().add(30, 'day'))}
        inputProps={{
          placeholder: 'Select min 30 minutes & max 30 days in advance'
        }}
      />
      {!isValid && <StyledFeedback>{errorMessage}</StyledFeedback>}
    </FormGroup>
      {!!showPSMessage &&
      <StyledFeedback
        messageType={meta.bookingsLimit < meta.currentPBs ? 'error' : 'success'}
      >
        {t(langKeys.createTrip.preBookingsLeftInfo, { currentPBs: meta.currentPBs })}
      </StyledFeedback>}
    </Fragment>
  );
};

DateTimeInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  locale: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  meta: PropTypes.shape({
    bookingsLimit: PropTypes.number,
    currentPBs: PropTypes.number,
  }),
};

export default DateTimeInput;
