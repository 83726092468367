import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import Masonry from 'react-masonry-component';
import { useStores } from '../../../../../stores/helpers/use-stores';
import PaginationRow from '../../../../custom-ui/paginationRow';
import * as S from '../styles';
import TripItem from '../tripItem';

const initialMetaState = {
  pageNo: 1,
  limit: 9
};

const Trips = props => {
  const { title, trips, meta, initialMeta, showExport } = props;
  const [currentMeta, setCurrentMeta] = useState(initialMeta || initialMetaState);
  const [isShown, toggleTripsShow] = useState(!!trips.length);
  const [isManualControlled, toggleManualToggle] = useState(false);
  const { dataStores: { myRidesStore: { refreshFlag } } } = useStores();
  const interval = useRef(null);

  useEffect(() => {
    props.onGetTrips(currentMeta);
    if (interval.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      props.onGetTrips(currentMeta);
    }, 15 * 1000);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
    // eslint-disable-next-line
  }, [currentMeta, refreshFlag]);

  useEffect(() => {
    if (!isManualControlled) {
      toggleTripsShow(!!trips.length);
    }
  }, [trips, isManualControlled])

  const handleCollapse = () => {
    toggleManualToggle(true);
    toggleTripsShow(!isShown);
  }

  return (
    <S.MainContainer>
      <S.TitleContainer>
        <S.Binded>
          <S.Title>{title}{meta ? ` (${meta?.totalRecords})` : ''}</S.Title>
          {showExport && <S.ButtonWPadding
            style={{ marginLeft: 8 }}
            variant={'outline-dark'}
            disabled={!trips.length}
            onClick={props.onExport}
          >
            {'Export'}
          </S.ButtonWPadding>}
        </S.Binded>
        <S.SButton
          variant={'outline-dark'}
          onClick={handleCollapse}
          disabled={!trips.length}
        >
          <BsChevronDown style={{
            transform: `rotate(${isShown ? 180 : 0}deg)`
          }}
          />
        </S.SButton>
      </S.TitleContainer>
      {!!trips.length && isShown && <S.TripsContainer>
        <Masonry>
          {toJS(trips).map(trip => <TripItem
            key={trip._id}
            trip={trip}
            onEdit={() => props.onEdit(trip)}
            onCancel={() => props.onCancel(trip)}
          />)
          }
        </Masonry>
      </S.TripsContainer>}
      {isShown && meta?.totalNoOfPages > 1 && <S.PaginationContainer>
        <PaginationRow
          numOfPages={meta.totalNoOfPages}
          currentPage={currentMeta.pageNo}
          currentLimit={currentMeta.limit}
          onLimitChange={limit => setCurrentMeta({ ...currentMeta, limit, pageNo: 1 })}
          onPageChange={pageNo => setCurrentMeta({ ...currentMeta, pageNo })}
        />
      </S.PaginationContainer>}
    </S.MainContainer>
  );
};

Trips.propTypes = {
  title: PropTypes.string.isRequired,
  trips: PropTypes.array.isRequired,
  showCounter: PropTypes.bool,
  meta: PropTypes.shape({
    totalNoOfPages: PropTypes.number,
    totalRecords: PropTypes.number,
  }),
  initialMeta: PropTypes.shape({
    pageNo: PropTypes.number,
    limit: PropTypes.number,
  }),
  onGetTrips: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  showExport: PropTypes.bool,
  onExport: PropTypes.func,
}

export default observer(Trips);
