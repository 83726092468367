import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import styled from 'styled-components';
import langKeys from '../../i18n/lang/keys';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyle from './commonStyle';

const PaginationContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 710px) {
    justify-content: space-around;
  }
  ul.pagination {
    margin: 10px;
  }
  ul.pagination > li.paginationItem {
    height: 38px;
    width: 38px;
    background-color: ${props => props.bgColor || commonStyle.colors.white};
    box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)};
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    &.active {
      background-color: ${commonStyle.colors.brandPrimary};
      box-shadow: none;
    }
  }
  ul.pagination > li.paginationItem.paginationFirstItem {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  ul.pagination > li.paginationItem.paginationLastItem {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  ul.pagination > li.paginationItem.paginationFirstItem,
  ul.pagination > li.paginationItem.paginationLastItem,
  ul.pagination > li.paginationItem.paginationNextItem,
  ul.pagination > li.paginationItem.paginationPrevItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    font-size: 14px;
    min-width: 80px;
    a.paginationLinkItem {
      text-align: left;
      width: auto;
      margin: 0;
    }
    &.disabled > .paginationLinkItem {
      color: ${commonStyle.colors.greyMedium};
      cursor: default;
    }
  }
  ul.pagination > li.paginationItem > .paginationLinkItem {
    width: 30px;
    color: ${commonStyle.colors.brandPrimary};
    text-align: center;
    text-decoration: none;
  }
  ul.pagination > li.paginationItem > .paginationActiveLinkItem {
    color: ${props => props.bgColor || commonStyle.colors.white};
  }
`;

const selectStyles = (bgColor = null) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: bgColor || commonStyle.colors.white,
    width: 120,
    margin: 0,
    border: 'none',
    boxShadow: `1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.brandPrimary, 0.25)}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    cursor: 'pointer'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    fill: commonStyle.colors.brandPrimary
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 30,
    padding: 0,
    textAlign: 'center',
    justifyContent: 'center',
  })
});

const PaginationRow = props => {
  const { numOfPages, currentPage, currentLimit, label, buttonBgColor } = props;
  const { t } = useTranslation();
  const [range, setRange] = useState(5);
  const [hideFirstLast, setHideFirstLast] = useState(false);
  const { uiStore: { globalView: { windowDimensions: { width } } } } = useStores();

  const pageOptions = useMemo(() => [9, 12, 18, 36, 48, 96].map(v => ({ label: `${v} ${label ?? 'Fahrten'}`, value: v })), [label]);

  useEffect(() => {
    if (width < 610 && range !== 3) {
      setRange(3);
    } else if (width >= 610 && range !== 5) {
      setRange(5);
    }
    if (width < 425 && !hideFirstLast) {
      setHideFirstLast(true);
    } else if (width >= 425 && hideFirstLast) {
      setHideFirstLast(false);
    }
    // eslint-disable-next-line
  }, [width]);

  return (
    <PaginationContainer bgColor={buttonBgColor}>
      <Select
        onChange={(v) => props.onLimitChange(v.value)}
        options={pageOptions}
        styles={selectStyles(buttonBgColor)}
        value={pageOptions.find(v => v.value === currentLimit)}
      />
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={currentLimit}
        totalItemsCount={numOfPages * currentLimit}
        pageRangeDisplayed={range}
        hideFirstLastPages={hideFirstLast}
        itemClass={'paginationItem'}
        linkClass={'paginationLinkItem'}
        activeLinkClass={'paginationActiveLinkItem'}
        itemClassFirst={'paginationFirstItem'}
        itemClassLast={'paginationLastItem'}
        itemClassNext={'paginationNextItem'}
        itemClassPrev={'paginationPrevItem'}
        onChange={props.onPageChange}
        firstPageText={t(langKeys.myRides.first)}
        lastPageText={t(langKeys.myRides.last)}
        prevPageText={`< ${t(langKeys.myRides.prev)}`}
        nextPageText={`${t(langKeys.myRides.next)} >`}
      />
    </PaginationContainer>
  );
};

PaginationRow.propTypes = {
  numOfPages: PropTypes.number,
  currentPage: PropTypes.number,
  currentLimit: PropTypes.number,
  onLimitChange: PropTypes.func,
  onPageChange: PropTypes.func,
  label: PropTypes.string,
  buttonBgColor: PropTypes.string,
};

export default observer(PaginationRow);
