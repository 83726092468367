import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './lang/de';
import en from './lang/en';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    debug: true,
    resources: {
      'de': { translation: de },
      'en': { translation: en }
    },

    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: true
    }
  });

export default i18n;
