import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import styled from 'styled-components';
import commonStyle from '../../custom-ui/commonStyle';

const BtnContainer = styled.button`
  display: none;
  position: absolute;
  left: 25px;
  height: 35px;
  width: 40px;
  top: 17.5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border: 1px solid ${commonStyle.colors.background};
  border-radius: 8px;
  background-color: ${commonStyle.colors.brandPrimary};
  color: ${commonStyle.colors.background};

  &:hover {
    background-color: ${commonStyle.colors.background};
    color: ${commonStyle.colors.brandPrimary};
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const SidebarToggler = props => {
  const { isSidebarVisible } = props;
  const [listenerAdded, setListener] = useState(false);

  function handleClick() {
    props.onToggle(true);
    setTimeout(() => {
      setListener(false);
    }, 100);
    document.removeEventListener('click', handleClick, true);
  }

  const handleShow = () => {
    if (!isSidebarVisible && !listenerAdded) {
      props.onToggle(false);
      setListener(true);
      document.addEventListener('click', handleClick, true);
    }
  }

  return (
    <BtnContainer onClick={handleShow}>
      {isSidebarVisible ? <AiOutlineClose/> : <AiOutlineMenu/>}
    </BtnContainer>
  );
};

SidebarToggler.propTypes = {
  isSidebarVisible: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default SidebarToggler;
