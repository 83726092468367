import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const OrderItem = props => {
  const { item, showMenuNumber } = props;
  const itemPricePrefix = item.dishId.sizingAvailable && item.dishId.flexibleSizingEnabled ? '~ ' : '';
  const price =(item.itemPrice * item.qty).toFixed(2);

  return (
    <S.OrderItem>
      <strong>{item.qty}</strong> x {showMenuNumber ? `(${item.dishId?.numberOnMenu}) ` : ''}{item.dishId?.name} {item.itemSize ? `(${item.itemSize})` : ''} {item.cookTypes ? `(${item.cookTypes})` : ''}{item.additions.length ? item.additions.map(addition => ` mit ${addition.name}`).join(',') : ''} ({itemPricePrefix}{price} €){!!item.comment ? `, komment - ${item.comment}` : ''}
      {!!item.extras?.length && item.extras.map(extra => {
        const name = <strong style={{ marginLeft: 16 }}>{extra.name}: </strong>;
        if (extra.isMulti) {
          const additions = extra.additions.map((addition, idx) => `${addition.name}${idx < extra.numberOfFreeExtras ? '' : ` (${addition.price.toFixed(2) } €)`}`).join(', ');
          return <div>{name} {additions}</div>;
        } else {
          return <div>{name} {extra.baseItem?.name}{!!extra.baseItem.price ? ` (${extra.baseItem.price.toFixed(2)} €)` : ''}</div>;
        }
      })}
    </S.OrderItem>
  );
};

OrderItem.propTypes = {
  item: PropTypes.object,
  showMenuNumber: PropTypes.bool,
};

export default OrderItem;
