import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import commonStyle from '../../../../custom-ui/commonStyle';
import Toggler from '../../../../custom-ui/toggler';
import DownChevron from '../../../../../assets/icons/downChevron.svg'
import * as S from '../styles';

export const openHoursSelectorStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    width: 80,
    maxHeight: 36,
    minHeight: 36,
    margin: 0,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    cursor: 'pointer'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    fill: commonStyle.colors.brandPrimary
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 30,
    padding: 0,
    textAlign: 'center',
    justifyContent: 'center',
  })
};

export const DownIcon = (props) => {
  return <components.IndicatorsContainer
    {...props}
  >
   <DownChevron fill={commonStyle.colors.brandPrimary}/>
  </components.IndicatorsContainer>;
}

const DayRow = props => {
  const { day, hoursData, dayIndex, timeOptions } = props;

  const dayOrderData = useMemo(() => {
    if (!hoursData) return null;
    return hoursData.find(dt => dt.day === `${dayIndex}`);
  }, [hoursData, dayIndex]);

  const handleChange = (value, key) => {
    props.onChange({ [key]: value }, dayIndex);
  }

  if (!dayOrderData) return null;

  return (
    <S.DayRow>
      <S.OffsetCol/>
      <S.DayContainer>
        <S.TogglerContainer>
          <Toggler
            isEnabled={dayOrderData.isWorking}
            height={20}
            width={40}
            onChange={() => handleChange(!dayOrderData.isWorking, 'isWorking')}
          />
        </S.TogglerContainer>
        <S.DayStr>{day}</S.DayStr>
      </S.DayContainer>
      <S.TimeCol active={dayOrderData.isWorking}>
        {!dayOrderData.isWorking && 'X'}
        {!!dayOrderData.isWorking && <Select
          components={{
            IndicatorsContainer: DownIcon,
          }}
          onChange={v => handleChange(v.value, 'from')}
          options={timeOptions}
          styles={openHoursSelectorStyles}
          isSearchable={false}
          value={timeOptions.find(v => v.value === dayOrderData.from)}
        />}
      </S.TimeCol>
      <S.TimeCol active={dayOrderData.isWorking}>
        {!dayOrderData.isWorking && 'X'}
        {!!dayOrderData.isWorking && <Select
          components={{
            IndicatorsContainer: DownIcon,
          }}
          onChange={v => handleChange(v.value, 'to')}
          options={timeOptions}
          styles={openHoursSelectorStyles}
          isSearchable={false}
          value={timeOptions.find(v => v.value === dayOrderData.to)}
        />}
      </S.TimeCol>
      <S.OffsetCol/>
    </S.DayRow>
  );
};

DayRow.propTypes = {
  day: PropTypes.string,
  dayIndex: PropTypes.number,
  timeOptions: PropTypes.array,
  hoursData: PropTypes.array,
  onChange: PropTypes.func,
};

export default DayRow;
