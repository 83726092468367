import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '../../../../../assets/icons/editIcon.svg';
import { defineDishType, dishTypes } from '../../../../../constants/dishTypes';
import { formatPrice } from '../../../../../helpers/utils';
import Toggler from '../../../../custom-ui/toggler';
import * as S from '../styles';

const DishItem = props => {
  const { dish } = props;
  const [fullText, setFullText] = useState(false);

  const currentDishType = useMemo(() => defineDishType(dish), [dish]);

  const totalPriceText = useMemo(() => {
    if (currentDishType === dishTypes.multiSize) {
      const minPrice = dish.programmedSizing[0].price;
      return `ab ${formatPrice(minPrice)}`;
    }
    if (currentDishType === dishTypes.looseSize) {
      const itemPrice = dish.flexibleSizing.unitPrice;
      return `ab ${formatPrice(itemPrice)}/${dish.flexibleSizing.units}`;
    }
    return formatPrice(dish.price);
  }, [dish, currentDishType]);

  return (
    <S.MenuItemLine key={dish._id}>
      <S.MenuShortBlock>#{dish.numberOnMenu}</S.MenuShortBlock>
      <S.DishNameBlock
        hideText={!fullText}
        onClick={() => setFullText(!fullText)}
      >
        {dish.name}
      </S.DishNameBlock>
      <S.DishDescBlock
        hideText={!fullText}
        onClick={() => setFullText(!fullText)}
      >
        {dish.description}
      </S.DishDescBlock>
      <S.PriceBlock>{totalPriceText}</S.PriceBlock>
      <S.CenteredShortBlock>
        <Toggler
          isEnabled={dish.isInStock}
          width={40}
          height={20}
          onChange={props.onStockChange}
        />
      </S.CenteredShortBlock>
      <S.EditButtonBlock>
        <S.EditButton
          onClick={props.onEdit}
        >
          <EditIcon />
        </S.EditButton>
      </S.EditButtonBlock>
    </S.MenuItemLine>
  );
};

DishItem.propTypes = {
  dish: PropTypes.object,
  onStockChange: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DishItem;
