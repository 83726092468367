import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../styles';
import DishItem from './dishItem';

const RestaurantCategory = props => {
  const { data: { title, data } } = props;
  return (
    <S.RestaurantCategoryContainer>
      <S.CategoryName>{title}:</S.CategoryName>
      <S.MenuBox>
        {data.map(dish => <DishItem
          key={dish._id}
          dish={dish}
          onStockChange={() => props.onStockChange(dish._id)}
          onEdit={() => props.onEdit(dish)}
        />)}
      </S.MenuBox>
    </S.RestaurantCategoryContainer>
  );
};

RestaurantCategory.propTypes = {
  data: PropTypes.shape({
    catId: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.array,
  }).isRequired,
  onStockChange: PropTypes.func,
  onEdit: PropTypes.func,
};

export default RestaurantCategory;
